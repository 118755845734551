import React from 'react'
import { PagesContext } from '../../../contexts/pages'
import CreatePatient from './create'
import ListPatients from './list'
import EditPatient from './edit'
import { useSelector } from 'react-redux'
import { adminSlice } from '../../../reducers'

const Patients = () => {
  const { adminPatientPage, AdminPatientPages } = React.useContext(PagesContext)
  const adminPatient = useSelector(adminSlice.selectors.selectPatient)
  switch (adminPatientPage) {
    case AdminPatientPages.ADMIN_PATIENT_CREATE:
      return <CreatePatient />
    case AdminPatientPages.ADMIN_PATIENT_LIST:
      if (adminPatient) {
        return <EditPatient />
      }
      return <ListPatients />
    default:
      return <ListPatients />
  }
}

export default Patients
