import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../../utils'
import moment from 'moment'

export const initialState = {
  patient: null,
  agenda: [],
  event: {
    title: '',
    notes: '',
    startDate: moment().add(1, 'day').valueOf(),
    endDate: moment().add(1, 'day').add(1, 'hour').valueOf(),
    patient: null
  },
  meeting: {
    title: '',
    startDate: moment().add(1, 'day').valueOf(),
    endDate: moment().add(1, 'day').add(1, 'hour').valueOf(),
    participants: []
  },
  meetings: [],
  meetingParticipants: []
}

export const pageAgendaSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setPatient: (state, { payload }) => {
      state.patient = payload
    },
    setAgenda: (state, { payload }) => {
      state.agenda = payload
    },
    setEvent: (state, { payload }) => {
      state.event = payload
    },
    setMeetings: (state, { payload }) => {
      state.meetings = payload
    },
    setMeeting: (state, { payload }) => {
      state.meeting = payload
    },
    setMeetingParticipants: (state, { payload }) => {
      state.meetingParticipants = payload
    }
  }
})

export const {
  setPatient,
  setAgenda,
  setEvent,
  setMeetings,
  setMeeting,
  setMeetingParticipants,
  reset
} = pageAgendaSlice.actions

export default pageAgendaSlice.reducer
