import React from 'react'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { adminSlice } from '../../../reducers'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers'
import Loading from '../../loading'
import JSZip from 'jszip'

const Documents = () => {
  const [getSignatureDocuments] = useEvent([adminSlice.eventBus.getSignatureDocuments])
  const signatureDocuments = useSelector(adminSlice.selectors.selectSignatureDocuments)
  const [rangeDate, setRangeDate] = React.useState({
    start: moment().startOf('month'),
    end: moment().endOf('month')
  })
  React.useEffect(() => {
    getSignatureDocuments.trigger()
  }, [])
  const [isDownloading, setIsDownloading] = React.useState(false)

  const search = React.useCallback(() => {
    const { start, end } = rangeDate
    getSignatureDocuments.trigger({ start: start?.valueOf(), end: end?.valueOf() })
  }, [rangeDate])

  const signedDocs = React.useMemo(() => signatureDocuments?.filter(doc => doc?.status === 'Concluído' && doc?.signedUrl), [signatureDocuments])

  const downloadZip = React.useCallback(() => {
    const download = async () => {
      setIsDownloading(true)
      const zip = new JSZip()
      for (const doc of signedDocs) {
        const response = await fetch(doc.signedUrl)
        const blob = response.blob()
        zip.file(doc.signedKey.split('/').pop(), blob)
      }
      const zipData = await zip.generateAsync({
        type: "blob",
        streamFiles: true,
      })
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(zipData)
      link.download = `Documentos Assinados - ${rangeDate?.start.format('DD-MM-YYYY')} - ${rangeDate?.end.format('DD-MM-YYYY')}.zip`
      link.click()
      setIsDownloading(false)
    }
    download()
  }, [rangeDate, signedDocs])

  return (
    <section className='px-2 flex flex-col justify-between min-h-[90vh]'>
      <div>
        <h1 className='title'>Documentos</h1>
        <div className='flex flex-row gap-2 py-2'>
          <div className='flex flex-col'>
            <label className='label'>Início</label>
            <DatePicker value={rangeDate.start} onChange={((value) => setRangeDate(date => ({ ...date, start: value })))} />
          </div>
          <div className='flex flex-col'>
            <label className='label'>Fim</label>
            <DatePicker value={rangeDate.end} onChange={((value) => setRangeDate(date => ({ ...date, end: value })))} />
          </div>
          <div className='flex items-end'>
            <button disabled={getSignatureDocuments.isWorking} onClick={search} className='btn-primary h-10'>{getSignatureDocuments.isWorking ? <Loading size={5} /> : <>Pesquisar</>}</button>
          </div>
        </div>
        <div className='flex items-start'>
          <button disabled={!signedDocs.length || getSignatureDocuments.isWorking || isDownloading} onClick={downloadZip} className='btn-primary h-10'>{isDownloading ? <div className='block w-40'><Loading size={5} /></div> : <>Baixar Documentos Assinados</>}</button>
        </div>
        <div className='flex justify-end'>
          {signedDocs.length > 0 && <p className='text-sm'>{signedDocs.length} documento(s) assinado(s).</p>}
        </div>
        {!signatureDocuments.length && !getSignatureDocuments.isWorking && <p className='text-sm'>Não há documentos para este período</p>}
        {!!signatureDocuments.length && <div className='shadow bg-white rounded-sm'>

          <table className='w-full'>
            <thead className='h-10'>
              <th className='border-x font-medium border-b'>Nome</th>
              <th className='border-x font-medium border-b'>Data</th>
              <th className='border-x font-medium border-b'>Origem</th>
              <th className='border-x font-medium border-b'>Status</th>
            </thead>
            <tbody className='text-sm'>
              {signatureDocuments.map(({ key, createdAt, source, status, signedUrl }) => (
                <tr className='border-b h-20' key={key}>
                  <td className='border-x p-2 text-wrap'>{key.split('/').pop()}</td>
                  <td className='border-x p-2 text-center'>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</td>
                  <td className='border-x p-2 text-center'>{source}</td>
                  <td className='border-x p-2 text-center'>{status === 'Concluído' ? <a className='underline' href={signedUrl} target='_blank'>Concluído</a> : 'Aguardando'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        }
      </div>
    </section>
  )
}
export default Documents