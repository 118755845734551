import * as actions from './slice'

export const searchDoctors = {
  eventName: 'getDoctors',
  onSuccess: {
    action: actions.setSearchDoctors,
    redux: true
  }
}

export const addDoctorToGroup = {
  eventName: 'addDoctorToGroup'
}

export const removeDoctorFromGroup = {
  eventName: 'removeDoctorFromGroup'
}

export const getPatientDoctors = {
  eventName: 'getPatientDoctors',
  onSuccess: {
    redux: true,
    action: actions.setPatientDoctors
  }
}

export const getDoctor = {
  eventName: 'getUserByPk',
  onSuccess: {
    action: actions.setDoctor,
    redux: true
  }
}

export const setIsSupervisor = {
  eventName: 'setIsSupervisor'
}