import React from 'react'

const data = [
  { nome: 'João Silva', responsavel: 'Maria Silva', proximoAtendimento: '01/12/2023 - 10:00' },
  { nome: 'João Silva', responsavel: 'Maria Silva', proximoAtendimento: '01/12/2023 - 10:00' },
  { nome: 'João Silva', responsavel: 'Maria Silva', proximoAtendimento: '01/12/2023 - 10:00' },
  { nome: 'João Silva', responsavel: 'Maria Silva', proximoAtendimento: '01/12/2023 - 10:00' },
  { nome: 'João Silva', responsavel: 'Maria Silva', proximoAtendimento: '01/12/2023 - 10:00' },
  { nome: 'João Silva', responsavel: 'Maria Silva', proximoAtendimento: '01/12/2023 - 10:00' },
  { nome: 'João Silva', responsavel: 'Maria Silva', proximoAtendimento: '01/12/2023 - 10:00' }
]

const PatientReports = () => {
  return (
    <div className='overflow-x-auto relative'>
      <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
        <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
          <tr>
            <th scope='col' className='py-3 px-6'>
              Nome
            </th>
            <th scope='col' className='py-3 px-6'>
              Responsável
            </th>
            <th scope='col' className='py-3 px-6'>
              Próx. Atendimento
            </th>
            <th scope='col' className='py-3 px-6'>
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
            >
              <td className='py-4 px-6'>{row.nome}</td>
              <td className='py-4 px-6'>{row.responsavel}</td>
              <td className='py-4 px-6'>{row.proximoAtendimento}</td>
              <td className='py-4 px-6'>
                <button className='text-blue-600 hover:text-blue-900'>
                  Ação
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PatientReports
