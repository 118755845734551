import React from 'react'
import { useSelector } from 'react-redux'
import { adminSlice } from '../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import Loading from '../../loading'
import Modal from '../../modal'
import PaymentMethods from './payment-methods'
import Plan from './plan'
import { PagesContext } from '../../../contexts/pages'
import History from './history'
import moment from 'moment'

const Subscription = () => {
  const { adminSubscriptionPageModal, setAdminSubscriptionPageModal, AdminSubscriptionPageModals } = React.useContext(PagesContext)
  const plan = useSelector(adminSlice.selectors.selectPlan)
  const subscription = useSelector(adminSlice.selectors.selectSubscription)
  const [getSubscription, getPlans, getCreditCards] = useEvent([adminSlice.eventBus.getSubscription, adminSlice.eventBus.getPlans, adminSlice.eventBus.getCreditCards])
  React.useEffect(() => {
    getSubscription.trigger()
    getPlans.trigger()
    getCreditCards.trigger()
  }, [getCreditCards, getPlans, getSubscription])
  const handleOpenModal = modal => {
    setAdminSubscriptionPageModal(modal)
  }
  const closeModal = () => setAdminSubscriptionPageModal(null)

  const isWorking = React.useMemo(() => getSubscription.isWorking, [getSubscription.isWorking])
  return (
    <section className='px-2 font-inter flex flex-col w-full'>
      <div className='inline-flex items-center justify-between'>
        <div className='title inline-flex'>
          Assinatura
        </div>
      </div>
      <div className='flex flex-row'>
        <button className='btn-primary' onClick={() => handleOpenModal(AdminSubscriptionPageModals.PLAN)}>Alterar Plano</button>
        <button className='btn-primary' onClick={() => handleOpenModal(AdminSubscriptionPageModals.PAYMENT)}>Métodos de Pagamento</button>
        <button className='btn-primary' onClick={() => handleOpenModal(AdminSubscriptionPageModals.HISTORY)}>Histórico</button>
      </div>
      <Modal open={adminSubscriptionPageModal === AdminSubscriptionPageModals.PLAN} onClose={closeModal} title='Alterar Plano' content={<Plan />} />
      <Modal open={adminSubscriptionPageModal === AdminSubscriptionPageModals.PAYMENT} onClose={closeModal} title='Métodos de Pagamento' content={<PaymentMethods />} />
      <Modal open={adminSubscriptionPageModal === AdminSubscriptionPageModals.HISTORY} onClose={closeModal} title='Histórico de Cobranças' content={<History />} />
      {isWorking
        ? <div className='flex w-full justify-center'><Loading /></div>
        : (
          <div>
            <div><span className='text-lg font-semibold'>Seu plano: </span><span>{plan?.name} - {plan?.description}</span></div>
            <div><span className='text-lg font-semibold'>Mensalidade: </span><span>R${plan?.amount}</span></div>
            <div><span className='text-lg font-semibold'>Frequência: </span><span>{plan?.planFrequence}</span></div>
            <div><span className='text-lg font-semibold'>Status da Assinatura: </span><span>{subscription?.statusName}</span></div>
            <div><span className='text-lg font-semibold'>Forma de Pagamento: </span><span>{subscription?.paymentMethodName}</span></div>
            <div><span className='text-lg font-semibold'>Assinante desde: </span><span>{moment(subscription?.createdDate).format('DD/MM/YYYY')}</span></div>
          </div>)}
    </section>
  )
}

export default Subscription
