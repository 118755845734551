import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './reducers/store'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './libs/i18n'
import './main.css'
import Join from './pages/join'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate to='/vi' />} />
        <Route path='join' element={<Join />} />
        <Route path=':tenantKey/*' element={<App />} />
      </Routes>
    </BrowserRouter>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if (module.hot) {
  module.hot.accept()
}
