import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { coreSlice } from '../../../reducers'
import { useSelector } from 'react-redux'

const PrivateRoute = ({ children }) => {
  const authUser = useSelector(coreSlice.selectors.selectAuthUser)
  const { tenantKey } = useParams()
  return authUser ? children : <Navigate to={`/${tenantKey}/signin`} />
}

export default PrivateRoute
