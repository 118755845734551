import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../../utils'

const initialState = {
  user: null,
  searchUsers: [],
  users: []
}

export const componentsSelectUserSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setSearchUsers: (state, { payload }) => {
      state.searchUsers = payload
    },
    setUsers: (state, { payload }) => {
      state.users = payload
    },
    setUser: (state, { payload }) => {
      state.user = payload
    }
  }
})

export const {
  reset,
  setSearchUsers,
  setUser,
  setUsers
} = componentsSelectUserSlice.actions

export default componentsSelectUserSlice.reducer
