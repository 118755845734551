import React from 'react'
import { useSelector } from 'react-redux'
import Modal from '../modal'
import { coreSlice } from '../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import Loading from '../loading'

const AcceptTerms = () => {
  const user = useSelector(coreSlice.selectors.selectUser)
  const [acceptTerms] = useEvent([coreSlice.eventBus.acceptTerms])
  const Terms = React.useCallback(() => {
    return (
      <div className='flex justify-center flex-col'>
        <p>Você aceita os termos de uso?</p>
        <div className='w-full flex justify-center h-8'>
          {acceptTerms.isWorking && <Loading size={4} />}
        </div>
        <button className='btn-primary' onClick={() => acceptTerms.trigger()} disabled={acceptTerms.isWorking}>Aceito</button>
      </div>
    )
  }, [])
  return (<Modal title={'Termos de uso'} content={<Terms />} open={!user?.hasAcceptedTerms} />)
}
export default AcceptTerms