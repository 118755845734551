import React from 'react'
import { useSelector } from 'react-redux'
import { coreSlice } from '../../../reducers'
import { EventContext } from '../../../contexts/event'
import Loading from '../../loading'
import { PagesContext } from '../../../contexts/pages'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import PersonIcon from '@mui/icons-material/Person'
import PaidIcon from '@mui/icons-material/Paid'
import BusinessIcon from '@mui/icons-material/Business'
import FolderIcon from '@mui/icons-material/Folder'

const AdminLeftBar = ({ adminPage, setAdminPage }) => {
  return (
    <div className='flex flex-col border-r min-h-screen'>
      <AdminLeftBarItems
        setAdminPage={setAdminPage}
        adminPage={adminPage}
      />
    </div>
  )
}

const AdminLeftBarItems = () => {
  const { userLoading } = React.useContext(EventContext)
  const { adminPage, setAdminPage, AdminPages, setShowLeftBar, showLeftBar } =
    React.useContext(PagesContext)
  const user = useSelector(coreSlice.selectors.selectUser)
  const isNotActiveTenant = useSelector(coreSlice.selectors.selectNotActiveTenant)

  const isLoading = userLoading || !user

  const OrgItems = () => (
    <>
      <div
        className={
          adminPage === AdminPages.ADMIN_ORG
            ? 'selected-left-drawer-item cursor-pointer'
            : 'left-drawer-item cursor-pointer'
        }
        onClick={() => setAdminPage(AdminPages.ADMIN_ORG)}
      >
        <BusinessIcon className='icon' />
        Organização
      </div>
      <div
        className={
          adminPage === AdminPages.ADMIN_SUBSCRIPTION
            ? 'selected-left-drawer-item cursor-pointer'
            : 'left-drawer-item cursor-pointer'
        }
        onClick={() => setAdminPage(AdminPages.ADMIN_SUBSCRIPTION)}
      >
        <PaidIcon className='icon' />
        Assinatura
      </div>
    </>
  )

  const Items = () => (
    <>
      <div
        className={
          adminPage === AdminPages.ADMIN_PATIENTS
            ? 'selected-left-drawer-item cursor-pointer'
            : 'left-drawer-item cursor-pointer'
        }
        onClick={() => setAdminPage(AdminPages.ADMIN_PATIENTS)}
      >
        <EmojiEmotionsIcon className='icon' />
        Pacientes
      </div>
      <div
        className={
          adminPage === AdminPages.ADMIN_USERS
            ? 'selected-left-drawer-item cursor-pointer'
            : 'left-drawer-item cursor-pointer'
        }
        onClick={() => setAdminPage(AdminPages.ADMIN_USERS)}
      >
        <PersonIcon className='icon' />
        Usuários
      </div>
      <div
        className={
          adminPage === AdminPages.ADMIN_DOCUMENTS
            ? 'selected-left-drawer-item cursor-pointer'
            : 'left-drawer-item cursor-pointer'
        }
        onClick={() => setAdminPage(AdminPages.ADMIN_DOCUMENTS)}
      >
        <FolderIcon className='icon' />
        Documentos
      </div>
      <OrgItems />
    </>
  )

  return (
    <>
      <div className={showLeftBar ? 'left-bar-open' : 'left-bar-closed'}>
        <div className='flex flex-col border-b shadow-sm h-screen w-min-40 w-40 bg-white' onClick={() => setShowLeftBar(false)}>
          {isLoading ?
            <div className='flex justify-center items-center w-full pt-10'>
              <Loading size={5} />
            </div>
            : isNotActiveTenant ? <OrgItems /> : <Items />}
        </div>
      </div>
      <div className={`w-screen h-screen bg-black bg-opacity-20 z-10 top-0 bottom-0 fixed md:hidden ${showLeftBar ? '' : 'hidden'}`} onClick={() => setShowLeftBar(false)} />
    </>
  )
}

export default AdminLeftBar
