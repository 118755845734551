import React, { useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAuth } from '../../../contexts/user-authentication'
import { cognitoPasswordRegex } from '../../../constants/regex'

const ConfirmOTPResetPassword = ({ registerData }) => {
  const { forgotPasswordSubmit, forgotPassword } = useAuth()
  const otpRefs = useRef([])
  otpRefs.current = Array(6)
    .fill()
    .map((_, i) => otpRefs.current[i] || React.createRef())

  const { handleChange, handleSubmit, handleBlur, errors, touched, values } = useFormik({
    initialValues: {
      otp: '',
      password: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('A senha é obrigatória').matches(cognitoPasswordRegex, 'Senha inválida'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
        .required('Confirmação da senha é obrigatória'),
      otp: Yup.string().required('O código de verificação é obrigatório!')
    }),
    onSubmit: async (values) => {
      forgotPasswordSubmit({ email: registerData.email, password: values.password, code: values.otp })
    }
  })

  React.useEffect(() => {
    forgotPassword(registerData.email)
  }, [forgotPassword, registerData])

  return (
    <div className='relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-12'>
      <div className='relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl'>
        <div className='mx-auto flex w-full max-w-md flex-col'>
          <div className='flex flex-col items-center justify-center text-center'>
            <div className='font-semibold text-3xl'>
              <p>Alterar Senha</p>
            </div>
            <div className='flex flex-row text-sm font-medium text-gray-400'>
              <p>
                Se houver uma conta registrada, enviamos um código para o e-mail {registerData.email}
              </p>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className='flex flex-col'>
              <div className='mb-4 flex flex-col items-center w-full'>
                <label
                  htmlFor='otp'
                  className='label'
                >
                  Código de Verificação
                </label>
                <div className='w-32'>
                  <input
                    className='input text-center'
                    type='text'
                    maxLength='6'
                    minLength='6'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.otp}
                    id='otp'
                    name='otp'
                    required
                  />
                </div>
                {touched.otp && errors.otp ? (
                  <div>{errors.otp}</div>
                ) : null}
              </div>
              <div className='mb-4'>
                <label
                  htmlFor='password'
                  className='label'
                >
                  Senha
                </label>
                <input
                  type='password'
                  name='password'
                  id='password'
                  placeholder='••••••••'
                  className='input'
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                {touched.password && errors.password ? (
                  <div>{errors.password}</div>
                ) : null}
              </div>
              <div className='mb-4'>
                <label
                  htmlFor='confirmPassword'
                  className='label'
                >
                  Repita a senha
                </label>
                <input
                  type='password'
                  name='confirmPassword'
                  id='confirmPassword'
                  placeholder='••••••••'
                  className='input'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  required
                />
                {touched.confirmPassword && errors.confirmPassword ? (
                  <div>{errors.confirmPassword}</div>
                ) : null}
              </div>
              <div className='text-sm pb-2'>
                <p>Sua senha deve conter ao menos:</p>
                <ul>
                  <li>8 caracteres</li>
                  <li>1 letra maiúscula</li>
                  <li>1 letra minúscula</li>
                  <li>1 caractere especial</li>
                </ul>
              </div>
              <div className='flex flex-col'>
                <button
                  type='submit'
                  className='signin-btn'
                >
                  Atualizar Senha
                </button>

                <div className='flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500'>
                  <p>Não recebeu o código?</p>
                  <button
                    className='flex flex-row items-center text-blue-600'
                    type='button'
                    onClick={() => forgotPassword(registerData.email)}
                  >
                    Reenviar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ConfirmOTPResetPassword
