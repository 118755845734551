import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../utils'

const initialState = {
  user: null,
  patient: null,
  doctor: null,
  plan: null,
  subscription: null,
  creditCards: [],
  plans: [],
  charges: [],
  signatureDocuments: []
}

export const adminSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setUser: (state, { payload }) => {
      state.user = payload
    },
    setPatient: (state, { payload }) => {
      state.patient = payload
    },
    setDoctor: (state, { payload }) => {
      state.doctor = payload
    },
    setSubscription: (state, { payload }) => {
      state.subscription = payload
    },
    setPlan: (state, { payload }) => {
      state.plan = payload
    },
    setSubscriptionAndPlan: (state, { payload }) => {
      state.plan = payload.plan
      state.subscription = payload.subscription
    },
    setCreditCards: (state, { payload }) => {
      state.creditCards = payload
    },
    setPlans: (state, { payload }) => {
      state.plans = payload
    },
    setCharges: (state, { payload }) => {
      state.charges = payload
    },
    setSignatureDocuments: (state, { payload }) => {
      state.signatureDocuments = payload
    }
  }
})

export const {
  setUser,
  setPatient,
  setDoctor,
  setSubscription,
  setPlan,
  setSubscriptionAndPlan,
  setCreditCards,
  setPlans,
  setCharges,
  setSignatureDocuments,
  reset
} = adminSlice.actions

export default adminSlice.reducer
