import React from 'react'
import { adminSlice, tenantSlice } from '../../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { useSelector } from 'react-redux'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import moment from 'moment'

const History = () => {
  const [getCharges] = useEvent([adminSlice.eventBus.getCharges])
  const charges = useSelector(adminSlice.selectors.selectCharges)
  const tenant = useSelector(tenantSlice.selectors.selectTenant)

  const [expand, setExpand] = React.useState(null)
  const { subscriptionHistory } = React.useMemo(() => tenant, [tenant])
  React.useEffect(() => {
    if (!charges.length && !getCharges.isWorking) {
      getCharges.trigger()
    }
  }, [charges.length, getCharges])
  return (
    <div className='w-[90vw] md:w-[600px] h-[70vh]  overflow-auto'>
      {subscriptionHistory?.map(sub => {
        return (
          <div className={`border rounded-lg p-2 cursor-pointer transition-all ${expand === sub ? 'h-96' : 'h-10'}`} key={sub} onClick={() => setExpand(sub === expand ? null : sub)}>
            <div className='font-bold justify-between flex flex-row'>
              <div>Assinatura <span className='font-semibold'>#{sub}</span></div>
              {expand === sub ? <ExpandLessIcon className='icon' /> : <ExpandMoreIcon className='icon' />}
            </div>
            <div className={`w-full py-4 text-sm h-80 transition-all ${expand === sub ? 'visible opacity-100' : 'invisible opacity-0'}`}>
              <div className='border h-10 text-sm font-bold w-full flex flex-row items-center'>
                <div className='border w-1/3 flex justify-center items-center h-full'>Valor</div>
                <div className='border w-1/3 flex justify-center items-center h-full'>Data</div>
                <div className='border w-1/3 flex justify-center items-center h-full'>Status</div>
              </div>
              <div className='overflow-auto h-64'>
                <table className='w-full text-sm'>
                  <tbody>
                    {charges[sub]?.map((charge, key) => (
                      <tr className='border h-10' key={key}>
                        <td className='text-center w-1/3'>R${charge.amount}</td>
                        <td className='text-center w-1/3'>{moment(charge.chargeDate).format('DD/MM/YYYY')}</td>
                        <td className='text-center w-1/3 font-semibold'>{charge.idTransaction > 0 ? 'Pago' : 'Pendente'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
export default History
