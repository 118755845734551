import * as actions from './slice'

export const getUser = {
  eventName: 'getUser',
  onSuccess: {
    action: actions.setUser,
    redux: true
  }
}

export const getPatient = {
  eventName: 'getPatient',
  onSuccess: {
    action: actions.setPatient,
    redux: true
  }
}

export const acceptTerms = {
  eventName: 'acceptTerms',
  onSuccess: {
    action: actions.setUser,
    redux: true
  }
}