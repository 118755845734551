import { toast } from 'react-toastify'
import * as actions from './slice'

export const getMeeting = {
  eventName: 'getMeeting',
  onSuccess: {
    action: actions.setMeeting,
    redux: true
  },
  onError: (error, a) => {
    toast.error(error)
  }
}

export const generateMeetingRtmToken = {
  eventName: 'generateMeetingRtmToken',
  onSuccess: {
    action: actions.setRtmToken,
    redux: true
  }
}

