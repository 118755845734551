import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../../utils'

const initialState = {
  patient: null,
  inProgressAttendance: null,
  attendance: {},
  attendances: [],
  allAttendances: [],
  attendanceRecordings: [],
  notes: '',
  hasNotesChanges: false,
  doctorInProgressAttendance: null,
  rtmToken: null,
  attendanceComments: []
}

export const dashboardAttendance = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setPatient: (state, { payload }) => {
      state.patient = payload
    },
    setInProgressAttendance: (state, { payload }) => {
      state.inProgressAttendance = payload
    },
    setAttendance: (state, { payload }) => {
      state.attendance = payload
    },
    setAttendances: (state, { payload }) => {
      state.attendances = payload
    },
    setAllAttendances: (state, { payload }) => {
      state.allAttendances = payload
    },
    setAttendanceRecordings: (state, { payload }) => {
      state.attendanceRecordings = payload
    },
    setNotes: (state, { payload }) => {
      state.notes = payload
    },
    setHasNotesChanges: (state, { payload }) => {
      state.hasNotesChanges = payload
    },
    setDoctorInProgressAttendance: (state, { payload }) => {
      state.doctorInProgressAttendance = payload
    },
    setRtmToken: (state, { payload }) => {
      state.rtmToken = payload
    },
    setAttendanceComments: (state, { payload }) => {
      state.attendanceComments = payload
    },
    addNewAttendanceComment: (state, { payload }) => {
      if (
        !state.attendanceComments.find(
          ({ attendance, moment }) =>
            attendance === payload?.attendance && moment === payload?.moment
        )
      ) {
        state.attendanceComments = [payload, ...state.attendanceComments]
      }
    },
    addAttendanceRecordingComment: (state, { payload }) => {
      const recordings = state.attendanceRecordings.map((r) => {
        if (r.slice === payload.slice) {
          r.comments.push(payload)
        }
        return r
      })
      state.attendanceRecordings = recordings
    }
  }
})

export const {
  reset,
  setPatient,
  setInProgressAttendance,
  setAttendance,
  setAttendances,
  setAllAttendances,
  setAttendanceRecordings,
  setNotes,
  setHasNotesChanges,
  setDoctorInProgressAttendance,
  setRtmToken,
  setAttendanceComments,
  addNewAttendanceComment,
  addAttendanceRecordingComment
} = dashboardAttendance.actions

export default dashboardAttendance.reducer
