import name from './name'

const selectSliceData = (state) => state[name]

export const selectSearchPatients = (state) =>
  selectSliceData(state).searchPatients
export const selectPatients = (state) => selectSliceData(state).patients
export const selectIsSearching = (state) => selectSliceData(state).isSearching
export const selectPatientDoctors = (state) =>
  selectSliceData(state).patientDoctors
