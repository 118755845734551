import React from 'react'
import { ccMask, cvvMask, expirationMask } from '../../utils/mask'

const CCForm = ({ formik, setFieldValue }) => {
  return (
    <>
      <label htmlFor='holder' className='label'>Nome</label>
      <input onBlur={formik.handleBlur} id='holder' className='input' value={formik.values.holder} onChange={formik.handleChange} />
      <p className='text-red-700 text-xs'>{formik.errors.holder}</p>
      <label htmlFor='cardNumber' className='label'>Número do Cartão</label>
      <input onBlur={formik.handleBlur} id='cardNumber' className='input' value={formik.values.cardNumber} onChange={({ target: { value } }) => setFieldValue('cardNumber', ccMask(value))} maxLength={19} />
      <p className='text-red-700 text-xs'>{formik.errors.cardNumber}</p>
      <div className='flex flex-row gap-2 h-36'>
        <div className='w-40'>
          <div className='w-40'>
            <label htmlFor='expirationDate' className='label'>Data de expiração</label>
            <input onBlur={formik.handleBlur} id='expirationDate' className='input' value={formik.values.expirationDate} onChange={({ target: { value } }) => setFieldValue('expirationDate', expirationMask(value))} />
          </div>
          <p className='text-red-700 text-xs flex flex-wrap text-wrap'>{formik.errors.expirationDate}</p>
        </div>
        <div className='w-1/4'>
          <label htmlFor='securityCode' className='label'>CVV</label>
          <input onBlur={formik.handleBlur} id='securityCode' className='input' value={formik.values.securityCode} onChange={({ target: { value } }) => setFieldValue('securityCode', cvvMask(value))} />
          <p className='text-red-700 text-xs text-wrap'>{formik.errors.securityCode}</p>
        </div>
      </div>
    </>
  )
}

export default CCForm
