import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../../utils'

const initialState = {
  doctor: null,
  searchDoctors: [],
  patientDoctors: [],
  groupMembers: []
}

export const componentsSelectDoctorSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setSearchDoctors: (state, { payload }) => {
      state.searchDoctors = payload
    },
    setDoctor: (state, { payload }) => {
      state.doctor = payload
    },
    setPatientDoctors: (state, { payload }) => {
      state.patientDoctors = payload
    }
  }
})

export const {
  reset,
  setSearchDoctors,
  setDoctor,
  patientDoctors,
  setPatientDoctors
} = componentsSelectDoctorSlice.actions

export default componentsSelectDoctorSlice.reducer
