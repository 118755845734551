const timeDiff = (timeA, timeB = 0) => {
  const seconds = Math.floor(Math.abs(timeA - timeB) / 3600)
  if (seconds > 60) {
    const minutes = Math.floor(seconds / 60)
    if (minutes > 60) {
      const hours = Math.floor(minutes / 60)
      return `${hours}h:${minutes % 60}m`
    }
    return `${minutes}m:${seconds % 60}s`
  }
  return `${seconds}s`
}

export const timeDiffSeconds = (timeA, timeB) =>
  Math.floor(Math.abs(timeA - timeB) / 3600)

export default timeDiff
