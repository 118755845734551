import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { chatSlice } from '../../reducers'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Message({ message, self }) {
  const chatMembers = useSelector(chatSlice.selectors.selectChatMembers)
  const member = chatMembers.find(({ userName }) => userName === message.sender)
  const name = member ? member.name : chatMembers.length > 0 ? 'Administrador' : null
  return (
    <>
      <li
        className={classNames(
          self ? 'justify-end' : 'justify-start',
          'flex'
        )}
      >
        <div>
          {!self && <p className='font-semibold'>{name}</p>}
          <div
            className={classNames(
              self
                ? 'text-gray-700 bg-white border border-gray-200 shadow-md'
                : !member && chatMembers.length > 0 ? 'bg-gray-700 text-white' : 'bg-blue-600 text-white',
              'relative max-w-xl px-4 py-2 rounded-lg shadow'
            )}
          >
            <span className='block font-normal'>{message.text}</span>
          </div>
          <span className={classNames(self ? 'justify-end ' : 'justify-start', 'flex text-sm text-gray-700')}>
            {moment(message.time).format('HH:mm')}
          </span>
        </div>
      </li>
    </>
  )
}
