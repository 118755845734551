import React from 'react'
import { useSelector } from 'react-redux'
import { adminSlice, tenantSlice } from '../../../../reducers'
import cities from '../../../address-form/cities.json'
import { PagesContext } from '../../../../contexts/pages'
import { useEvent } from '@emerald-works/react-event-bus-client'

const CreateSubscription = ({ plan, close }) => {
  const { setAdminPage, AdminPages, setAdminSubscriptionPageModal, AdminSubscriptionPageModals } = React.useContext(PagesContext)
  const tenant = useSelector(tenantSlice.selectors.selectTenant)
  const [getCreditCards] = useEvent([adminSlice.eventBus.getCreditCards])
  const creditCards = useSelector(adminSlice.selectors.selectCreditCards)
  const { state, city } = React.useMemo(() => {
    if (tenant?.city && tenant?.city.length) {
      const cityObject = cities.find(({ Código }) => Código === parseInt(tenant?.city))
      return { state: cityObject?.Estado, city: cityObject?.Município }
    }
  }, [tenant.city])
  const outdatedInfo = React.useMemo(() => !tenant?.name ||
    !tenant?.tenantKey ||
    !tenant?.identity ||
    !city ||
    !state ||
    !tenant?.district ||
    !tenant?.street ||
    !tenant?.number ||
    !tenant?.zipcode, [tenant, city, state])
  const [getSubscription] = useEvent([adminSlice.eventBus.getSubscription])
  const [newSubscription] = useEvent([{ ...adminSlice.eventBus.newSubscription, onSuccess: getSubscription.trigger }])

  React.useEffect(() => {
    getCreditCards.trigger()
  }, [getCreditCards])

  const [paymentMethod, setPaymentMethod] = React.useState(creditCards.length ? 2 : 1)
  const [creditCard, setCrediCard] = React.useState(null)

  const hasPaymentMethod = React.useMemo(() => paymentMethod === 1 || (paymentMethod === 2 && creditCard), [creditCard, paymentMethod])

  const createSubscription = React.useCallback(() => {
    newSubscription.trigger({ idPlan: plan?.idPlan, paymentMethod, Token: creditCard?.Token })
    close()
  }, [close, creditCard, newSubscription, paymentMethod, plan])

  const TenantInfo = () => (
    <div>
      <div>Os dados de sua organização estão atualizados?</div>
      <div className='border rounded p-4 mb-4'>
        <div className='text-sm pl-2'>
          <div>Nome: <span className='font-bold'>{tenant?.name}</span></div>
          <div>ID: <span className='font-bold'>{tenant?.tenantKey}</span></div>
          <div>E-mail: <span className='font-bold'>{tenant?.adminEmail}</span></div>
          <div>CNPJ: <span className='font-bold'>{tenant?.identity}</span></div>
          <div>Estado: <span className='font-bold'>{city}</span></div>
          <div>Cidade: <span className='font-bold'>{state}</span></div>
          <div>Bairro: <span className='font-bold'>{tenant?.district}</span></div>
          <div>Rua: <span className='font-bold'>{tenant?.street}</span></div>
          <div>Número: <span className='font-bold'>{tenant?.number}</span></div>
          <div>Complemento: <span className='font-bold'>{tenant?.complement}</span></div>
          <div>CEP: <span className='font-bold'>{tenant?.zipcode}</span></div>
          <label className='label'>E-mails de cobrança</label>
          {tenant?.orgAdminEmails?.length > 0
            ? (
              <div className='input max-h-48 flex flex-col'>
                {tenant?.orgAdminEmails.map((email, key) => (
                  <div className='py-1 border-b flex items-center justify-between' key={key}>
                    <div className='text-xs'>
                      {email}
                    </div>
                  </div>
                ))}
              </div>)
            : <p className='text-xs py-2'>Não há e-mails cadastrados.</p>}
        </div>
      </div>
    </div>
  )

  const Payment = React.memo(() => {
    return (
      <div>
        <div>Selecione um método de pagamento</div>
        <div className='border rounded p-4 mb-4'>
          <label className='label'>Método de pagamento</label>
          <div className='flex flex-row justify-between'>
            <div className='flex flex-row cursor-pointer' onClick={() => setPaymentMethod(1)}><input type='radio' className='mr-1' checked={paymentMethod === 1} onChange={() => { }} /><div>Boleto</div></div>
            <div className='flex flex-row cursor-pointer' onClick={() => setPaymentMethod(2)}><input type='radio' className='mr-1' checked={paymentMethod === 2} onChange={() => { }} /><div>Cartão de Crédito</div></div>
          </div>
          {paymentMethod === 2 && !creditCards.length && <p className='text-sm'>Não existem cartões cadastrados.</p>}
          {
            paymentMethod === 2 && creditCards.length > 0 &&
            (
              <div>
                <label className='label'>Selecione um dos cartões</label>
                <div className='input max-h-48 flex overflow-auto flex-col mt-2'>
                  {creditCards.map((card, key) => (
                    <div className='py-1 border-b flex flex-row items-center justify-center cursor-pointer' key={key} onClick={() => setCrediCard(card)}>
                      <input type='radio' className='mr-2' checked={creditCard === card} onChange={() => { }} />
                      <div>
                        {card?.Brand}: {card?.Holder} - {card?.CardNumber} - {card.Expiration}
                      </div>
                    </div>
                  ))}
                </div>
                {!creditCard && <p className='text-red-700 text-xs'>Você precisa selecionar um cartão de crédito.</p>}
              </div>)
          }
          {paymentMethod === 2 && <button className='btn-primary' onClick={() => { setAdminSubscriptionPageModal(AdminSubscriptionPageModals.PAYMENT); close() }}>Adicionar novo cartão de crédito</button>}
        </div>
      </div>
    )
  }, [paymentMethod, creditCards])

  return (
    <div>
      <div>Você está contrando o plano <span className='font-bold'>{plan?.name}</span> por <span className='font-bold'>R${plan?.amount}</span> com frequência <span className='font-bold'>{plan?.frequence?.toLowerCase()}</span>.</div>
      {outdatedInfo ? <div>Os dados de sua organização parecem desatualizados/incompletos.</div> : <><TenantInfo /><Payment /></>}
      <div className='justify-end w-full flex'>
        <button className='btn-primary' onClick={() => setAdminPage(AdminPages.ADMIN_ORG)}>Atualizar Dados</button>
        <button className='btn-primary' disabled={outdatedInfo || !hasPaymentMethod} onClick={createSubscription}>Tudo certo! Contratar Plano</button>
      </div>
    </div>
  )
}
export default CreateSubscription
