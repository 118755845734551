import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../../utils'

const initialState = {
  familyUsers: [],
  familyUser: null,
  patientFamily: []
}

export const componentsSelectFamilySlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setFamilyUsers: (state, { payload }) => {
      state.familyUsers = payload
    },
    setFamilyUser: (state, { payload }) => {
      state.familyUser = payload
    },
    setPatientFamily: (state, { payload }) => {
      state.patientFamily = payload
    }
  }
})

export const { setFamilyUsers, setFamilyUser, setPatientFamily, reset } =
  componentsSelectFamilySlice.actions

export default componentsSelectFamilySlice.reducer
