import React from 'react'
import Start from '../../components/dashboard/start'
import Patients from '../../components/dashboard/patients'
import Agenda from '../../components/dashboard/agenda'
import DashboardLeftBar from '../../components/dashboard/left-bar'
import Attendance from '../../components/dashboard/attendance'
import { PagesContext } from '../../contexts/pages'
import Chat from '../../components/dashboard/chat'
import ToolBar from '../../components/tool-bar'
import { useSelector } from 'react-redux'
import { coreSlice } from '../../reducers'
import AcceptTerms from '../../components/accept-terms'

const Dashboard = () => {
  const { dashBoardPage, DashBoardPages } = React.useContext(PagesContext)
  const conditionalRendering = {
    [DashBoardPages.START]: <Start />,
    [DashBoardPages.PATIENTS]: <Patients />,
    [DashBoardPages.AGENDA]: <Agenda />,
    [DashBoardPages.ATTENDANCE]: <Attendance />,
    [DashBoardPages.CHAT]: <Chat />
  }
  const patient = useSelector(coreSlice.selectors.selectPatient)
  const { role } = useSelector(coreSlice.selectors.selectUser)
  const mustSelectAPatient = role === 'family' && !patient?.pk
  return (
    <section className='flex flex-col bg-primary-gray h-screen overflow-hidden'>
      <ToolBar />
      <AcceptTerms />
      <div className='flex'>
        <DashboardLeftBar />
        <div className='pt-14 w-screen md:pl-40 overflow-auto'>
          {mustSelectAPatient ? <div className='text-center'>Selecione um paciente</div>
            : conditionalRendering[dashBoardPage ?? 0]}
        </div>
      </div>
    </section>
  )
}

export default Dashboard
