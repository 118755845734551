import React from 'react'
import {
  useConnectionContext,
  useEvent
} from '@emerald-works/react-event-bus-client'
import * as allReducers from '../reducers'
import { useDispatch, useSelector } from 'react-redux'
import LoadingPage from '../components/loading-page'
import { useLocation } from 'react-router-dom'

const { coreSlice, tenantSlice, ...reducers } = allReducers

export const EventContext = React.createContext(null)

export const useEventContext = () => {
  return React.useContext(EventContext)
}

export const EventContextProvider = ({ children }) => {
  const { pathname } = useLocation()
  const { connection, isConnected } = useConnectionContext()
  const [getUser, getPatient] = useEvent([
    coreSlice.eventBus.getUser,
    coreSlice.eventBus.getPatient
  ])
  const user = useSelector(coreSlice.selectors.selectUser)
  const authUser = useSelector(coreSlice.selectors.selectAuthUser)
  const patient = useSelector(coreSlice.selectors.selectPatient)
  const familyPatients = useSelector(coreSlice.selectors.selectFamilyPatients)
  const dispatcher = useDispatch()

  React.useEffect(() => {
    if (connection?.session?.isAuthenticated && isConnected) {
      getUser.trigger()
    }
  }, [isConnected, getUser, connection.session.isAuthenticated, authUser])

  React.useEffect(() => {
    if (user?.role === 'patient' && user?.patientDoc && !patient) {
      getPatient.trigger({ patientDoc: user?.patientDoc })
    }
  }, [getPatient, patient, user])

  React.useEffect(() => {
    if (user?.role === 'family') {
      if (user.patients.length === 1) {
        dispatcher(coreSlice.actions.setPatient(user.patients[0]))
      } else if (user.patients.length > 1) {
        const patient = localStorage.getItem('patient')
        const foundPatient = user.patients?.find(({ pk }) => pk === patient)
        if (foundPatient) {
          dispatcher(coreSlice.actions.setPatient(foundPatient))
        }
      }
      if (familyPatients.length === 0) {
        dispatcher(coreSlice.actions.setFamilyPatients(user.patients))
      }
    } else if (user?.role === 'patient') {
      dispatcher(coreSlice.actions.setPatient(user.patient))
    }
  }, [dispatcher, familyPatients.length, user])

  const cleanRedux = React.useCallback(() => {
    for (const key of Object.keys(reducers)) {
      dispatcher(reducers[key].actions.reset())
    }
  }, [dispatcher])

  React.useEffect(() => {
    if (patient) {
      cleanRedux()
    }
  }, [cleanRedux, patient])

  const isSignInPage = pathname.match(/\/[^/]+\/signin/)

  if (!user && !isSignInPage) {
    return <LoadingPage />
  }

  return (
    <EventContext.Provider
      value={{
        getUser,
        userLoading: getUser.isWorking
      }}
    >
      {children}
    </EventContext.Provider>
  )
}
