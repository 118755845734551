import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../utils'

const initialState = {
  searchDoctors: []
}

export const doctorSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setSearchDoctors: (state, { payload }) => {
      state.searchDoctors = payload
    }
  }
})

export const { reset, setSearchDoctors } = doctorSlice.actions

export default doctorSlice.reducer
