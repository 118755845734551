import React from 'react'
import { EventBusProvider } from '@emerald-works/react-event-bus-client'
import { AuthProvider } from '@emerald-works/react-auth'
import { authSettings } from '../libs/amplify'
import { UserAuthProvider } from './user-authentication'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { coreSlice, tenantSlice } from '../reducers'
import Api from '../services/api'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const localeText = {
  previousMonth: 'Mês anterior',
  nextMonth: 'Próximo mês',
  openPreviousView: 'Abrir próxima seleção',
  openNextView: 'Abrir seleção anterior',
  start: 'Início',
  end: 'Fim',
  cancelButtonLabel: 'Cancelar',
  clearButtonLabel: 'Limpar',
  okButtonLabel: 'OK',
  todayButtonLabel: 'Hoje',
  datePickerToolbarTitle: 'Selecione a data',
  dateTimePickerToolbarTitle: 'Selecione data e hora',
  timePickerToolbarTitle: 'Selecione a hora',
  dateRangePickerToolbarTitle: 'Selecione o intervalo entre datas',
  calendarWeekNumberHeaderLabel: 'Número da semana',
  calendarWeekNumberHeaderText: '#',
  timeTableLabel: 'escolha uma hora',
  dateTableLabel: 'escolha uma data'
}

export default function RootContextProvider({ children }) {
  const params = useParams()
  const { pathname } = useLocation()
  const tenant = useSelector(tenantSlice.selectors.selectTenant)
  const dispatcher = useDispatch()
  const eventBusAuthTokenConfig = {
    generate: true,
    apiGatewayAddress: process.env.REACT_APP_API_GATEWAY_ADDRESS,
    awsRegion: process.env.REACT_APP_API_GATEWAY_REGION,
    generateTokenEndpoint: '/generate-token'
  }
  const handleAuthRedirection = ({ Auth, federatedSignInParams }) => {
    Auth.federatedSignIn({
      ...federatedSignInParams
    })
  }

  React.useEffect(() => {
    if (!tenant) {
      async function fetchTenantInfo() {
        if (!params.tenantKey) {
          console.error('Tenant key is required')
          return
        }
        const { data } = await new Api().get('tenant-info', {
          tenantKey: params.tenantKey
        })
        if (!data) {
          if (pathname === `/${params.tenantKey}/admin`) {
            const { data } = await new Api().get('admin-tenant-info', {
              tenantKey: params.tenantKey
            })
            dispatcher(tenantSlice.actions.setTenant(data))
            dispatcher(coreSlice.actions.setNotActiveTenant(true))
            return
          }
          console.error('Error fetching client info')
          return
        }
        dispatcher(tenantSlice.actions.setTenant(data))
      }
      fetchTenantInfo()
    }
  }, [dispatcher, tenant, params.tenantKey, pathname])

  const amplifyAuthConfig = () => {
    if (!tenant) return null
    const baseAuthSettings = {
      ...authSettings(params),
      ...tenant.cognitoSettings
    }
    baseAuthSettings.oauth.domain =
      tenant.customDomain ||
      `${tenant.cognitoSettings.userPoolDomain}.auth.${tenant.cognitoSettings.region}.amazoncognito.com`
    return baseAuthSettings
  }

  if (!tenant) {
    return null
  }
  return (
    <LocalizationProvider localeText={localeText} dateAdapter={AdapterMoment}>
      <AuthProvider
        amplifyAuthConfig={amplifyAuthConfig()}
        onAuthNeededRedirection={handleAuthRedirection}
        allowGuestUsers
        signOutPath='/signout'
        eventBusAuthTokenConfig={eventBusAuthTokenConfig}
        LoadingAuthComponent={<>Loading</>}
        AuthenticatingAuthComponent={<>AuthenticatingAuthComponent</>}
      >
        <EventBusProvider
          eventBusURL={
            tenant.eventBusUrl || process.env.REACT_APP_EVENT_BUS_URL
          }
          connectionParams={{
            tenantKey: tenant?.tenantKey,
            maxAttempts: 5
          }}
          initialisers={{}}
          options={{
            socketIoUrl:
              tenant.eventBusBackupURL ||
              process.env.REACT_APP_EVENT_BUS_BACKUP_URL ||
              'https://socketio.ewnova.dev',
            maxAttempts: 5
          }}
          useAuthProvider
          namespace='vi'
        >
          <UserAuthProvider>{children}</UserAuthProvider>
        </EventBusProvider>
      </AuthProvider>
    </LocalizationProvider>
  )
}
