import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../utils'

const initialState = {
  users: [],
  searchUsers: []
}

export const users = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setUsers: (state, { payload }) => {
      const { users } = state
      for (const user of payload) {
        const index = users.findIndex(({ pk }) => pk === user.pk)
        if (index >= 0) {
          state.users[index] = user
        } else {
          state.users = [...state.users, user]
        }
      }
    },
    setSearchUsers: (state, { payload }) => {
      const { searchUsers } = state
      for (const user of payload) {
        const index = searchUsers.findIndex(({ pk }) => pk === user.pk)
        if (index >= 0) {
          state.searchUsers[index] = user
        } else {
          state.searchUsers = [...state.searchUsers, user]
        }
      }
    },
    cleanSearchUsers: (state) => {
      state.searchUsers = []
    }
  }
})

export const { setUsers, setSearchUsers, cleanSearchUsers, reset } = users.actions

export default users.reducer
