import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { componentsSelectPatientSlice, patientSlice } from '../../reducers'
import Select from 'react-select'

const SelectPatient = ({ readOnly, modal, keepState }) => {
  const patientsPerPage = 5
  const [search, setSearch] = React.useState('')
  const [searchBy, setSearchBy] = React.useState('name')
  const [isSearching, setIsSearching] = React.useState(false)
  const [searchPatients] = useEvent([componentsSelectPatientSlice.eventBus.searchPatients])
  const patientsFromSearch = useSelector(
    componentsSelectPatientSlice.selectors.selectSearchPatients
  )
  const patient = useSelector(componentsSelectPatientSlice.selectors.selectPatient)

  const dispatcher = useDispatch()

  React.useEffect(() => {
    if (!isSearching) {
      dispatcher(patientSlice.actions.setSearchPatients([]))
    }
  }, [dispatcher, isSearching])

  const searchForPatients = React.useCallback(() => {
    if (search.length >= 3) {
      setIsSearching(true)
      searchPatients.trigger({ limit: patientsPerPage, search, searchBy })
    }
  }, [search, searchBy, searchPatients])

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(searchForPatients, 2000)
    return () => clearTimeout(delayDebounceFn)
  }, [search, searchBy, searchForPatients, searchPatients])

  React.useEffect(() => {
    if (!keepState) {
      dispatcher(componentsSelectPatientSlice.actions.reset())
    }
  }, [dispatcher, keepState])

  const options = patientsFromSearch.map(({ pk, name }) => ({
    label: `${name} - ${pk}`,
    id: pk
  }))

  const noOptionsMessage = () => (
    <>
      {searchPatients.isWorking
        ? 'Buscando pacientes'
        : !patientsFromSearch.length && isSearching
          ? 'Não foram encontrados pacientes'
          : `Digite o ${searchBy === 'doc' ? 'documento' : 'nome'} do paciente`}
    </>
  )

  const setPatient = ({ id }) => {
    const patient = patientsFromSearch.find(({ pk }) => pk === id)
    dispatcher(componentsSelectPatientSlice.actions.setPatient(patient))
    setIsSearching(false)
  }

  return (
    <div className={`flex ${modal ? 'md:w-full' : 'md:w-[48rem]'} w-full flex-col md:flex-row gap-1`}>
      {!readOnly && (
        <div className='flex flex-col pb-2'>
          <label className='text-secondary-gray text-xs' htmlFor='searchBy'>
            Buscar usando
          </label>
          <label className='flex' id='searchBy'>
            <div
              onClick={() => setSearchBy('name')}
              className={`text-sm px-2 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'name' ? 'bg-secondary-gray' : 'bg-transparent'}`}
            >
              Nome
            </div>
            <div
              onClick={() => setSearchBy('doc')}
              className={`text-sm px-2 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'doc' ? 'bg-secondary-gray' : 'bg-transparent'}`}
            >
              Documento
            </div>
          </label>
        </div>
      )}
      <Select
        styles={{
          placeholder: (styles) => ({
            ...styles,
            color: readOnly ? styles.color : 'black'
          }),
          input: (styles) => ({
            ...styles,
            paddingTop: 10,
            paddingBottom: 10,
            color: 'black'
          }),
          control: (styles) => ({
            ...styles,
            backgroundColor: 'transparent'
          })
        }}
        isDisabled={readOnly}
        className='w-64 text-xs z-10'
        value={search}
        onChange={setPatient}
        onInputChange={setSearch}
        noOptionsMessage={noOptionsMessage}
        options={options}
        placeholder={
          patient?.pk
            ? `${patient.name} - ${patient.pk}`
            : 'Selecione o paciente'
        }
      />
    </div>
  )
}
export default SelectPatient
