import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../utils'

const initialState = {
  meeting: null,
  rtmToken: null
}

export const meeting = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setMeeting: (state, { payload }) => {
      state.meeting = payload
    },
    setRtmToken: (state, { payload }) => {
      state.rtmToken = payload
    }
  }
})

export const { setMeeting, setRtmToken, reset } = meeting.actions

export default meeting.reducer
