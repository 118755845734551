import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../../utils'

const initialState = {
  patient: null,
  isWorking: false,
  isSearching: false
}

export const componentsSearchPatientsSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setPatient: (state, { payload }) => {
      state.patient = payload
    },
    setIsWorking: (state, { payload }) => {
      state.isWorking = payload
    },
    setIsSearching: (state, { payload }) => {
      state.isSearching = payload
    }
  }
})

export const {
  setPatient,
  reset,
  setIsSearching,
  setIsWorking
} = componentsSearchPatientsSlice.actions

export default componentsSearchPatientsSlice.reducer
