import React from 'react'
import CCForm from '../../../cc-form'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ConfirmModal from '../../../confirm-modal'
import { useSelector } from 'react-redux'
import { adminSlice } from '../../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { ClearIcon } from '@mui/x-date-pickers'
import { nameRegex, ccRegex, cvvRegex, expirationRegex } from '../../../../constants/regex'

const PaymentMethods = () => {
  const [showRemoveModal, setShowRemoveModal] = React.useState(false)
  const creditCards = useSelector(adminSlice.selectors.selectCreditCards)
  const [getCreditCards] = useEvent([adminSlice.eventBus.getCreditCards])
  const [removeCreditCard, addCreditCard] = useEvent([{ ...adminSlice.eventBus.removeCreditCard, onSuccess: getCreditCards.trigger }, { ...adminSlice.eventBus.addCreditCard, onSuccess: getCreditCards.trigger }])
  const [cardToRemove, setCardToRemove] = React.useState(null)

  React.useEffect(() => {
    getCreditCards.trigger()
  }, [getCreditCards])

  const removeCC = React.useCallback(() => {
    removeCreditCard.trigger(cardToRemove)
    setShowRemoveModal(false)
  }, [cardToRemove, removeCreditCard])

  const handleRemoveCardClick = React.useCallback(card => {
    setCardToRemove(card)
    setShowRemoveModal(true)
  }, [])

  const { setFieldValue, resetForm, ...formik } = useFormik({
    initialValues: {
      holder: '',
      cardNumber: '',
      expirationDate: '',
      securityCode: ''
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      holder: Yup.string().required('O nome é obrigatório.').matches(nameRegex, { message: 'O nome pode conter apenas letras, números e espaços.' }),
      cardNumber: Yup.string().required('O número do cartão é obrigatório').matches(ccRegex, 'O número deve conter 16 dígitos.'),
      securityCode: Yup.string().required('O CVV é obrigatório.').matches(cvvRegex, 'O CVV deve conter 3 dígitos.'),
      expirationDate: Yup.string().required('A data de expiração é obrigatória.').matches(expirationRegex, 'A data de expiração deve estar no formato MM/YYYY.')
    })
  })

  const handleAddCreditCard = React.useCallback(() => {
    addCreditCard.trigger(formik.values)
    resetForm()
  }, [addCreditCard, formik.values, resetForm])

  const isValidPayment = React.useMemo(() => (!formik.errors.holder &&
    !formik.errors.cardNumber &&
    !formik.errors.expirationDate &&
    !formik.errors.securityCode), [formik.errors])

  return (
    <div className='w-[90vw] md:w-[600px] overflow-auto flex flex-col gap-4'>
      <ConfirmModal content={`Remover cartão ${cardToRemove?.Brand} ${cardToRemove?.Holder} - ${cardToRemove?.CardNumber} - ${cardToRemove?.Expiration}?`} open={showRemoveModal} onCancel={() => setShowRemoveModal(false)} onConfirm={removeCC} />
      <div>
        <label className='label'>Cartões de Crédito</label>
        {!creditCards.length && <p className='text-sm'>Não existem cartões cadastrados.</p>}
        {creditCards.length > 0 &&
          <div className='input max-h-48 flex overflow-auto flex-col'>
            {creditCards.map((card, key) => (
              <div className='h-14 border-b flex items-center justify-between' key={key}>
                <div>
                  {card?.Brand}: {card?.Holder} - {card?.CardNumber} - {card.Expiration}
                </div>
                <div>
                  <ClearIcon onClick={() => { handleRemoveCardClick(card) }} className='icon cursor-pointer hover:text-gray-900' />
                </div>
              </div>
            ))}
          </div>}
      </div>
      <div>
        <label className='label'>Adicionar novo cartão de crédito</label>
        <CCForm formik={formik} setFieldValue={setFieldValue} />
        <div className='flex w-full justify-end'>
          <button className='btn-primary' disabled={!isValidPayment} onClick={handleAddCreditCard}>Adicionar</button>
        </div>
      </div>
    </div>
  )
}
export default PaymentMethods
