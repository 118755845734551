export const nameRegex = /[\w\s\d]{1,}/
export const ccRegex = /(\d{4}\s){3}\d{4}$/
export const cvvRegex = /\d\d\d/
export const expirationRegex = /\d{2}\/\d{4}/
export const tenantRegex = /[a-z\d]{1,15}/
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const cepRegex = /\d{5}-\d{3}/
export const cnpjRegex = /\d{2}(\.\d{3}){2}\/\d{4}-\d{2}/
export const cognitoPasswordRegex =
  /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
