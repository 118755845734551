import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { componentsSelectUserSlice, coreSlice } from '../../reducers'
import Select from 'react-select'

const SelectUser = ({ readOnly }) => {
  const usersPerPage = 5
  const [search, setSearch] = React.useState('')
  const [searchBy, setSearchBy] = React.useState('name')
  const [isSearching, setIsSearching] = React.useState(false)
  const [searchUsers] = useEvent([componentsSelectUserSlice.eventBus.searchUsers])
  const usersFromSearch = useSelector(
    componentsSelectUserSlice.selectors.selectSearchUsers
  )
  const user = useSelector(componentsSelectUserSlice.selectors.selectUser)
  const coreUser = useSelector(coreSlice.selectors.selectUser)
  const users = useSelector(componentsSelectUserSlice.selectors.selectUsers)

  const dispatcher = useDispatch()

  React.useEffect(() => {
    if (!isSearching) {
      dispatcher(componentsSelectUserSlice.actions.setSearchUsers([]))
    }
  }, [dispatcher, isSearching])

  React.useEffect(() => {
    dispatcher(componentsSelectUserSlice.actions.setUser(null))
  }, [dispatcher])

  const searchForUsers = React.useCallback(() => {
    if (search.length >= 3) {
      setIsSearching(true)
      searchUsers.trigger({ limit: usersPerPage, search, searchBy })
    }
  }, [search, searchBy, searchUsers])

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(searchForUsers, 2000)
    return () => clearTimeout(delayDebounceFn)
  }, [search, searchBy, searchForUsers, searchUsers])

  const filteredUsersFromSearch = React.useMemo(() => usersFromSearch.filter(({ pk }) => pk !== coreUser.pk).filter(user => users.find(({ pk }) => pk === user.pk) === undefined), [coreUser, user, users, usersFromSearch])

  const options = filteredUsersFromSearch.map(({ pk, name }) => ({
    label: `${name} - ${pk}`,
    id: pk
  }))

  const noOptionsMessage = () => (
    <>
      {searchUsers.isWorking
        ? 'Buscando usuários'
        : !filteredUsersFromSearch.length && isSearching
          ? 'Não foram encontrados usuários'
          : `Digite o ${searchBy === 'email' ? 'e-mail' : 'nome'} do usuário`}
    </>
  )

  const setUser = ({ id }) => {
    const user = usersFromSearch.find(({ pk }) => pk === id)
    dispatcher(componentsSelectUserSlice.actions.setUser(user))
    setIsSearching(false)
  }

  return (
    <div className='flex md:w-[40rem] w-full flex-col md:flex-row gap-1'>
      {!readOnly && (
        <div className='flex flex-col pb-2'>
          <label className='text-secondary-gray text-xs' htmlFor='searchBy'>
            Buscar usando
          </label>
          <label className='flex' id='searchBy'>
            <div
              onClick={() => setSearchBy('name')}
              className={`text-sm px-2 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'name' ? 'bg-secondary-gray' : 'bg-transparent'}`}
            >
              Nome
            </div>
            <div
              onClick={() => setSearchBy('email')}
              className={`text-sm px-2 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'email' ? 'bg-secondary-gray' : 'bg-transparent'}`}
            >
              Email
            </div>
          </label>
        </div>
      )}
      <Select
        styles={{
          placeholder: (styles) => ({
            ...styles,
            color: readOnly ? styles.color : 'black'
          }),
          input: (styles) => ({
            ...styles,
            paddingTop: 10,
            paddingBottom: 10,
            color: 'black'
          }),
          control: (styles) => ({
            ...styles,
            backgroundColor: 'transparent'
          })
        }}
        isDisabled={readOnly}
        className='w-full text-xs z-10'
        value={search}
        onChange={setUser}
        onInputChange={setSearch}
        noOptionsMessage={noOptionsMessage}
        options={options}
        placeholder={
          user?.pk
            ? `${user.name} - ${user.email}`
            : 'Selecione o usuário'
        }
      />
    </div>
  )
}
export default SelectUser
