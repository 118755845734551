import * as actions from './slice'

export const getPatient = {
  eventName: 'getPatient',
  onSuccess: {
    action: actions.setPatient,
    redux: true
  }
}

export const searchPatients = {
  eventName: 'getPatients',
  onSuccess: {
    action: actions.setSearchPatients,
    redux: true
  }
}
