import * as actions from './slice'

export const getUsers = {
  eventName: 'getUsers',
  onSuccess: {
    action: actions.setUsers,
    redux: true
  }
}

export const searchUsers = {
  eventName: 'getUsers',
  onSuccess: {
    action: actions.setSearchUsers,
    redux: true
  }
}
