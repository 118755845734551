import React from 'react'
import { useDispatch } from 'react-redux'
import { adminSlice } from '../../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { PagesContext } from '../../../../contexts/pages'
import { cpf } from 'cpf-cnpj-validator'
import { applyCpfString, fixCpfString } from '../../../../utils/mask'

const CreatePatient = () => {
  const { setPatientPage, AdminPatientPages } = React.useContext(PagesContext)
  const [selectedPatient, setSelectedPatient] = React.useState({ name: '', doc: '' })
  const [createPatient] = useEvent([adminSlice.eventBus.createPatient])
  const setPatient = user => {
    dispatcher(adminSlice.actions.setPatient(user))
  }

  const [isSupervised, setIsSupervised] = React.useState(false)

  const dispatcher = useDispatch()

  const setPatientInfo = React.useCallback((field, value) => {
    setSelectedPatient({ ...selectedPatient, [field]: value })
  }, [selectedPatient])

  const patientToCreate = React.useMemo(() => ({ ...selectedPatient }), [selectedPatient])

  const create = () => {
    createPatient.trigger({ ...patientToCreate })
    dispatcher(adminSlice.actions.setPatient(patientToCreate))
    setPatient(null)
    setPatientPage(AdminPatientPages.ADMIN_PATIENT_LIST)
  }

  const isValidCpf = React.useMemo(() => cpf.isValid(selectedPatient?.doc), [selectedPatient.doc])
  const isAbleToCreate = React.useMemo(() => !createPatient.isWorking && selectedPatient.name && isValidCpf, [createPatient.isWorking, isValidCpf, selectedPatient.name])

  return (
    <section className='py-2 font-inter flex flex-col w-full px-8'>
      <div className='inline-flex items-center justify-between my-2'>
        <div className='title inline-flex'>
          <button
            className='outline-none border-none'
          >
            Novo Paciente
          </button>
        </div>
        <div className='flex'>
          <button className={isAbleToCreate ? 'btn-primary' : 'btn-disabled'} disabled={!isAbleToCreate} onClick={create}>Criar</button>
          <button className='btn-secondary' onClick={() => { setPatient(null); setPatientPage(AdminPatientPages.ADMIN_USER_LIST) }}>Voltar</button>
        </div>
      </div>

      <form className='w-full'>
        <div className='mb-5'>
          <label htmlFor='name' className='label'>Nome</label>
          <input type='text' id='name' className='input' value={selectedPatient?.name} onChange={({ target: { value } }) => setPatientInfo('name', value)} />
        </div>
        <div className='mb-5'>
          <label htmlFor='doc' className='label'>Documento (CPF)</label>
          <input type='text' id='doc' className='input' value={applyCpfString(selectedPatient?.doc)} onChange={({ target: { value } }) => setPatientInfo('doc', fixCpfString(value))} />
        </div>
      </form>

    </section>
  )
}

export default CreatePatient
