import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAuth } from '../../../contexts/user-authentication'
import { cognitoPasswordRegex } from '../../../constants/regex'

const Register = ({ setCurrentStep, setRegisterData }) => {
  const { signUp } = useAuth()

  const { handleBlur, handleSubmit, values, touched, handleChange, errors } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
        confirmPassword: ''
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email('Insira um e-mail válido')
          .required('O e-mail é obrigatório'),
        password: Yup.string().required('A senha é obrigatória').matches(cognitoPasswordRegex, 'Senha inválida'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
          .required('Confirmação da senha é obrigatória')
      }),
      onSubmit: (values) => {
        setRegisterData(values)
        signUp(values.email, values.password, setCurrentStep)
      }
    })

  return (
    <div className='flex flex-col p-8 w-full'>
      <div className='bg-white rounded-lg shadow-lg w-full lg:w-[448px] xl:p-0'>
        <div className='p-6 md:space-y-6 sm:p-8 w-full'>
          <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
            Crie sua conta
          </h1>
          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label
                htmlFor='email'
                className='block mb-2 text-sm font-medium text-gray-900'
              >
                Seu email
              </label>
              <input
                type='email'
                name='email'
                id='email'
                className='input-with-error'
                placeholder='name@company.com'
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {touched.email && errors.email ? <div>{errors.email}</div> : null}
            </div>
            <div className='mb-4'>
              <label
                htmlFor='password'
                className='block mb-2 text-sm font-medium text-gray-900'
              >
                Senha
              </label>
              <input
                type='password'
                name='password'
                id='password'
                placeholder='••••••••'
                className='input-with-error'
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {touched.password && errors.password ? (
                <div>{errors.password}</div>
              ) : null}
            </div>
            <div className='mb-4'>
              <label
                htmlFor='confirmPassword'
                className='block mb-2 text-sm font-medium text-gray-900'
              >
                Repita a senha
              </label>
              <input
                type='password'
                name='confirmPassword'
                id='confirmPassword'
                placeholder='••••••••'
                className='input-with-error'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                required
              />
              {touched.confirmPassword && errors.confirmPassword ? (
                <div>{errors.confirmPassword}</div>
              ) : null}
            </div>
            <div className='text-sm pb-2'>
              <p>Sua senha deve conter ao menos:</p>
              <ul>
                <li>8 caracteres</li>
                <li>1 letra maiúscula</li>
                <li>1 letra minúscula</li>
                <li>1 caractere especial</li>
              </ul>
            </div>
            <button
              type='submit'
              className='signin-btn'
            >
              Criar
            </button>
            <p className='text-sm font-light text-gray-500'>
              Ja tem uma conta?{' '}
              <button
                onClick={() => setCurrentStep(0)}
                type='button'
                className='font-medium text-primary-blue hover:underline'
              >
                Logue aqui
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Register
