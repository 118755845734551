import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../../utils'

const initialState = {
  patient: null,
  isSearching: false,
  isWorking: false
}

const dashboardChatSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setIsSearching: (state, { payload }) => {
      state.isSearching = payload
    },
    setIsWorking: (state, { payload }) => {
      state.isWorking = payload
    },
    setPatient: (state, { payload }) => {
      state.patient = payload
    }
  }
})

export const {
  reset,
  setPatient,
  setIsSearching,
  setIsWorking
} = dashboardChatSlice.actions

export default dashboardChatSlice.reducer
