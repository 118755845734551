import name from './name'

const selectSliceData = state => state[name]

const selectMeeting = state => selectSliceData(state).meeting
const selectRtmToken = state=>selectSliceData(state).rtmToken

export {
  selectMeeting,
  selectRtmToken
}
