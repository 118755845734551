import React, { createContext, useContext, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import * as reducers from '../reducers'
import { useConnectionContext } from '@emerald-works/react-event-bus-client'

const UserAuthContext = createContext()

export const UserAuthProvider = ({ children }) => {
  const context = useConnectionContext()
  const dispatcher = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const checkUser = React.useCallback(
    async function () {
      try {
        const userData = await Auth.currentAuthenticatedUser()
        const {
          signInUserSession: {
            idToken: {
              payload: { 'cognito:groups': cognitoGroups }
            }
          }
        } = userData
        const authUser = { ...userData.attributes, cognitoGroups }
        dispatcher(reducers.coreSlice.actions.setAuthUser(authUser))
      } catch (error) {
        const { tenantKey, '*': path } = params
        if (path !== 'signin') {
          navigate('signin', { state: { redirect: `/${tenantKey}/${path}` } })
        }
        console.error('Not signed in')
      }
    },
    [dispatcher, navigate]
  )

  const signIn = React.useCallback(
    async (email, password) => {
      try {
        const userData = await Auth.signIn(email, password)
        context.reloadConnection()
        const {
          signInUserSession: {
            idToken: {
              payload: { 'cognito:groups': cognitoGroups }
            }
          }
        } = userData
        const authUser = { ...userData.attributes, cognitoGroups }
        dispatcher(reducers.coreSlice.actions.setAuthUser(authUser))
        toast.success('Usuario logado com sucesso!', {
          toastId: 'toast-success-login'
        })
        await checkUser()
      } catch (error) {
        switch (error.code) {
          case 'UserNotConfirmedException':
            throw error
          default:
            toast.error(
              'Ocorreu um erro ao realizar o login, verifique seus dados e tente novamente!',
              {
                toastId: 'toast-error-login'
              }
            )
        }
      }
    },
    [context, dispatcher, checkUser]
  )

  const confirmUser = React.useCallback(
    async (registerData, otp) => {
      try {
        await Auth.confirmSignUp(registerData.email, otp)
        const userData = await Auth.signIn(
          registerData.email,
          registerData.password
        )
        context.reloadConnection()
        const {
          signInUserSession: {
            idToken: {
              payload: { 'cognito:groups': cognitoGroups }
            }
          }
        } = userData
        const authUser = { ...userData.attributes, cognitoGroups }
        dispatcher(reducers.coreSlice.actions.setAuthUser(authUser))
        toast.success('Usuario cadastrado com sucesso !', {
          toastId: 'toast-success-confirm-user'
        })
      } catch (error) {
        toast.error(
          'Ocorreu um erro ao confirmar sua conta, verifique o codigo digitado ou reenvie o codigo e tente novamente.',
          {
            toastId: 'toast-error-confirm-user'
          }
        )
      }
    },
    [context, dispatcher]
  )

  const signUp = React.useCallback(
    async (email, password, setCurrentStep, attributes = {}) => {
      try {
        await Auth.signUp({
          username: email,
          password,
          attributes
        })

        setCurrentStep(2)
      } catch (error) {
        toast.error('Ocorreu um erro ao realizar o registro !', {
          toastId: 'toast-error-register-user'
        })
      }
    },
    []
  )

  const forgotPassword = React.useCallback((email) => {
    const forgot = async (email) => {
      await Auth.forgotPassword(email)
    }
    forgot(email)
  }, [])

  const forgotPasswordSubmit = React.useCallback(
    async ({ email, code, password }) => {
      try {
        await Auth.forgotPasswordSubmit(email, code, password)
        await signIn(email, password)
      } catch (error) {
        toast.error('Ocorreu um erro ao atualizar a senha!', {
          toastId: 'toast-error-update-password'
        })
      }
    },
    [signIn]
  )

  const resendSignUp = React.useCallback((email) => {
    const resend = async () => {
      try {
        await Auth.resendSignUp(email)

        toast.success('Codigo reenviado com sucesso !', {
          toastId: 'toast-success-code-resend'
        })
      } catch (error) {
        console.log({ error })
        toast.error('Ocorreu um erro reenviar o codigo!', {
          toastId: 'toast-error-code-resend'
        })
      }
    }
    resend(email)
  }, [])

  const resetRedux = React.useCallback(() => {
    for (const key of Object.keys(reducers)) {
      dispatcher(reducers[key].actions.reset())
    }
  }, [dispatcher])

  const singOut = React.useCallback(() => {
    const signout = async () => {
      try {
        await Auth.signOut()
        resetRedux()
        context.reloadConnection()
        dispatcher(reducers.coreSlice.actions.setAuthUser(null))
        navigate('signin')
        toast.success('Usuario deslogado com sucesso !', {
          toastId: 'toast-success-singout-user'
        })
      } catch (error) {
        toast.error('Ocorreu um erro ao deslogar o usuario!', {
          toastId: 'toast-error-signout-user'
        })
      }
    }
    signout()
  }, [context, dispatcher, navigate, resetRedux])

  useEffect(() => {
    checkUser()
  }, [checkUser])

  return (
    <UserAuthContext.Provider
      value={{
        signIn,
        singOut,
        signUp,
        confirmUser,
        resendSignUp,
        resetRedux,
        forgotPassword,
        forgotPasswordSubmit
      }}
    >
      {children}
    </UserAuthContext.Provider>
  )
}

export const useAuth = () => useContext(UserAuthContext)
