import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../utils'

const initialState = {
  tenant: null
}

export const tenant = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setTenant: (state, { payload }) => {
      state.tenant = payload
    }
  }
})

export const { setTenant, reset } = tenant.actions

export default tenant.reducer
