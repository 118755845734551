import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminSlice, componentsSelectFamilySlice } from '../../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import ClearIcon from '@mui/icons-material/Clear'
import ConfirmModal from '../../../confirm-modal'
import SelectFamily from '../../../select-family'
import Loading from '../../../loading'

const ListFamily = () => {
  const dispatcher = useDispatch()
  const familyUser = useSelector(componentsSelectFamilySlice.selectors.selectFamilyUser)
  const adminPatient = useSelector(adminSlice.selectors.selectPatient)
  const [showAddModal, setShowAddModal] = React.useState(false)
  const [showRemoveModal, setShowRemoveModal] = React.useState(false)
  const [familyToRemove, setFamilyToRemove] = React.useState(null)
  const [getPatientFamily] = useEvent([componentsSelectFamilySlice.eventBus.getPatientFamily])
  const patientFamily = useSelector(componentsSelectFamilySlice.selectors.selectPatientFamily)

  const fetchPatientFamily = React.useCallback(() => {
    getPatientFamily.trigger({ patient: adminPatient?.pk })
  }, [adminPatient, getPatientFamily])

  React.useEffect(() => {
    fetchPatientFamily()
  }, [fetchPatientFamily])

  const [addToFamily, removeFromFamily] = useEvent([{ ...componentsSelectFamilySlice.eventBus.addToFamily, onSuccess: fetchPatientFamily }, { ...componentsSelectFamilySlice.eventBus.removeFromFamily, onSuccess: fetchPatientFamily }])

  const addFamily = () => {
    addToFamily.trigger({ family: familyUser?.pk, patient: adminPatient?.pk })
    dispatcher(componentsSelectFamilySlice.actions.setFamilyUser(null))
    setShowAddModal(false)
  }

  const removeFamily = () => {
    removeFromFamily.trigger({ family: familyToRemove?.pk, patient: adminPatient?.pk })
    setFamilyToRemove(null)
    setShowRemoveModal(false)
  }

  const handleRemoveFamilyClick = family => {
    setFamilyToRemove(family)
    setShowRemoveModal(true)
  }

  const state = React.useMemo(() => {
    if (getPatientFamily.isWorking ||
      addToFamily.isWorking ||
      removeFromFamily.isWorking) {
      return 'loading'
    }
    if (!patientFamily.length) {
      return 'empty'
    }
    return 'done'

  }, [getPatientFamily.isWorking, addToFamily.isWorking, removeFromFamily.isWorking, patientFamily])

  return (
    <>
      <ConfirmModal content={`Adicionar ${familyUser?.name} - ${familyUser?.email} como familiar do paciente ${adminPatient?.name} - ${adminPatient?.pk}?`} open={showAddModal} onCancel={() => setShowAddModal(false)} onConfirm={addFamily} />
      <ConfirmModal content={`Remover ${familyToRemove?.name} - ${familyToRemove?.email} da lista de familiares do paciente ${adminPatient?.name} - ${adminPatient?.pk}?`} open={showRemoveModal} onCancel={() => setShowRemoveModal(false)} onConfirm={removeFamily} />
      <label className='label'>Familiares</label>
      {state === 'empty' && <>Não existem famliares designados para este paciente.</>}
      {state === 'loading' && <div className='flex justify-center'><Loading size={10} /></div>}
      {state === 'done' &&
        <div className='input max-h-48 flex overflow-auto flex-col'>
          <table className='w-full'>
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Remover</th>
              </tr>
            </thead>
            {patientFamily.map((doctorInfo, key) => (
              <tr key={key}>
                <td className='text-center'>
                  {doctorInfo?.name}
                </td>
                <td className='text-center'>{doctorInfo?.email}</td>
                <td className='text-center'>
                  <ClearIcon onClick={() => { handleRemoveFamilyClick(doctorInfo) }} className='icon cursor-pointer hover:text-gray-900' />
                </td>
              </tr>
            ))}
          </table>
        </div>
      }
      <label className='label'>Adicionar Familiar</label>
      <div className='flex flex-col md:flex-row items-center justify-start'>
        <SelectFamily />
        <button onClick={() => setShowAddModal(true)} className={familyUser?.pk ? 'btn-primary' : 'btn-disabled'} disabled={!familyUser?.pk}>Adicionar</button>
      </div>
    </>
  )
}

export default ListFamily
