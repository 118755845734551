import { useFormik } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { tenantSlice } from '../../../reducers'
import { cepRegex, cnpjRegex, emailRegex, nameRegex, tenantRegex } from '../../../constants/regex'
import { cnpjMask, emailMask, nameMask } from '../../../utils/mask'
import { ClearIcon } from '@mui/x-date-pickers'
import AddressForm from '../../address-form'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { deepEqual } from '../../../utils/deepEqual'
import Loading from '../../loading'

const Organization = () => {
  const tenant = useSelector(tenantSlice.selectors.selectTenant)
  const [getTenant] = useEvent([tenantSlice.eventBus.getTenant])
  const [updateTenant] = useEvent([{ ...tenantSlice.eventBus.updateTenant, onSuccess: getTenant.trigger }])
  const { setFieldValue, ...formik } = useFormik({
    initialValues: {
      name: tenant?.name,
      tenantKey: tenant?.tenantKey,
      identity: tenant?.identity,
      ownerEmail: tenant?.ownerEmail,
      ownerEmails: tenant?.ownerEmails,
      email: '',
      city: tenant?.city,
      district: tenant?.district,
      street: tenant?.street,
      number: tenant?.number,
      complement: tenant?.complement,
      zipcode: tenant?.zipcode
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      name: Yup.string().required('O nome é obrigatório.').matches(nameRegex, { message: 'O nome pode conter apenas letras, números e espaços.' }),
      tenantKey: Yup.string().required('O id é obrigatório.').matches(tenantRegex, { message: 'O id pode conter apenas letras minúsculas e números.' }),
      ownerEmail: Yup.string().required('O email do proprietário é obrigatório.').matches(emailRegex, { message: 'Digite um e-mail válido.' }),
      ownerEmails: Yup.array().required('É necessário ter ao menos um e-mail'),
      identity: Yup.string().required('O CNPJ é obrigatório.').matches(cnpjRegex, { message: 'Digite um CNPJ válido' }),
      email: Yup.string().matches(emailRegex, { message: 'Digite um e-mail válido.' }),
      city: Yup.string().required('A cidade é obrigatória.'),
      district: Yup.string().required('O bairro é obrigatório.'),
      street: Yup.string().required('A rua é obrigatória.'),
      number: Yup.string().required('O número é obrigatório.'),
      complement: Yup.string().notRequired(),
      zipcode: Yup.string().required('O CEP é obrigatório.').matches(cepRegex, 'O CEP deve conter 8 dígitos.')
    })
  })

  const handleRemoveEmail = React.useCallback(email => {
    setFieldValue('ownerEmails', formik.values.ownerEmails.filter(e => e !== email))
  }, [formik.values.ownerEmails, setFieldValue])

  const handleAddEmail = React.useCallback(() => {
    setFieldValue('ownerEmails', [...(formik.values.ownerEmails || []), formik.values.email])
    setFieldValue('email', '')
  }, [formik.values.email, formik.values.ownerEmails, setFieldValue])

  const validEmail = React.useMemo(() => {
    const email = formik.values.email
    const ownerEmails = formik.values.ownerEmails
    return email.length > 0 && !formik.errors.email && !ownerEmails?.find(e => e === email)
  }, [formik.errors.email, formik.values.email, formik.values.ownerEmails])

  const handleUpdateTenant = () => {
    updateTenant.trigger(formik.values)
  }

  const ableToSave = React.useMemo(() => {
    const { email, complement, ...values } = formik.values
    if (complement) {
      values.complement = complement
    }
    const { cognitoSettings, userPoolId, subscriptionHistory, idSubscription, ...tenantValues } = tenant
    const hasErrors = Object.keys(formik.errors).reduce((prev, key) => prev || formik.errors[key], false)
    return !deepEqual(tenantValues, values) && !hasErrors
  }, [tenant, formik.values, formik.errors])

  return (
    <section className='px-2 font-inter flex flex-col w-full'>
      <div>
        <div className='flex w-full justify-between'>
          <p className='title inline-flex'>Organização</p>
          <button className='btn-primary !w-20' disabled={!ableToSave || updateTenant.isWorking || getTenant.isWorking} onClick={handleUpdateTenant}>{updateTenant.isWorking ? <Loading size={5} /> : 'Salvar'}</button>
        </div>
        <p className='text-xs'>As alterações, para informações de cobrança, apenas farão efeito após a contratação de uma nova assinatura.</p>
        <div>
          <label className='label'>Nome</label>
          <input className='input' value={formik.values.name} onChange={({ target: { value } }) => setFieldValue('name', nameMask(value))} id='name' />
          <p className='text-red-700 text-xs'>{formik.errors.name}</p>
          <label className='label'>ID da organização</label>
          <input className='input' value={formik.values.tenantKey} disabled />
          <label className='label'>CNPJ</label>
          <input className='input' value={formik.values.identity} onChange={({ target: { value } }) => setFieldValue('identity', cnpjMask(value))} id='identity' />
          <p className='text-red-700 text-xs'>{formik.errors.identity}</p>
          <label className='label'>E-mail</label>
          <input className='input' value={formik.values.ownerEmail} onChange={({ target: { value } }) => setFieldValue('ownerEmail', emailMask(value))} id='ownerEmail' />
          <p className='text-red-700 text-xs'>{formik.errors.ownerEmail}</p>
          <label className='label'>E-mails de cobrança</label>
          {formik.values.ownerEmails?.length > 0
            ? (
              <div className='input max-h-48 flex overflow-auto flex-col'>
                {formik.values.ownerEmails.map((email, key) => (
                  <div className='h-14 border-b flex items-center justify-between' key={key}>
                    <div>
                      {email}
                    </div>
                    <div>
                      <ClearIcon onClick={() => { handleRemoveEmail(email) }} className='icon cursor-pointer hover:text-gray-900' />
                    </div>
                  </div>
                ))}
              </div>)
            : <p className='text-xs py-2'>Não há e-mails cadastrados.</p>}
          <div className='flex flex-row'>
            <input className='input' value={formik.values.email} onChange={({ target: { value } }) => setFieldValue('email', emailMask(value))} />
            <button className='btn-primary' onClick={handleAddEmail} disabled={!validEmail}>Adicionar</button>
          </div>
          <p className='text-red-700 text-xs'>{formik.errors.email}</p>
        </div>
        <AddressForm formik={formik} setFieldValue={setFieldValue} />
      </div>
    </section>
  )
}

export default Organization
