import React from 'react'
import { dashboardPatientSlice } from '../../../../reducers'
import ChatMessages from '../../../chat/ChatMessages'
import ChatForm from '../../../chat/ChatForm'
import { useSelector } from 'react-redux'

const PatientChat = () => {
  const patient = useSelector(dashboardPatientSlice.selectors.selectPatient)
  return <><ChatMessages patient={patient} /><ChatForm patient={patient} /></>
}
export default PatientChat
