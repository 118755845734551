import React from 'react'
import AdminLeftBar from '../../components/admin/left-bar'
import { PagesContext } from '../../contexts/pages'
import Users from '../../components/admin/users'
import ToolBar from '../../components/tool-bar'
import Patients from '../../components/admin/patients'
import Subscription from '../../components/admin/subscription'
import Organization from '../../components/admin/org'
import Documents from '../../components/admin/documents'

const Admin = () => {
  const { AdminPages, adminPage } = React.useContext(PagesContext)
  const conditionalRendering = {
    [AdminPages.ADMIN_USERS]: <Users />,
    [AdminPages.ADMIN_PATIENTS]: <Patients />,
    [AdminPages.ADMIN_SUBSCRIPTION]: <Subscription />,
    [AdminPages.ADMIN_ORG]: <Organization />,
    [AdminPages.ADMIN_DOCUMENTS]: <Documents />,
  }

  return (
    <section className='flex flex-col bg-primary-gray h-screen'>
      <ToolBar />
      <div className='flex'>
        <AdminLeftBar />
        <div className='pt-14 w-screen md:pl-40'>
          {conditionalRendering[adminPage ?? 0]}
        </div>
      </div>
    </section>
  )
}

export default Admin
