import React from 'react'
import cities from './cities.json'
import { cepMask } from '../../utils/mask'

const states = Array.from(new Set(cities.map(({ Estado }) => Estado))).sort((a, b) => a.localeCompare(b))

const AddressForm = ({ formik, setFieldValue }) => {
  const initialState = React.useMemo(() => {
    if (formik.values.city && formik.values.city.length) {
      return cities.find(({ Código }) => Código === parseInt(formik.values.city))?.Estado
    }
  }, [formik.values.city])
  const [state, setState] = React.useState(initialState || states[0])
  const filteredCities = React.useMemo(() => cities.filter(({ Estado }) => Estado === state), [state])

  React.useEffect(() => {
    if (!formik.initialValues.city) {
      setFieldValue('city', filteredCities[0].Código.toString())
    }
  }, [filteredCities, formik.initialValues.city, setFieldValue])
  return (
    <>
      <div>
        <label className='label' htmlFor='state'>Estado</label>
        <select className='input' value={state} onChange={({ target: { value } }) => setState(value)}>
          {states.map((name, key) => <option value={name} key={key}>{name}</option>)}
        </select>
      </div>
      <div>
        <label className='label' htmlFor='city'>Cidade</label>
        <select onBlur={formik.handleBlur} className='input' value={formik.values.city} onChange={formik.handleChange} id='city' name='city'>
          {filteredCities.map(({ Município: city, Código: code }) => <option value={code} key={code}>{city}</option>)}
        </select>
        <p className='text-red-700 text-xs'>{formik.errors.city}</p>
      </div>
      <div className='flex flex-row gap-2'>
        <div>
          <label className='label' htmlFor='district'>Bairro</label>
          <input onBlur={formik.handleBlur} className='input' type='text' value={formik.values.district} onChange={formik.handleChange} id='district' maxLength={20} />
          <p className='text-red-700 text-xs'>{formik.errors.district}</p>
        </div>
        <div>
          <label className='label' htmlFor='street'>Rua</label>
          <input onBlur={formik.handleBlur} className='input' value={formik.values.street} onChange={formik.handleChange} id='street' maxLength={20} />
          <p className='text-red-700 text-xs'>{formik.errors.street}</p>
        </div>
      </div>
      <div className='flex flex-row gap-2'>
        <div className='w-3/4'>
          <label className='label' htmlFor='complement'>Complemento</label>
          <input onBlur={formik.handleBlur} className='input' type='text' value={formik.values.complement} onChange={formik.handleChange} id='complement' maxLength={30} />
          <p className='text-red-700 text-xs'>{formik.errors.complement}</p>
        </div>
        <div className='w-1/4'>
          <label className='label' htmlFor='number'>Número</label>
          <input min={0} onBlur={formik.handleBlur} className='input' type='number' value={formik.values.number} onChange={formik.handleChange} id='number' />
          <p className='text-red-700 text-xs'>{formik.errors.number}</p>
        </div>
      </div>
      <div>
        <label className='label' htmlFor='zipcode'>CEP</label>
        <input onBlur={formik.handleBlur} className='input' type='text' value={formik.values.zipcode} onChange={({ target: { value } }) => setFieldValue('zipcode', cepMask(value))} id='zipcode' maxLength={9} />
        <p className='text-red-700 text-xs'>{formik.errors.zipcode}</p>
      </div>
    </>
  )
}
export default AddressForm
