import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminSlice, usersSlice } from '../../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import Loading from '../../../loading'
import SearchIcon from '@mui/icons-material/Search'
import { useParams } from 'react-router-dom'
import { PagesContext } from '../../../../contexts/pages'
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'

const ListUsers = () => {
  const { setUserPage, AdminUserPages } = React.useContext(PagesContext)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [search, setSearch] = React.useState('')
  const [isSearching, setIsSearching] = React.useState(false)
  const [searchBy, setSearchBy] = React.useState('name')
  const [role, setRole] = React.useState(null)
  const dispatcher = useDispatch()
  const { tenantKey } = useParams()
  const usersPerPage = 9
  const indexOfLastUser = currentPage * usersPerPage
  const indexOfFirstUser = indexOfLastUser - usersPerPage
  const users = useSelector(usersSlice.selectors.selectUsers)
  const usersFromSearch = useSelector(
    usersSlice.selectors.selectSearchUsers
  )
  const usersList = isSearching ? usersFromSearch : users
  const currentUsers = usersList.slice(
    indexOfFirstUser,
    indexOfLastUser
  )
  const startAt = { tenantKey, email: currentUsers[currentUsers.length - 1]?.email }
  const pages = Math.ceil(usersList.length / usersPerPage)
  const [alreadyOnLastPage, setAlreadyOnLastPage] = React.useState(false)
  const [clean, setClean] = React.useState(false)

  const paginate = pageNumber => setCurrentPage(pageNumber)

  const [getUsers, searchUsers] = useEvent([
    usersSlice.eventBus.getUsers,
    usersSlice.eventBus.searchUsers
  ])

  const isWorking = getUsers.isWorking || searchUsers.isWorking

  const requestUsers = () => {
    if (currentPage === pages) {
      if (isSearching) {
        searchForUsers()
      } else {
        getUsers.trigger({ limit: usersPerPage, startAt })
      }
    }
    paginate(currentPage + 1)
  }

  React.useEffect(() => {
    if (users.length === 0) {
      getUsers.trigger({ limit: usersPerPage })
    }
  }, [getUsers, users.length])

  const setUser = user => {
    dispatcher(adminSlice.actions.setUser(user))
  }

  React.useEffect(() => {
    if (
      currentUsers.length < usersPerPage &&
      currentUsers.length > 0
    ) {
      setAlreadyOnLastPage(true)
    }
  }, [currentUsers])

  const searchForUsers = () => {
    setIsSearching(true)
    if (clean) {
      dispatcher(usersSlice.actions.cleanSearchUsers()) // clean users from previous search
      searchUsers.trigger({ limit: usersPerPage, search, searchBy, role })
      setClean(false)
    } else {
      searchUsers.trigger({ limit: usersPerPage, search, searchBy, role, startAt })
    }
    setCurrentPage(1)
    setAlreadyOnLastPage(false)
  }

  const endSearching = () => {
    setSearch('')
    setRole(null)
    setIsSearching(false)
    dispatcher(usersSlice.actions.cleanSearchUsers())
    setCurrentPage(1)
    setAlreadyOnLastPage(false)
  }

  const isAbleToSearch = (search.length >= 3 || role) && !searchUsers.isWorking
  const toggleRole = newRole => {
    role === newRole ? setRole(null) : setRole(newRole)
    setClean(true)
  }

  React.useEffect(() => {
    setCurrentPage(1)
  }, [isSearching])

  return (
    <section className='px-2 flex flex-col justify-between min-h-[90vh]'>
      <div>
        <div className='flex justify-between'>
          <h1 className='title'>Usuários</h1>
          <button
            type='button'
            onClick={() => { setUserPage(AdminUserPages.ADMIN_USER_CREATE) }}
            className='btn-primary'
          >
            + <span>Novo Usuário</span>
          </button>
        </div>
        <div className='flex flex-col xl:flex-row mt-3'>
          <div className='flex md:flex-row flex-col'>
            <div className='flex items-center border-b flex-1'>
              <SearchIcon />
              <input
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                  setClean(true)
                }}
                type='search'
                name='user-name'
                id='user-name'
                placeholder='Buscar usuário'
                className='py-3 px-4 lg:w-auto flex-1 text-sm bg-transparent outline-none'
              />
            </div>
            <div className='flex flex-col px-2'>
              <label className='text-secondary-gray text-xs' htmlFor='searchBy'>
                Buscar usando
              </label>
              <label className='flex' id='searchBy'>
                <div
                  onClick={() => setSearchBy('name')}
                  className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'name'
                    ? 'bg-secondary-gray'
                    : 'bg-transparent'}`}
                >
                  Nome
                </div>
                <div
                  onClick={() => setSearchBy('email')}
                  className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'email'
                    ? 'bg-secondary-gray'
                    : 'bg-transparent'}`}
                >
                  Email
                </div>
              </label>
            </div>
          </div>
          <div className='flex flex-col px-2'>
            <label className='text-secondary-gray text-xs' htmlFor='searchBy'>
              Filtrar
            </label>
            <label className='flex' id='searchBy'>
              <div
                onClick={() => toggleRole('patient')}
                className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${role === 'patient'
                  ? 'bg-secondary-gray'
                  : 'bg-transparent'}`}
              >
                Paciente
              </div>
              <div
                onClick={() => toggleRole('family')}
                className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${role === 'family'
                  ? 'bg-secondary-gray'
                  : 'bg-transparent'}`}
              >
                Familiar
              </div>
              <div
                onClick={() => toggleRole('doctor')}
                className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${role === 'doctor'
                  ? 'bg-secondary-gray'
                  : 'bg-transparent'}`}
              >
                Profissional
              </div>
              <div
                onClick={() => toggleRole('admin')}
                className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${role === 'admin'
                  ? 'bg-secondary-gray'
                  : 'bg-transparent'}`}
              >
                Administrador
              </div>
              <div
                onClick={() => toggleRole('owner')}
                className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${role === 'owner'
                  ? 'bg-secondary-gray'
                  : 'bg-transparent'}`}
              >
                Proprietário
              </div>
            </label>
          </div>
          <button
            onClick={searchForUsers}
            className={`btn-primary ${!isAbleToSearch ? 'opacity-50' : ''}`}
            disabled={!isAbleToSearch}
          >
            Buscar
          </button>
          {isSearching && (
            <button onClick={endSearching} className='btn-secondary'>
              Limpar Busca
            </button>
          )}
        </div>
        {isWorking ? (
          <div className='w-full flex justify-center p-10'>
            <Loading />
          </div>
        ) : !currentUsers.length ? (
          <div className='w-full flex justify-center p-10'>
            Não foram encontrados usuários.
          </div>
        ) : (
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
            {currentUsers.map((user, index) => (
              <div
                key={index}
                className='flex flex-col border p-4 gap-6 lg:max-w-md rounded-md border-slate-300 shadow-xl cursor-pointer'
                onClick={() => {
                  setUser(user)
                }}
              >
                <div className='flex justify-between items-center w-full'>
                  <div className='truncate mr-1'>
                    <h1 className='font-semibold text-sm truncate'>
                      {user.name}
                    </h1>
                    <p className='text-[12px] text-secondary-gray'>{user.email}</p>
                  </div>
                  {user.role === 'family' ? <FamilyRestroomOutlinedIcon className='icon' />
                    : user.role === 'patient' ? <PersonOutlineIcon className='icon' />
                      : user.role === 'doctor' ? <Diversity1OutlinedIcon className='icon' />
                        : user.role === 'admin' ? <ManageAccountsOutlinedIcon className='icon' /> : <></>}
                </div>
                {user.role === 'family' && user?.patients.elgnth > 0 &&
                  <><p className='text-[12px]'>Responsável por: </p>
                    {user?.patients?.map(({ name, pk }) => <p className='text-[12px]' key={pk}>{name}</p>)}
                  </>}
                {user.role === 'doctor' &&
                  <div className='grid grid-cols-2 gap-2'>
                    <div className='flex flex-col'>
                      <h1 className='font-semibold text-sm'>Pacientes</h1>
                      {user?.patients?.length === 0 && <p className='text-[12px]'>Não há pacientes designados a este profissional.</p>}
                      <ul className='list-disc pt-1 pl-1'>
                        {user?.patients?.map((patient, index) => (
                          <li key={index} className='text-[12px] truncate'>
                            {patient.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>}
              </div>
            ))}
          </div>)}
      </div>
      <div className='pagination max-w-5xl mx-auto flex justify-center items-center'>
        {[...Array(pages).keys()].map(number => (
          <button
            className={`border p-2 rounded ${currentPage === number + 1
              ? 'bg-secondary-blue text-white'
              : 'bg-white text-secondary-blue'}`}
            key={number}
            onClick={() => paginate(number + 1)}
          >
            {number + 1}
          </button>
        ))}
        {!alreadyOnLastPage && usersList.length > 0 && !clean && (
          <button
            className='m-2 shadow bg-white p-2 rounded text-secondary-blue'
            onClick={requestUsers}
          >
            {currentPage === pages ? 'Carregar mais' : '>'}
          </button>
        )}
      </div>
    </section>
  )
}

export default ListUsers
