import * as actions from './slice'

export const getTenant = {
  eventName: 'getTenant',
  onSuccess: {
    action: actions.setTenant,
    redux: true
  }
}

export const updateTenant = {
  eventName: 'updateTenant'
}
