import React from 'react'
import SinglePatient from './single-patient'
import { useDispatch, useSelector } from 'react-redux'
import { componentSearchPatientsSlice, dashboardPatientSlice, patientSlice } from '../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import Loading from '../../loading'
import { useParams } from 'react-router-dom'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { SearchPatients } from '../../search-patients'
import { applyCpfString } from '../../../utils/mask'

const patientsPerPage = 9
const Patients = () => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const dispatcher = useDispatch()
  const { tenantKey } = useParams()
  const patient = useSelector(dashboardPatientSlice.selectors.selectPatient)
  const indexOfLastPatient = currentPage * patientsPerPage
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage
  const patients = useSelector(patientSlice.selectors.selectPatients)
  const patientsFromSearch = useSelector(
    patientSlice.selectors.selectSearchPatients
  )
  const isSearching = useSelector(componentSearchPatientsSlice.selectors.selectIsSearching)
  const patientsList = React.useMemo(() => isSearching ? patientsFromSearch : patients, [isSearching, patients, patientsFromSearch])
  const currentPatients = patientsList.slice(
    indexOfFirstPatient,
    indexOfLastPatient
  )
  const pages = Math.ceil(patientsList.length / patientsPerPage)
  const startAt = React.useMemo(() => ({ tenantKey, doc: currentPatients[currentPatients.length - 1]?.doc }), [currentPatients, tenantKey])
  const [alreadyOnLastPage, setAlreadyOnLastPage] = React.useState(false)

  const paginate = pageNumber => setCurrentPage(pageNumber)

  const [getPatients] = useEvent([
    patientSlice.eventBus.getPatients
  ])
  const isSearchWorking = useSelector(componentSearchPatientsSlice.selectors.selectIsWorking)
  const isWorking = getPatients.isWorking || isSearchWorking

  const requestPatients = () => {
    if (currentPage === pages) {
      getPatients.trigger({ limit: patientsPerPage, startAt })
    }
    paginate(currentPage + 1)
  }

  React.useEffect(() => {
    if (patients.length === 0) {
      getPatients.trigger({ limit: patientsPerPage })
    }
  }, [getPatients, patients.length])

  const setSelectedPatient = patient => {
    dispatcher(dashboardPatientSlice.actions.setPatient(patient))
  }

  const isSinglePatientSelected = patient?.pk

  React.useEffect(() => {
    if (
      currentPatients.length < patientsPerPage &&
      currentPatients.length > 0
    ) {
      setAlreadyOnLastPage(true)
    }
  }, [currentPatients])

  React.useEffect(() => {
    setCurrentPage(1)
  }, [isSearching])

  return (
    <section className='h-[94vh]'>
      <div className='p-2 flex flex-col justify-between h-full'>
        <div>
          {!isSinglePatientSelected ? (
            <>
              <h1 className='flex title items-center'>Pacientes</h1>
              <SearchPatients />
              {isWorking ? (
                <div className='w-full flex justify-center p-10'>
                  <Loading />
                </div>
              ) : !currentPatients.length ? (
                <div className='w-full flex justify-center p-10'>
                  Não foram encontrados pacientes.
                </div>
              ) : (
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
                  {currentPatients.map((patient, index) => (
                    <div
                      key={index}
                      className='flex flex-col border p-4 lg:max-w-md rounded-md border-slate-300 shadow-xl cursor-pointer'
                      onClick={() => {
                        setSelectedPatient(patient)
                      }}
                    >
                      <div className='flex justify-between items-center'>
                        <div className='mr-1 truncate'>
                          <h1 className='font-semibold text-sm truncate'>
                            {patient.name}
                          </h1>
                          <p className='text-[12px] text-secondary-gray'>{applyCpfString(patient.doc)}</p>
                        </div>
                        <PersonOutlineIcon className='icon' />
                      </div>
                      <div className='grid grid-cols-2 gap-2'>
                        <div className='flex flex-col'>
                          <h1 className='font-semibold text-sm'>Profissionais</h1>
                          {patient?.doctors.length === 0 && <p className='pl-1 pt-1 text-[12px]'>Não há profissionais para este paciente.</p>}
                          <ul className='list-disc pt-1 pl-1'>
                            {patient?.doctors?.map((doctor, index) => (
                              <li key={index} className='text-[12px] truncate'>
                                {doctor.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className='flex flex-col'>
                          <h1 className='font-semibold text-sm'>Familiares</h1>
                          {patient?.family.length === 0 && <p className='pl-1 pt-1 text-[12px]'>Não há familiares para este paciente.</p>}
                          <ul className='pt-1 pl-1'>
                            {patient?.family?.map((relative, index) => (
                              <li key={index} className='text-[12px] truncate'>
                                {relative.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <SinglePatient />
          )}
        </div>
        <div className='align-bottom'>
          {!isSinglePatientSelected && (
            <div className='pagination max-w-5xl mx-auto flex justify-center items-center'>
              {[...Array(pages).keys()].map(number => (
                <button
                  className={`border p-2 rounded ${currentPage === number + 1
                    ? 'bg-secondary-blue text-white'
                    : 'bg-white text-secondary-blue'}`}
                  key={number}
                  onClick={() => paginate(number + 1)}
                >
                  {number + 1}
                </button>
              ))}
              {!alreadyOnLastPage && patientsList.length > 0 && (
                <button
                  className='m-2 shadow bg-white p-2 rounded text-secondary-blue'
                  onClick={requestPatients}
                >
                  {currentPage === pages ? 'Carregar mais' : '>'}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Patients
