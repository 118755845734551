import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import React from 'react'
import { useSelector } from 'react-redux'
import { dashboardAttendanceSlice } from '../../../reducers'
import { useMediaQuery } from '@material-ui/core'
import Loading from '../../loading'
import timeDiff from '../../../utils/timeDiff'

const Comments = () => {
  const attendance = useSelector(dashboardAttendanceSlice.selectors.selectAttendance)
  const comments = useSelector(dashboardAttendanceSlice.selectors.selectAttendanceComments)
  const [comment, setComment] = React.useState('')
  const [getAttendanceComments, addComment, newAttendanceComment] = useEvent([dashboardAttendanceSlice.eventBus.getAttendanceComments, dashboardAttendanceSlice.eventBus.addAttendanceComment, dashboardAttendanceSlice.eventBus.newAttendanceComment])
  React.useEffect(() => {
    if (attendance?.start) {
      getAttendanceComments.trigger(attendance)
    }
  }, [attendance.start])
  const add = React.useCallback(() => {
    addComment.trigger({ ...attendance, comment })
    setComment('')
  }, [attendance, comment])

  useEventsOnViewLoad(() => {
    if (attendance.start && attendance.patient) {
      newAttendanceComment.subscribeToEvent({ id: `${attendance?.patient}#${attendance?.start}` })
    }
  }, [attendance.start, attendance.patient])

  const isTabletOrMobile = useMediaQuery('(max-width:600px)')

  if (isTabletOrMobile) {
    return (
      <div className='h-80 bg-white flex flex-col p-2'>
        <div className='h-40 p-2'>
          <textarea disabled={addComment.isWorking || getAttendanceComments.isWorking} className='flex input h-16' value={comment} onChange={({ target: { value } }) => setComment(value)} />
          <button className='btn-primary' onClick={add} disabled={addComment.isWorking || !comment.length}>Adicionar Comentário</button>
        </div>
        <label className='label px-2'>Comentários</label>
        {!comments?.length && !getAttendanceComments.isWorking && <p className='text-xs px-2'>Não há comentários</p>}
        <div className='px-2 h-full overflow-auto'>
          <div className='flex flex-col overflow-y-auto'>
            {getAttendanceComments.isWorking ? <div className='flex justify-center h-10'><Loading size={5} /></div> :
              comments?.map(({ comment, moment, commenter, slice }) => (
                <div key={moment} className='border-b'>
                  <div className='flex flex-row items-center gap-1'>
                    <p className='font-semibold text-sm'>{commenter?.name}</p>
                    <p className='text-gray-700 text-xs'>{timeDiff(slice, moment)}</p>
                  </div>
                  <p className='text-sm overflow-auto flex-wrap'>{comment}</p>
                </div>))
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='w-80 bg-white h-full flex flex-col'>
      <div className='h-40 p-2'>
        <textarea className='flex input h-20' value={comment} onChange={({ target: { value } }) => setComment(value)} />
        <button className='btn-primary' onClick={add} disabled={addComment.isWorking || !comment.length}>Adicionar Comentário</button>
      </div>
      <label className='label px-2'>Comentários</label>
      {!comments?.length && !getAttendanceComments.isWorking && <p className='text-xs px-2'>Não há comentários</p>}
      <div className='px-2 h-full overflow-auto'>
        <div className='flex flex-col overflow-y-auto'>
          {getAttendanceComments.isWorking ? <div className='flex justify-center h-10'><Loading size={5} /></div> :
            comments?.map(({ comment, moment, commenter, slice }) => (
              <div key={moment} className='border-b'>
                <div className='flex flex-row items-center gap-1'>
                  <p className='font-semibold text-sm'>{commenter?.name}</p>
                  <p className='text-gray-700 text-xs'>{timeDiff(slice, moment)}</p>
                </div>
                <p className='text-sm overflow-auto flex-wrap'>{comment}</p>
              </div>))
          }
        </div>
      </div>
    </div>
  )
}
export default Comments