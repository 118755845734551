import React from 'react'

const Start = () => {
  return (
    <div className='grid grid-cols-3 w-full h-5/6 p-6'>
      <div className='flex flex-col items-start mb-6'>
        <h1 className='font-bold'>Meus Pacientes</h1>
        <button className='text-secondary-gray'>Listar</button>
        <button className='text-secondary-gray'>+ Cadastrar</button>
      </div>
      <div className='flex flex-col items-start mb-6'>
        <h1 className='font-bold'>Agenda</h1>
        <button className='text-secondary-gray'>Ver</button>
        <button className='text-secondary-gray'>+ Criar atendimento</button>
      </div>
      <div className='flex flex-col items-start mb-6'>
        <h1 className='font-bold'>Transmissão</h1>
        <button className='text-secondary-gray'>Ver</button>
        <button className='text-secondary-gray'>+ Criar transmissão</button>
      </div>
      <div className='flex flex-col items-start'>
        <h1 className='font-bold'>Protocolos/Modelos</h1>
        <button className='text-secondary-gray'>Listar</button>
        <button className='text-secondary-gray'>+ Cadastrar</button>
      </div>
      <div className='flex flex-col items-start'>
        <h1 className='font-bold'>Equipe</h1>
        <button className='text-secondary-gray'>Listar</button>
        <button className='text-secondary-gray'>+ Cadastrar</button>
      </div>
      <div className='flex flex-col items-start'>
        <h1 className='font-bold'>Administrativo</h1>
        <button className='text-secondary-gray'>Relatórios</button>
      </div>
    </div>
  )
}

export default Start
