export const applyCpfString = (cpf) =>
  cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')

export const fixCpfString = (cpf) => cpf.replace(/[^\d]/g, '')

export const nameMask = (value) => {
  if (!value) return ''
  if (value.length >= 15) {
    return value.slice(0, 15)
  }
  value = value.replace(/[^\w\s\d]/g, '')
  return value
}

export const tenantMask = (value) => {
  if (!value) return ''
  if (value.length >= 10) {
    return value.slice(0, 10)
  }
  value = value.replace(/[^a-z\d]/g, '')
  return value
}

export const cnpjMask = (value) => {
  if (!value) return ''
  if (value.length >= 18) {
    return value.slice(0, 18)
  }
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  if (value.length >= 18) {
    return value.slice(0, 18)
  }
  return value
}

export const emailMask = (value) => value.replace(/[^a-zA-Z0-9+_/-@.]/g, '')

export const cepMask = (value) =>
  value
    .replace(/\D/g, '')
    .slice(0, 8)
    .replace(/^(\d{0,5})(\d{0,4})$/, '$1-$2')

export const cvvMask = (value) => value.replace(/\D/g, '').slice(0, 3)

export const ccMask = (value) =>
  value
    .replace(/\D/g, '')
    .slice(0, 16)
    .replace(/(\d{4})(\d{1,4})/g, '$1 $2')
    .replace(/(\d{4})(\d{4})/g, '$1 $2')

export const expirationMask = (value) =>
  value
    .replace(/\D/g, '')
    .slice(0, 6)
    .replace(/^(\d{0,2})(\d{0,4})$/, '$1/$2')
