import React from 'react'

import ChatRoom from '../../chat/ChatRoom'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardChatSlice, coreSlice, chatSlice, patientSlice } from '../../../reducers'
import Patients from './patients'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function Chat() {
  const patients = useSelector(patientSlice.selectors.selectPatients)
  const chatMembers = useSelector(chatSlice.selectors.selectChatMembers)
  const dispatcher = useDispatch()
  const user = useSelector(coreSlice.selectors.selectUser)
  const isPatientOrFamily = React.useMemo(() => user?.role === 'patient' || user?.role === 'family', [user])
  const patient = useSelector((isPatientOrFamily ? coreSlice : dashboardChatSlice).selectors.selectPatient)

  const setPatient = patient =>
    dispatcher(dashboardChatSlice.actions.setPatient(patient))

  const setChatMembers = chatMembers => {
    dispatcher(chatSlice.actions.setChatMembers(chatMembers))
  }
  const resetChatSelection = () => {
    setPatient(null)
    setChatMembers([])
  }

  const isDoctor = user?.role === 'doctor'

  return (
    <section>
      {isDoctor && patient && patients.length > 0 && (
        <div className='z-10 bg-white hover:bg-gray-100 cursor-pointer h-10 w-full border-b' onClick={resetChatSelection}>
          <div
            className='flex items-center h-full'
          >
            <ArrowBackIcon />
            <div className='pl-2 text-base'>Ver Pacientes</div>
          </div>
        </div>
      )}
      <div className='rounded md:grid md:grid-cols-2 h-[90vh] w-full overflow-y-hidden'>
        {patient && chatMembers.length > 0 ? (
          <>
            <Patients />
            <ChatRoom />
          </>
        ) : (
          <Patients />
        )}
      </div>
    </section>
  )
}
