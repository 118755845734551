import React from 'react'
import ChatForm from './ChatForm'
import { useSelector } from 'react-redux'
import ChatMessages from './ChatMessages'
import { coreSlice, dashboardChatSlice } from '../../reducers'
import Patients from '../dashboard/chat/patients'
import CloseIcon from '@mui/icons-material/Close'

const ChatRoom = () => {
  const user = useSelector(coreSlice.selectors.selectUser)
  const isPatientOrFamily = React.useMemo(() => user?.role === 'patient' || user?.role === 'family', [user])
  const patient = useSelector((isPatientOrFamily ? coreSlice : dashboardChatSlice).selectors.selectPatient)
  const [showMembers, setShowMembers] = React.useState(false)
  return (
    <div className='flex flex-col h-[90vh]'>
      <div className='relative px-2 bg-white shadow-sm h-16 w-full'>
        <div className='flex flex-col h-full justify-center'>
          <div className='font-semibold'>Paciente: {patient?.name}</div>
          <div className='md:hidden text-sm cursor-pointer' onClick={() => setShowMembers(!showMembers)}>Ver participantes</div>
        </div>
      </div>
      {
        showMembers
          ? <MobileMembers setShowMembers={setShowMembers} />
          : <> <ChatMessages patient={patient} /> <ChatForm patient={patient} /></>
      }
    </div>
  )
}

const MobileMembers = ({ setShowMembers }) => <div className='bg-white h-full w-full'><div className='w-full flex justify-end'><CloseIcon className='icon' onClick={() => setShowMembers(false)} /></div><Patients showOnMobile /></div>

export default ChatRoom
