import React from 'react'
import Api from '../../services/api'
import Loading from '../../components/loading'

const Plans = ({ plan: selelectedPlan, setPlan }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [plans, setPlans] = React.useState([])
  React.useEffect(()=>{
    const getPlans = async () => {
      setIsLoading(true)
      const { data: plans } = await new Api().get('plans')
      setPlans(plans)
      if (plans?.length) {
        setPlan(plans.length === 1 ? plans[0]?.idPlan : plans[1].idPlan)
      }
      setIsLoading(false)
    }
    getPlans()
  }, [])
  if (isLoading) {
    return <div className='w-full flex justify-center h-32 items-center'><Loading /></div>
  }
  return (
    <div className='flex flex-row gap-2'>
      {plans?.map((p, key) => {
        const isCurrentPlan = selelectedPlan === p.idPlan
        return (
          <div onClick={() => setPlan(p.idPlan)} key={key} className={`${isCurrentPlan ? 'bg-gray-100 border-black' : 'bg-white'} border rounded-md shadow-lg h-40 w-52 flex flex-col items-center my-4 md:mx-0 mx-4 hover:bg-gray-100 cursor-pointer`}>
            <div className='p-4'>
              <div className='w-28'>
                <h1 className='font-semibold'>{p.name}</h1>
                <div className='flex flex-col items-center'>
                  <p>Por apenas</p>
                  <p className='font-medium'>R${p.amount}</p>
                  <p>por mês</p>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Plans
