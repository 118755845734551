import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminSlice, componentsSelectDoctorSlice } from '../../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import ClearIcon from '@mui/icons-material/Clear'
import SelectDoctor from '../../../select-doctor'
import ConfirmModal from '../../../confirm-modal'
import Loading from '../../../loading'

const ListDoctors = () => {
  const dispatcher = useDispatch()
  const patientDoctors = useSelector(componentsSelectDoctorSlice.selectors.selectPatientDoctors)
  const doctor = useSelector(componentsSelectDoctorSlice.selectors.selectDoctor)
  const adminPatient = useSelector(adminSlice.selectors.selectPatient)
  const [getPatientDoctors] = useEvent([componentsSelectDoctorSlice.eventBus.getPatientDoctors])
  const [showAddModal, setShowAddModal] = React.useState(false)
  const [showRemoveodal, setShowRemoveModal] = React.useState(false)
  const [doctorToRemove, setDoctorToRemove] = React.useState(null)

  const fetchPatientDoctors = React.useCallback(() => {
    getPatientDoctors.trigger({ patient: adminPatient?.doc })
  }, [getPatientDoctors, adminPatient])

  const [setIsSupervisor] = useEvent([{ ...componentsSelectDoctorSlice.eventBus.setIsSupervisor, onSuccess: fetchPatientDoctors }])

  const [addDoctorToGroup, removeDoctorFromGroup] = useEvent([{ ...componentsSelectDoctorSlice.eventBus.addDoctorToGroup, onSuccess: fetchPatientDoctors }, { ...componentsSelectDoctorSlice.eventBus.removeDoctorFromGroup, onSuccess: fetchPatientDoctors }])

  React.useEffect(() => {
    fetchPatientDoctors()
  }, [fetchPatientDoctors])

  const addDoctor = () => {
    addDoctorToGroup.trigger({ professional: doctor?.pk, patient: adminPatient?.doc })
    dispatcher(componentsSelectDoctorSlice.actions.setDoctor(null))
    setShowAddModal(false)
  }

  const removeDoctor = () => {
    removeDoctorFromGroup.trigger({ professional: doctorToRemove?.pk, patient: adminPatient?.doc })
    setDoctorToRemove(null)
    setShowRemoveModal(false)
  }

  const handleRemoveDoctorClick = doctor => {
    setDoctorToRemove(doctor)
    setShowRemoveModal(true)
  }

  const handleSetIsSupervisor = React.useCallback((doctorInfo) => {
    const { isSupervisor } = doctorInfo
    setIsSupervisor.trigger({ ...doctorInfo, isSupervisor: !isSupervisor })
  }, [patientDoctors])

  const state = React.useMemo(() => {
    if (
      setIsSupervisor.isWorking ||
      getPatientDoctors.isWorking) {
      return 'loading'
    }
    if (!patientDoctors.length) {
      return 'empty'
    }
    return 'done'

  }, [setIsSupervisor.isWorking, getPatientDoctors.isWorking, patientDoctors])

  return (
    <>
      <ConfirmModal content={`Adicionar ${doctor?.name} - ${doctor?.email} como profissional do paciente ${adminPatient?.name} - ${adminPatient?.doc}?`} open={showAddModal} onCancel={() => setShowAddModal(false)} onConfirm={addDoctor} />
      <ConfirmModal content={`Remover ${doctorToRemove?.name} - ${doctorToRemove?.email} da lista de profissionais do paciente ${adminPatient?.name} - ${adminPatient?.doc}?`} open={showRemoveodal} onCancel={() => setShowRemoveModal(false)} onConfirm={removeDoctor} />
      <label className='label'>Profissionais</label>
      {state === 'empty' && <>Não existem profisionais designados para este paciente.</>}
      {state === 'loading' && <div className='flex justify-center'><Loading size={10} /></div>}
      {state === 'done' &&
        <div className='input max-h-48 flex overflow-auto flex-col'>
          <table className='w-full'>
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Supervisor</th>
                <th>Remover</th>
              </tr>
            </thead>
            <tbody>
              {patientDoctors.length > 0 &&
                patientDoctors.map((doctorInfo, key) => (
                  <tr key={key}>
                    <td className='text-center'>
                      {doctorInfo?.name}
                    </td>
                    <td className='text-center'>
                      {doctorInfo?.email}
                    </td>
                    <td>
                      <div className='flex justify-center'>
                        <input type='checkbox' className='sr-only peer' checked={doctorInfo.isSupervisor} readOnly />
                        <div onClick={() => { handleSetIsSupervisor(doctorInfo) }} className='relative w-11 h-6 bg-secondary-gray rounded-full peer peer-focus:ring-4 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[""] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-primary-gray after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-blue cursor-pointer' />
                      </div>
                    </td>
                    <td className='text-center'>
                      <ClearIcon onClick={() => { handleRemoveDoctorClick(doctorInfo) }} className='icon cursor-pointer hover:text-gray-900' />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div >}
      <label className='label'>Adicionar Profissional</label>
      <div className='flex flex-col md:flex-row items-center justify-start'>
        <SelectDoctor />
        <button onClick={() => setShowAddModal(true)} className={doctor?.pk ? 'btn-primary' : 'btn-disabled'} disabled={!doctor?.pk}>Adicionar</button>
      </div>
    </>
  )
}

export default ListDoctors
