import React from 'react'

const ConfirmModal = ({ open, content, title, onConfirm, onCancel }) => {
  return (
    <>
      {open
        ? (
          <>
            <div
              className='modal'
            >
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                <div className='modal-content'>
                  {title &&
                    <div className='flex justify-between p-5 border-b border-solid rounded-t flex-row items-center'>
                      <h3 className='title'>
                        {title}
                      </h3>
                      <button
                        className='flex content-center  justify-end ml-auto text-black opacity-50 text-3xl font-semibold'
                        onClick={onCancel}
                      >
                        <span className='flex items-center justify-end text-black opacity-50 h-6 w-6 text-2xl outline-none focus:outline-none'>
                          ×
                        </span>
                      </button>
                    </div>}
                  {content &&
                    <div className='relative p-6 flex-auto'>
                      <p className='my-4 text-lg leading-relaxed'>
                        {content}
                      </p>
                    </div>}
                  <div className='flex items-center justify-end rounded-b'>
                    <button
                      className='btn-secondary'
                      type='button'
                      onClick={onCancel}
                    >
                      Cancelar
                    </button>
                    <button
                      className='btn-primary'
                      type='button'
                      onClick={onConfirm}
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black' />
          </>)
        : null}
    </>
  )
}

export default ConfirmModal
