import * as actions from './slice'

export const getFamilyUsers = {
  eventName: 'getUsers',
  onSuccess: {
    action: actions.setFamilyUsers,
    redux: true
  }
}

export const addToFamily = {
  eventName: 'addToFamily'
}

export const removeFromFamily = {
  eventName: 'removeFromFamily'
}

export const getPatientFamily = {
  eventName: 'getPatientFamily',
  onSuccess: {
    action: actions.setPatientFamily,
    redux: true
  }
}
