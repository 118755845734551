import name from './name'

const selectSliceData = (state) => state[name]

const selectSearchPatients = (state) => selectSliceData(state).searchPatients
const selectPatient = (state) => selectSliceData(state).patient
const selectAgenda = (state) => selectSliceData(state).agenda
const selectEvent = (state) => selectSliceData(state).event
const selectMeetings = (state) => selectSliceData(state).meetings
const selectMeeting = (state) => selectSliceData(state).meeting
const selectMeetingParticipants = (state) =>
  selectSliceData(state).meetingParticipants

export {
  selectSearchPatients,
  selectPatient,
  selectAgenda,
  selectEvent,
  selectMeetings,
  selectMeeting,
  selectMeetingParticipants
}
