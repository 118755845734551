import React, { useEffect, useState } from 'react'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useConnectionContext } from '@emerald-works/react-event-bus-client'

export default function Logger () {
  const [isOpen, setIsOpen] = useState(false)
  const [messageText, setMessageText] = useState('Disconnected')
  const [severity, setSeverity] = useState('error')
  const context = useConnectionContext()
  const isConnected = context?.isConnected
  useEffect(() => {
    const currentMessageText = isConnected ? 'Connected' : 'Disconnected'
    setSeverity(isConnected ? 'success' : 'error')
    setMessageText(currentMessageText)
    // if (currentMessageText !== messageText) {
    //   setIsOpen(true)
    // }
    console.log('WEBSOCKET STATUS: ' + currentMessageText)
  }, [isConnected, messageText])
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Alert
        elevation={6}
        onClose={() => setIsOpen(false)}
        variant='filled'
        severity={severity}
      >
        <span>{messageText}</span>
      </Alert>
    </Snackbar>
  )
}
