import { toast } from 'react-toastify'
import * as actions from './slice'

export const getInProgressAttendance = {
  eventName: 'getInProgressAttendance',
  onSuccess: {
    action: actions.setInProgressAttendance,
    redux: true
  }
}

export const createAttendance = {
  eventName: 'createAttendance',
  onSuccess: {
    action: actions.setAttendance,
    redux: true
  }
}

export const updateAttendance = {
  eventName: 'updateAttendance'
}

export const finishAttendance = {
  eventName: 'finishAttendance'
}

export const getAttendances = {
  eventName: 'getAttendances',
  onSuccess: {
    action: actions.setAttendances,
    redux: true
  }
}

export const exportAttendance = {
  eventName: 'exportAttendance',
  onSuccess: {
    action: actions.setAttendance,
    redux: true
  }
}

export const recordAttendance = {
  eventName: 'recordAttendance'
}

export const attendanceRecordingStatus = {
  eventName: 'attendanceRecordingStatus'
}

export const getAllAttendances = {
  eventName: 'getAllAttendances',
  onSuccess: {
    action: actions.setAllAttendances,
    redux: true
  }
}

export const getAttendanceRecordings = {
  eventName: 'getAttendanceRecordings',
  onSuccess: {
    action: actions.setAttendanceRecordings,
    redux: true
  }
}

export const getDoctorInProgressAttendance = {
  eventName: 'getDoctorInProgressAttendance',
  onSuccess: {
    action: actions.setDoctorInProgressAttendance,
    redux: true
  }
}

export const generateAttendanceRtmToken = {
  eventName: 'generateAttendanceRtmToken',
  onSuccess: {
    action: actions.setRtmToken,
    redux: true
  }
}

export const getAttendanceComments = {
  eventName: 'getAttendanceComments',
  onSuccess: {
    action: actions.setAttendanceComments,
    redux: true
  }
}

export const addAttendanceComment = {
  eventName: 'addAttendanceComment'
}

export const newAttendanceComment = {
  eventName: 'newAttendanceComment',
  onSuccess: {
    action: actions.addNewAttendanceComment,
    redux: true
  }
}

export const addAttendanceRecordingComment = {
  eventName: 'addAttendanceRecordingComment',
  onSuccess: {
    action: actions.addAttendanceRecordingComment,
    redux: true
  },
  onError: () => {
    toast.error('Já existe um comentário neste momento do vídeo.')
  }
}
