import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../../utils'

const initialState = {
  patients: [],
  patient: null,
  searchPatients: []
}

export const componentsSelectPatientSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setPatient: (state, { payload }) => {
      state.patient = payload
    },
    setSearchPatients: (state, { payload }) => {
      state.searchPatients = payload
    }
  }
})

export const { setPatient, setSearchPatients, reset } =
  componentsSelectPatientSlice.actions

export default componentsSelectPatientSlice.reducer
