import { useEvent } from '@emerald-works/react-event-bus-client'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import { componentSearchPatientsSlice, patientSlice } from '../../reducers'
import { applyCpfString, fixCpfString } from '../../utils/mask'

export const SearchPatients = ({ patientsPerPage }) => {
  const [search, setSearch] = React.useState('')
  const [searchBy, setSearchBy] = React.useState('name')
  const isSearching = useSelector(componentSearchPatientsSlice.selectors.selectIsSearching)
  const [clear, setClear] = React.useState(false)
  const { tenantKey } = useParams()
  const [searchPatients] = useEvent([
    patientSlice.eventBus.searchPatients
  ])
  const patients = useSelector(patientSlice.selectors.selectPatients)
  const startAt = React.useMemo(() => ({ tenantKey, doc: patients[patients.length - 1]?.doc }), [patients, tenantKey])
  const dispatcher = useDispatch()
  const setIsSearching = isSearching => {
    dispatcher(componentSearchPatientsSlice.actions.setIsSearching(isSearching))
  }
  const searchForPatients = () => {
    setIsSearching(true)
    if (clear) {
      dispatcher(patientSlice.actions.clearSearchPatients()) // clear patients from previous search
      searchPatients.trigger({ limit: patientsPerPage, search, searchBy })
      setClear(false)
    } else {
      searchPatients.trigger({ limit: patientsPerPage, search, searchBy, startAt })
    }
  }
  const endSearching = () => {
    setSearch('')
    setIsSearching(false)
    dispatcher(patientSlice.actions.clearSearchPatients())
  }

  React.useEffect(() => {
    dispatcher(componentSearchPatientsSlice.actions.setIsWorking(searchPatients.isWorking))
  }, [dispatcher, searchPatients.isWorking])

  const isAbleToSearch = search.length >= 3 && !searchPatients.isWorking
  return (
    <div className='flex flex-row items-center gap-4 mt-3 flex-wrap'>
      <div className='flex flex-row w-full md:w-auto'>
        <div className='flex items-center border-b px-2'>
          <SearchIcon />
          <input
            value={searchBy === 'doc' ? applyCpfString(search) : search}
            onChange={e => {
              setSearch(searchBy === 'doc' ? fixCpfString(e.target.value) : e.target.value)
              setClear(true)
            }}
            type='search'
            name='patient-name'
            id='patient-name'
            placeholder='Buscar paciente'
            className='py-3 px-4 md:w-auto w-full flex-1 text-sm bg-transparent outline-none'
          />
        </div>
        <div className='flex flex-col px-2'>
          <label className='text-secondary-gray text-xs' htmlFor='searchBy'>
            Buscar usando
          </label>
          <label className='flex' id='searchBy'>
            <div
              onClick={() => setSearchBy('name')}
              className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'name'
                ? 'bg-secondary-gray'
                : 'bg-transparent'}`}
            >
              Nome
            </div>
            <div
              onClick={() => setSearchBy('doc')}
              className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'doc'
                ? 'bg-secondary-gray'
                : 'bg-transparent'}`}
            >
              Documento
            </div>
          </label>
        </div>
      </div>
      <button
        onClick={searchForPatients}
        className={`btn-primary w-full  ${!isAbleToSearch ? 'opacity-50' : ''}`}
        disabled={!isAbleToSearch}
      >
        Buscar
      </button>
      {isSearching && (
        <button onClick={endSearching} className='btn-secondary w-full text-nowrap'>
          Limpar Busca
        </button>
      )}
    </div>
  )
}
