import React from 'react'
import LogoImage from '../../assets/img/main-logo.png'

const LoadingPage = () => {
  return (
    <div className='w-full h-full flex items-center flex-col pt-[50vh]'>
      <div>
        <img
          width={148}
          height={38}
          src={LogoImage}
          alt='Logo Vinculo Integral'
        />
        <div className='w-full'>
          <div className='h-1 w-full bg-pink-100 overflow-hidden'>
            <div className='progress w-full h-full bg-pink-500 left-right' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingPage
