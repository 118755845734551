import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Logo from '../../assets/img/main-logo.png'
import SignIn from '../../components/signin-page/signin'
import Register from '../../components/signin-page/register'
import ConfirmOTP from '../../components/signin-page/confirm-otp'
import { useSelector } from 'react-redux'
import { coreSlice } from '../../reducers'
import Recover from '../../components/signin-page/recover'
import ConfirmOTPResetPassword from '../../components/signin-page/confirm-otp-reset'

const SignInPage = ({ ...a }) => {
  const authUser = useSelector(coreSlice.selectors.selectAuthUser)
  const navigate = useNavigate()
  const { state } = useLocation()
  const { tenantKey } = useParams()
  React.useEffect(() => {
    if (authUser) {
      if (state?.redirect) {
        navigate(state?.redirect)
      } else {
        navigate(`/${tenantKey}/dashboard`)
      }
    }
  }, [authUser, navigate, tenantKey])

  const [registerData, setRegisterData] = useState({
    email: '',
    password: ''
  })

  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (currentStep !== 0 && currentStep !== 3) {
        const message =
          'Você tem alterações não salvas. Tem certeza de que deseja sair?'
        event.returnValue = message
        return message
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [currentStep])

  const conditionalRendering = {
    0: <SignIn setCurrentStep={setCurrentStep} setRegisterData={setRegisterData} />,
    1: (
      <Register
        setCurrentStep={setCurrentStep}
        setRegisterData={setRegisterData}
      />
    ),
    2: <ConfirmOTP registerData={registerData} />,
    3: <Recover setRegisterData={setRegisterData} setCurrentStep={setCurrentStep} />,
    4: <ConfirmOTPResetPassword registerData={registerData} />
  }

  return (
    <section className='py-6 bg-primary-gray min-h-screen flex justify-center items-center'>
      <div className='max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center justify-between'>
        <div className='flex flex-col gap-6 max-w-2xl mx-auto p-8 justify-between'>
          <img src={Logo} alt='Logo Vinculo Integral' width={273} height={65} />
          <h1 className='text-4xl sm:text-6xl font-bold'>
            Qualidade total: <br /> do atendimento ao acompanhamento
          </h1>
          <p className='text-lg sm:text-xl text-secondary-gray font-normal'>
            Plataforma completa para atendimento padrão Clifali. Contempla desde
            o atendimento à família até o gerenciamento de horas trabalhadas em
            atendimento por parte da equipe.
          </p>
          <a
            href='/'
            className='flex items-center gap-1 text-primary-blue text-lg'
          >
            Saiba mais sobre a nossa aplicação{' '}
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='21'
                viewBox='0 0 20 21'
                fill='none'
              >
                <path
                  d='M17.4184 10.9191C17.4996 10.7064 17.5208 10.4724 17.4794 10.2466C17.438 10.0208 17.3359 9.81342 17.1859 9.65069L12.8999 4.99593C12.8011 4.88478 12.6829 4.79613 12.5521 4.73514C12.4214 4.67415 12.2808 4.64205 12.1385 4.64071C11.9963 4.63937 11.8552 4.66881 11.7235 4.72732C11.5918 4.78583 11.4722 4.87224 11.3716 4.9815C11.271 5.09076 11.1914 5.22069 11.1375 5.36371C11.0836 5.50672 11.0565 5.65995 11.0578 5.81447C11.059 5.96898 11.0886 6.12168 11.1447 6.26366C11.2009 6.40563 11.2825 6.53404 11.3848 6.64138L13.8429 9.31089H3.5715C3.28732 9.31089 3.01478 9.43349 2.81383 9.65173C2.61289 9.86996 2.5 10.1659 2.5 10.4746C2.5 10.7832 2.61289 11.0792 2.81383 11.2974C3.01478 11.5157 3.28732 11.6383 3.5715 11.6383H13.8429L11.3859 14.3066C11.2836 14.414 11.202 14.5424 11.1458 14.6843C11.0896 14.8263 11.0601 14.979 11.0588 15.1335C11.0576 15.288 11.0847 15.4413 11.1386 15.5843C11.1925 15.7273 11.272 15.8572 11.3726 15.9665C11.4732 16.0758 11.5929 16.1622 11.7246 16.2207C11.8562 16.2792 11.9973 16.3086 12.1396 16.3073C12.2819 16.3059 12.4225 16.2738 12.5532 16.2129C12.6839 16.1519 12.8022 16.0632 12.901 15.9521L17.187 11.2973C17.2863 11.189 17.3649 11.0605 17.4184 10.9191Z'
                  fill='#1C64F2'
                />
              </svg>
            </span>
          </a>
        </div>
        {conditionalRendering[currentStep]}
      </div>
    </section>
  )
}

export default SignInPage
