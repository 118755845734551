import React from 'react'
import { coreSlice, dashboardAttendanceSlice } from '../../../reducers'
import { useDispatch, useSelector } from 'react-redux'
import { useEvent } from '@emerald-works/react-event-bus-client'
import Loading from '../../loading'
import AgoraUIKit from "agora-react-uikit"
import { useMediaQuery } from '@material-ui/core'
import Comments from './comments'

const { REACT_APP_AGORA_IO_APP_ID } = process.env

const VideoStream = () => {
  const user = useSelector(coreSlice.selectors.selectUser)
  const dispatcher = useDispatch()
  const attendance = useSelector(dashboardAttendanceSlice.selectors.selectAttendance)
  const notes = useSelector(dashboardAttendanceSlice.selectors.selectNotes)
  const hasNotesChanges = useSelector(dashboardAttendanceSlice.selectors.selectHasNotesChanges)
  const attendances = useSelector(dashboardAttendanceSlice.selectors.selectAttendances)
  const isPatientOrFamilyUser = React.useMemo(() => user?.role === 'patient' || user?.role === 'family', [user])
  const patient = useSelector(isPatientOrFamilyUser ? coreSlice.selectors.selectPatient : dashboardAttendanceSlice.selectors.selectPatient)
  const videoCall = useSelector(coreSlice.selectors.selectVideoCall)
  const rtmToken = useSelector(dashboardAttendanceSlice.selectors.selectRtmToken)
  const inProgressAttendance = useSelector(dashboardAttendanceSlice.selectors.selectInProgressAttendance)
  const [getAttendances, getInProgressAttendance, generateAttendanceRtmToken] = useEvent([dashboardAttendanceSlice.eventBus.getAttendances, dashboardAttendanceSlice.eventBus.getInProgressAttendance, dashboardAttendanceSlice.eventBus.generateAttendanceRtmToken])
  const [getDoctorInProgressAttendance] = useEvent([
    dashboardAttendanceSlice.eventBus.getDoctorInProgressAttendance
  ])
  const [recordAttendance, finishAttendance, updateAttendance] = useEvent([
    {
      ...dashboardAttendanceSlice.eventBus.recordAttendance, onSuccess: data => {
        if (!data.success) {
          if (isRecording) {
            alert('Falha na gravação. O atendimento será interrrompido.')
          } else {
            alert('O atendimento ainda não foi iniciado pelo terapeuta.')
          }
          setIsRecording(false)
        } else {
          setIsRecording(true)
        }
      }
    },
    {
      ...dashboardAttendanceSlice.eventBus.finishAttendance, onSuccess: data => {
        dispatcher(dashboardAttendanceSlice.actions.setAttendance(data))
        getAttendances.trigger({ patient: patient?.pk, limit: 5 })
        getDoctorInProgressAttendance.trigger()
      }
    }, {
      ...dashboardAttendanceSlice.eventBus.updateAttendance, onSuccess: data => {
        dispatcher(dashboardAttendanceSlice.actions.setAttendance(data))
        getAttendances.trigger({ patient: patient?.pk, limit: 5 })
      }
    }])
  const setVideoCall = React.useCallback(vc => dispatcher(coreSlice.actions.setVideoCall(vc), [dispatcher]))
  const [isRecording, setIsRecording] = React.useState(false)
  const [role, setRole] = React.useState('host')

  const rtcProps = React.useMemo(() => ({
    appId: REACT_APP_AGORA_IO_APP_ID,
    channel: patient?.doc,
    token: attendance?.token,
    role: role,
    enableScreensharing: true,
    screenshareUid: user?.pk,
    screenshareToken: attendance?.token,
    enableVideo: false,
    enableAudio: false,
    activeSpeaker: user?.pk === attendance?.doctor?.pk
  }), [attendance, user, patient, role])

  React.useEffect(() => {
    if (patient?.pk) {
      generateAttendanceRtmToken.trigger({ patient: patient?.pk })
    }
  }, [patient])

  const rtmProps = React.useMemo(() => ({ username: user.name, displayUsername: true, token: rtmToken, uid: user?.pk }), [rtmToken, user])

  const handleStopVideoCall = React.useCallback(() => {
    setVideoCall(false)
  }, [])

  const callbacks = {
    EndCall: () => { handleStopVideoCall() },
  }

  React.useEffect(() => {
    const record = () => {
      if (attendance?.start && videoCall) {
        recordAttendance.trigger(attendance)
      }
    }
    const interval = setInterval(record, 30000)
    return () => {
      clearInterval(interval)
    }
  }, [attendance, videoCall])

  const handleStartVideoCall = React.useCallback((role = 'host') => {
    if (attendance?.start) {
      setVideoCall(true)
      setRole(role)
      recordAttendance.trigger(attendance)
    }
  }, [attendance, role])

  const endAttendance = React.useCallback(() => {
    if (attendance.start) {
      finishAttendance.trigger(attendance)
    }
  }, [attendance])

  const isSupervisor = React.useMemo(
    () => patient?.supervisors?.find((s) => s.pk === user.pk),
    [patient, user]
  )
  const isReady = React.useMemo(() => attendance?.start && rtmToken, [attendance, rtmToken])
  const ableToSelectAttendance = React.useMemo(() => user?.role === 'doctor' && !isSupervisor, [user, isSupervisor])
  const hasChanges = React.useMemo(() => notes?.length && !hasNotesChanges, [notes, hasNotesChanges])

  const setNotes = notes => {
    dispatcher(dashboardAttendanceSlice.actions.setNotes(notes))
  }

  const update = () => {
    updateAttendance.trigger(({ ...attendance, notes }))
  }

  React.useEffect(() => {
    if (!ableToSelectAttendance) {
      dispatcher(dashboardAttendanceSlice.actions.setAttendance(inProgressAttendance))
    }
  }, [inProgressAttendance])

  const lookForInProgressAttendance = React.useCallback(() => {
    if (patient?.pk) {
      getInProgressAttendance.trigger({ patient: patient?.pk })
    }
  }, [patient])

  React.useEffect(() => {
    if (patient?.pk) {
      lookForInProgressAttendance()
    }
  }, [patient])

  React.useEffect(() => {
    if (!ableToSelectAttendance) {
      const delayDebounceFn = setInterval(lookForInProgressAttendance, 30000)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [attendances, ableToSelectAttendance, lookForInProgressAttendance])

  const isTabletOrMobile = useMediaQuery('(max-width:600px)')

  if (!patient?.doc || !attendance?.start) {
    return ableToSelectAttendance ? (!isTabletOrMobile ? <>Selecione um atendimento</> : <></>) : <div className='flex justify-center h-14'>{getInProgressAttendance.isWorking ? <Loading size={10} /> : <>Nenhum atendimento em andamento.</>}</div>
  }

  return videoCall && isRecording ? (
    <div className='flex items-center w-full justify-center'>
      <div style={{ position: 'absolute', display: 'flex', bottom: 0, top: 0, left: 0, right: 0, backgroundColor: 'black', zIndex: 30, flexDirection: isTabletOrMobile ? 'column' : 'row' }}>
        <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} rtmProps={rtmProps} />
        {ableToSelectAttendance && <Comments />}
      </div>
    </div>
  ) : (
    <>
      {!attendance.hasFinished && (
        <div className='flex justify-center'>
          {!ableToSelectAttendance && <button className='btn-primary' onClick={() => handleStartVideoCall('audience')} disabled={!isReady}>Assistir Atendimento {recordAttendance.isWorking && role === 'audience' && <Loading size={3} />}</button>}
          <button className='btn-primary' onClick={() => handleStartVideoCall('host')} disabled={!isReady}>Participar do Atendimento {recordAttendance.isWorking && role === 'host' && <Loading size={3} />}</button>
        </div>
      )}
      <>
        {ableToSelectAttendance &&
          <div className='p-2'>
            <label className='label'>Evolução</label>
            <textarea className='input' value={notes} rows={8} onChange={({ target: { value } }) => setNotes(value)} disabled={attendance?.hasFinished || updateAttendance.isWorking} />
            {!attendance.hasFinished && <>
              <div className='flex justify-end'>
                {updateAttendance.isWorking ? <div className='flex w-12 justify-center flex-col'><Loading size={5} /></div> : <button className='btn-primary' disabled={!hasNotesChanges || getAttendances.isWorking} onClick={update}>Salvar</button>}
                <button className='btn-secondary' disabled={!hasNotesChanges || updateAttendance.isWorking} onClick={() => { setNotes(attendance?.notes || '') }}>Descartar</button>
              </div>
              <p className='text-sm h-8'>{hasChanges ? '' : hasNotesChanges ? 'Você precisa salvar ou descartar as alterações para encerrar o atendimento.' : 'Você precisa adicionar a evolução do atendimento para finalizá-lo.'}</p>
              {finishAttendance.isWorking ? <div className='w-40 flex justify-center items-center h-14'><Loading size={5} /></div> : <button className='btn-primary' onClick={endAttendance} disabled={!hasChanges}>Encerrar Atendimento</button>}
            </>}
          </div>}
      </>
    </>
  )
}

export default VideoStream
