import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { chatSlice, dashboardChatSlice, coreSlice, patientSlice, componentSearchPatientsSlice } from '../../../reducers'
import {
  useEvent
} from '@emerald-works/react-event-bus-client'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Loading from '../../loading'
import { SearchPatients } from '../../search-patients'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const patientsPerPage = 30

export default function Patients({ showOnMobile }) {
  const dispatcher = useDispatch()
  const [getPatients, getChatMembers] = useEvent([
    patientSlice.eventBus.getPatients,
    chatSlice.eventBus.getChatMembers
  ])
  const [page, setPage] = React.useState('patients')
  const user = useSelector(coreSlice.selectors.selectUser)
  const isPatientOrFamily = React.useMemo(() => user?.role === 'patient' || user?.role === 'family', [user])
  const patient = useSelector((isPatientOrFamily ? coreSlice : dashboardChatSlice).selectors.selectPatient)
  const patients = useSelector(patientSlice.selectors.selectPatients)
  const chatMembers = useSelector(chatSlice.selectors.selectChatMembers)
  const [newMessage] = useEvent([
    chatSlice.eventBus.newMessage
  ])
  const patientsFromSearch = useSelector(
    patientSlice.selectors.selectSearchPatients
  )
  const isSearching = useSelector(componentSearchPatientsSlice.selectors.selectIsSearching)
  const isSearchPatientWorking = useSelector(componentSearchPatientsSlice.selectors.selectIsWorking)
  const patientsList = React.useMemo(() => isSearching ? patientsFromSearch : patients, [isSearching, patients, patientsFromSearch])

  const setPatient = React.useCallback(
    patient => {
      dispatcher(dashboardChatSlice.actions.setPatient(patient))
    },
    [dispatcher]
  )

  React.useEffect(() => {
    if (patients?.length) {
      for (const patient of patients) {
        newMessage.subscribeToEvent({ id: patient?.pk })
      }
    }
  }, [patients, newMessage])

  React.useEffect(() => {
    if (user?.role === 'doctor') {
      getPatients.trigger({ limit: patientsPerPage })
    }
  }, [getPatients, user])

  React.useEffect(() => {
    if (patient?.pk) {
      setPage('members')
    } else {
      setPage('patients')
    }
  }, [patient])

  React.useEffect(() => {
    if (page === 'members' && patient?.pk) {
      getChatMembers.trigger({ patient: patient?.pk })
    }
  }, [getChatMembers, patient, page])

  const GroupMembersComponent = () => {
    return (
      <div className={`h-[72.2vh] px-2 md:block relative ${showOnMobile ? '' : 'hidden'}`}>
        <div className='text-lg font-semibold'>Membros</div>
        <div className='px-6 overflow-y-auto bg-white border-b border-gray-200 h-full'>
          <div className='pb-10'>
            {isLoading ?
              <div className='w-full p-2 flex justify-center'>
                <Loading />
              </div> :
              chatMembers.map((member, index) => {
                const { role, email, name } = member
                return (
                  <div
                    key={index}
                    className='transition duration-150 ease-in-out cursor-pointer bg-white border-b border-gray-200 hover:bg-gray-100 flex items-center px-3 py-2 text-sm'
                  >
                    <AccountCircleIcon fontSize='large' />
                    <div className='flex flex-col pl-2'>
                      <p className='font-semibold'>{name}</p>
                      <p className='text-secondary-gray'>
                        {role === 'patient'
                          ? 'Paciente'
                          : role === 'family'
                            ? 'Familiar'
                            : role === 'doctor'
                              ? 'Doutor'
                              : ''}
                      </p>
                      <p className='text-secondary-gray'>{email}</p>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }

  const isLoading = React.useMemo(() => getPatients.isWorking || getChatMembers.isWorking || isSearchPatientWorking, [getChatMembers.isWorking, getPatients.isWorking, isSearchPatientWorking])

  if (page === 'members') {
    return <GroupMembersComponent />
  }

  return (
    <div className='h-[92vh] bg-white border-r px-2'>
      <h1 className='title'>Pacientes</h1>
      <SearchPatients patientsPerPage={patientsPerPage} />
      <div className='overflow-y-auto border-b border-gray-200'>
        <div className='w-full flex justify-center'>
          {isLoading ?
            <div className='p-2 items-center'>
              <Loading />
            </div> :
            !patientsList.length ?
              <>Não foram encontrados pacientes.</> :
              <div className='w-full'>
                {patientsList?.map((g, i) => (
                  <div
                    key={i}
                    className={classNames(
                      patient?.pk === g.pk
                        ? 'bg-gray-100'
                        : 'transition duration-150 ease-in-out cursor-pointer bg-white border-b border-gray-200 hover:bg-gray-100',
                      'flex items-center py-2 text-sm'
                    )}
                    onClick={() => { setPatient(g) }}
                  >
                    <AccountCircleIcon fontSize='large' />
                    <div className='flex flex-col pl-2'>
                      <p className='font-semibold'>{g.name}</p>
                      <p className='text-secondary-gray'>{g.pk}</p>
                    </div>
                  </div>
                ))}
              </div>}
        </div>
      </div>
    </div>
  )
}
