import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const Recover = ({ setCurrentStep, setRegisterData }) => {
  const { handleSubmit, handleChange, errors, touched, handleBlur, values } =
    useFormik({
      initialValues: {
        email: ''
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email('Insira um e-mail válido')
          .required('O e-mail é obrigatório')
      }),
      onSubmit: values => {
        setRegisterData(values)
        setCurrentStep(4)
      }
    })

  return (
    <div className='flex flex-col p-8 w-full'>
      <div className='bg-white rounded-lg shadow-lg w-full lg:w-[448px] xl:p-0'>
        <div className='p-6 space-y-4 md:space-y-6 sm:p-8 w-full'>
          <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
            Recuperar senha
          </h1>
          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label
                htmlFor='email'
                className='block mb-2 text-sm font-medium text-gray-900'
              >
                Seu email
              </label>
              <input
                type='email'
                name='email'
                id='email'
                className='input-with-error'
                placeholder='name@company.com'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                required
              />

              {touched.email && errors.email ? <div>{errors.email}</div> : null}
            </div>
            <button
              type='submit'
              className='signin-btn'
            >
              Alterar Senha
            </button>
            <p className='text-sm font-light text-gray-500'>
              Ja tem uma conta?{' '}
              <button
                onClick={() => setCurrentStep(0)}
                type='button'
                className='font-medium text-primary-blue hover:underline'
              >
                Logue aqui
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Recover
