import { useEvent } from '@emerald-works/react-event-bus-client'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { coreSlice, meetingSlice } from '../../reducers'
import { useSelector } from 'react-redux'
import LoadingPage from '../../components/loading-page'
import AgoraUIKit, { layout } from 'agora-react-uikit'

const { REACT_APP_AGORA_IO_APP_ID } = process.env

const Meeting = () => {
  const { sk } = useParams()
  const [getMeeting, generateMeetingRtmToken] = useEvent([meetingSlice.eventBus.getMeeting, meetingSlice.eventBus.generateMeetingRtmToken])
  const meeting = useSelector(meetingSlice.selectors.selectMeeting)
  const rtmToken = useSelector(meetingSlice.selectors.selectRtmToken)
  const user = useSelector(coreSlice.selectors.selectUser)
  const rtcProps = React.useMemo(() => ({
    appId: REACT_APP_AGORA_IO_APP_ID,
    channel: sk,
    token: meeting?.token,
    role: 'host',
    enableScreensharing: true,
    screenshareUid: user?.pk,
    screenshareToken: meeting?.token,
    enableVideo: false,
    enableAudio: false
  }), [meeting, user, sk])
  const rtmProps = React.useMemo(() => ({ username: user.name, displayUsername: true, token: rtmToken, uid: user?.pk }), [rtmToken, user])
  const navigate = useNavigate()
  React.useEffect(() => {
    if (sk) {
      getMeeting.trigger({ sk })
      generateMeetingRtmToken.trigger({ sk })
    }
  }, [sk])
  if (getMeeting.isWorking) {
    return <LoadingPage />
  }
  if (!meeting) {
    return 'Não foi possível carregar as informações da reunião.'
  }

  return (
    <div style={{ position: 'absolute', display: 'flex', bottom: 0, top: 0, left: 0, right: 0, backgroundColor: 'black' }}>
      <AgoraUIKit rtcProps={rtcProps} rtmProps={rtmProps} callbacks={{ EndCall: () => { navigate('/') } }} />
    </div>
  )
}

export default Meeting