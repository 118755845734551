import name from './name'

const selectSliceData = (state) => state[name]

export const selectPatient = (state) => selectSliceData(state).patient
export const selectFamilyPatients = (state) =>
  selectSliceData(state).familyPatients
export const selectDoctor = (state) => selectSliceData(state).doctor
export const selectUser = (state) => selectSliceData(state).user
export const selectAuthUser = (state) => selectSliceData(state).authUser
export const selectNotActiveTenant = (state) =>
  selectSliceData(state).notActiveTenant
export const selectVideoCall = (state) => selectSliceData(state).videoCall
