import React from 'react'
import { useSelector } from 'react-redux'
import { adminSlice } from '../../../../reducers'
import ConfirmModal from '../../../confirm-modal'
import { useEvent } from '@emerald-works/react-event-bus-client'
import Modal from '../../../modal'
import CreateSubscription from './create-subscription'

const Plan = () => {
  const plans = useSelector(adminSlice.selectors.selectPlans)
  const plan = useSelector(adminSlice.selectors.selectPlan)
  const [selectedPlan, setSelectedPlan] = React.useState(null)
  const subscription = useSelector(adminSlice.selectors.selectSubscription)
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] = React.useState(false)
  const [openCreateSubscriptionModal, setOpenCreateSubscriptionModal] = React.useState(false)
  const [getSubscription] = useEvent([adminSlice.eventBus.getSubscription])
  const [cancelSubscription] = useEvent([{ ...adminSlice.eventBus.cancelSubscription, onSuccess: getSubscription.trigger }])
  const handleCancelSubscription = () => {
    cancelSubscription.trigger()
    setOpenCancelSubscriptionModal(false)
  }

  const handleNewSubscription = plan => {
    setSelectedPlan(plan)
    setOpenCreateSubscriptionModal(true)
  }
  return (
    <div className='flex md:h-full h-[32rem] overflow-scroll'>
      <ConfirmModal open={openCancelSubscriptionModal} content={`Tem certeza que deseja cancelar a assinatura atual no plano ${plan?.name}?`} onCancel={() => setOpenCancelSubscriptionModal(false)} onConfirm={handleCancelSubscription} title='Cancelar assinatura' />
      <Modal open={openCreateSubscriptionModal} content={<CreateSubscription plan={selectedPlan} close={() => setOpenCreateSubscriptionModal(false)} />} title='Nova assinatura' onClose={() => setOpenCreateSubscriptionModal(false)} />
      <div className='flex md:flex-row flex-col gap-4'>
        {plans.map((p, key) => {
          const isCurrentPlan = plan?.idPlan === p.idPlan
          const alreadyHasAPlan = !!plan?.idPlan
          return (
            <div key={key} className={`${isCurrentPlan ? 'bg-gray-100 border-black' : 'bg-white'} border rounded-md shadow-lg h-80 w-56 flex flex-col items-center my-4 md:mx-0 mx-4 hover:bg-gray-100`}>
              <div className='h-10 w-full justify-start p-1'>{isCurrentPlan && <div><p className='text-sm font-semibold'>Plano atual</p><p className='text-sm'>Status da assinatura: {subscription.statusName}</p></div>}</div>
              <div className='p-4'>
                <div className='h-40'>
                  <h1 className='font-semibold'>{p.name}</h1>
                  <div className='flex flex-col items-center'>
                    <p>Por apenas</p>
                    <p className='font-medium'>R${p.amount}</p>
                    <p>por mês</p>
                  </div>
                </div>
                <div className='flex w-full justify-center'>
                  {isCurrentPlan ? <button className='btn-secondary' onClick={() => { setOpenCancelSubscriptionModal(true) }}>Cancelar Assinatura</button> : <button disabled={alreadyHasAPlan} onClick={() => handleNewSubscription(p)} className='btn-primary'>Contratar Plano</button>}
                </div>
                {alreadyHasAPlan && !isCurrentPlan && <p className='text-xs opacity-70 w-full flex justify-center'>Você precisa cancelar a assinatura atual para contratar este plano.</p>}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Plan
