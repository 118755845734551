import name from './name'

const selectSliceData = (state) => state[name]

export const selectUser = (state) => selectSliceData(state).user
export const selectPatient = (state) => selectSliceData(state).patient
export const selectDoctor = (state) => selectSliceData(state).doctor
export const selectPlan = (state) => selectSliceData(state).plan
export const selectSubscription = (state) => selectSliceData(state).subscription
export const selectCreditCards = (state) => selectSliceData(state).creditCards
export const selectPlans = (state) => selectSliceData(state).plans
export const selectCharges = (state) => selectSliceData(state).charges
export const selectSignatureDocuments = (state) =>
  selectSliceData(state).signatureDocuments
