import React from 'react'
import LogoImage from '../../assets/img/main-logo.png'
import { useAuth } from '../../contexts/user-authentication'
import { PagesContext } from '../../contexts/pages'
import { useMediaQuery } from '@material-ui/core'
import MenuIcon from '@mui/icons-material/Menu'
import { useSelector } from 'react-redux'
import { coreSlice } from '../../reducers'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const ToolBar = () => {
  const isTabletOrMobile = useMediaQuery('(max-width:767px)')
  const { toggleShowLeftBar } = React.useContext(PagesContext)
  const { singOut } = useAuth()
  const { role } = useSelector(coreSlice.selectors.selectUser)
  const navigate = useNavigate()
  const { tenantKey } = useParams()
  const { pathname } = useLocation()
  const dashboard = `/${tenantKey}/dashboard`
  const admin = `/${tenantKey}/admin`
  return (
    <nav className='z-30 fixed top-0 left-0 bg-white shadow-sm w-full md:z-20 md:px-8'>
      <div className='w-full flex justify-between items-center h-full p-2'>
        {isTabletOrMobile && <MenuIcon className='icon' onClick={toggleShowLeftBar} />}
        <div>
          <img
            width={148}
            height={38}
            src={LogoImage}
            alt='Logo Vinculo Integral'
          />
        </div>
        <ul className='flex gap-8 items-center'>
          {/* <li>
          <a href="/">Protocolos/Modelos</a>
        </li>
        <li>
          <a href="/">Pacientes</a>
        </li>
        <li>
          <a href="/">Equipe</a>
        </li>
        <li>
          <a
            href="/"
            className="hover:underline underline-offset-4 hover:font-medium transition-all ease-in duration-150"
          >
            Administrativo
          </a>
        </li> */}
          <li>
            {(role === 'admin' || role === 'owner') &&
              <button className='pr-4' type='button' onClick={() => pathname === dashboard ? navigate(admin) : pathname === admin ? navigate(dashboard) : () => { }}>
                {pathname === dashboard ? 'Admin' : pathname === admin ? 'Dashboard' : ''}
              </button>}
            <button type='button' onClick={() => singOut()}>
              Sair
            </button>
          </li>
        </ul>
      </div>
    </nav >
  )
}

export default ToolBar
