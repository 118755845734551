import React from 'react'
import ApplicationRoutes from './routes'
import Logger from './utils/logger'
import RootContextProvider from './contexts/root'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { EventContextProvider } from './contexts/event'
import { PagesContextProvider } from './contexts/pages'
import AgoraRTC, { AgoraRTCProvider, useRTCClient } from 'agora-rtc-react'

function App() {
  const client = useRTCClient(
    AgoraRTC.createClient({
      codec: 'vp8',
      mode: 'live'
    })
  )
  AgoraRTC.setLogLevel(3)
  return (
    <AgoraRTCProvider client={client}>
      <RootContextProvider>
        <EventContextProvider>
          <PagesContextProvider>
            <Logger />
            <main>
              <ApplicationRoutes />
            </main>
            <ToastContainer position='top-center' />
          </PagesContextProvider>
        </EventContextProvider>
      </RootContextProvider>
    </AgoraRTCProvider>
  )
}

window.Buffer = window.Buffer || require('buffer').Buffer

export default App
