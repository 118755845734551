import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { componentsSelectFamilySlice } from '../../reducers'
import Select from 'react-select'

const SelectFamily = ({ readOnly }) => {
  const usersPerPage = 5
  const [search, setSearch] = React.useState('')
  const [searchBy, setSearchBy] = React.useState('name')
  const [isSearching, setIsSearching] = React.useState(false)
  const [getFamilyUsers] = useEvent([componentsSelectFamilySlice.eventBus.getFamilyUsers])
  const familyUsersFromSearch = useSelector(
    componentsSelectFamilySlice.selectors.selectFamilyUsers
  )
  const familyUser = useSelector(componentsSelectFamilySlice.selectors.selectFamilyUser)
  const patientFamily = useSelector(componentsSelectFamilySlice.selectors.selectPatientFamily)

  const dispatcher = useDispatch()

  React.useEffect(() => {
    if (!isSearching) {
      dispatcher(componentsSelectFamilySlice.actions.setFamilyUsers([]))
    }
  }, [dispatcher, isSearching])

  const searchForFamilyUsers = React.useCallback(() => {
    if (search.length >= 3) {
      setIsSearching(true)
      getFamilyUsers.trigger({ limit: usersPerPage, search, searchBy, role: 'family' })
    }
  }, [search, searchBy, getFamilyUsers])

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(searchForFamilyUsers, 2000)
    return () => clearTimeout(delayDebounceFn)
  }, [search, searchBy, searchForFamilyUsers, getFamilyUsers])

  const filteredUsersFamilyFromSearch = familyUsersFromSearch.filter(family => patientFamily.find(({ pk }) => pk === family.pk) === undefined)

  const options = filteredUsersFamilyFromSearch.map(({ pk, name }) => ({
    label: `${name} - ${pk}`,
    id: pk
  }))

  const noOptionsMessage = () => (
    <>
      {getFamilyUsers.isWorking
        ? 'Buscando usuários'
        : !filteredUsersFamilyFromSearch.length && isSearching
          ? 'Não foram encontrados usuários'
          : `Digite o ${searchBy === 'email' ? 'e-mail' : 'nome'} do familiar`}
    </>
  )

  const setFamilyUser = ({ id }) => {
    const familyUser = familyUsersFromSearch.find(({ pk }) => pk === id)
    dispatcher(componentsSelectFamilySlice.actions.setFamilyUser(familyUser))
    setIsSearching(false)
  }

  return (
    <div className='flex md:w-[48rem] w-full flex-col md:flex-row gap-1'>
      {!readOnly && (
        <div className='flex flex-col pb-2'>
          <label className='text-secondary-gray text-xs' htmlFor='searchBy'>
            Buscar usando
          </label>
          <label className='flex' id='searchBy'>
            <div
              onClick={() => setSearchBy('name')}
              className={`text-sm px-2 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'name' ? 'bg-secondary-gray' : 'bg-transparent'}`}
            >
              Nome
            </div>
            <div
              onClick={() => setSearchBy('email')}
              className={`text-sm px-2 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'email' ? 'bg-secondary-gray' : 'bg-transparent'}`}
            >
              Email
            </div>
          </label>
        </div>
      )}
      <Select
        styles={{
          placeholder: (styles) => ({
            ...styles,
            color: readOnly ? styles.color : 'black'
          }),
          input: (styles) => ({
            ...styles,
            paddingTop: 10,
            paddingBottom: 10,
            color: 'black'
          }),
          control: (styles) => ({
            ...styles,
            backgroundColor: 'transparent'
          })
        }}
        isDisabled={readOnly}
        className='w-64 text-xs z-10'
        value={search}
        onChange={setFamilyUser}
        onInputChange={setSearch}
        noOptionsMessage={noOptionsMessage}
        options={options}
        placeholder={
          familyUser?.pk
            ? `${familyUser.name} - ${familyUser.email}`
            : 'Selecione o familiar'
        }
      />
    </div>
  )
}
export default SelectFamily
