import * as actions from './slice'

export const searchPatients = {
  eventName: 'getPatients',
  onSuccess: {
    action: actions.setSearchPatients,
    redux: true
  }
}

export const getPatients = {
  eventName: 'getPatients',
  onSuccess: {
    action: actions.setPatients,
    redux: true
  }
}
