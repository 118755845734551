import name from './name'

const selectSliceData = state => state[name]

const selectUsers = state => selectSliceData(state).users
const selectSearchUsers = state => selectSliceData(state).searchUsers

export {
  selectUsers,
  selectSearchUsers
}
