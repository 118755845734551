import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import SignIn from './pages/signin'
import PrivateRoute from './components/shared/private-route'
import Dashboard from './pages/dashboard'
import Admin from './pages/admin'
import AdminRoute from './components/shared/admin-route'
import { useSelector } from 'react-redux'
import { coreSlice } from './reducers'
import Meeting from './pages/meeting'

const ApplicationRoutes = () => {
  const authUser = useSelector(coreSlice.selectors.selectAuthUser)
  return (
    <Routes>
      <Route
        path='/'
        element={
          authUser ? <Navigate to='dashboard' /> : <Navigate to='signin' />
        }
      />
      <Route path='/signin' element={<SignIn />} />
      <Route
        path='/dashboard'
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path='/admin'
        element={
          <AdminRoute>
            <Admin />
          </AdminRoute>
        }
      />
      <Route
        path='/meeting/:sk'
        element={
          <PrivateRoute>
            <Meeting />
          </PrivateRoute>
        }
      />
    </Routes>
  )
}

export default ApplicationRoutes
