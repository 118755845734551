import React from 'react'
import { useSelector } from 'react-redux'
import { coreSlice } from '../../../../reducers'

const PatientDetails = () => {
  const patient = useSelector(coreSlice.selectors.selectPatient)
  return (
    <section>
      <div className='flex lg:flex-row flex-col justify-between w-full text-sm'>
        <div className='border border-slate-300 rounded-lg flex flex-1 flex-col p-2'>
          <div className='flex justify-between items-center w-full mb-4'>
            <h1 className='font-semibold'>Detalhes</h1>
            <button
              type='button'
              className='font-semibold p-2 border border-slate-300 rounded-lg'
            >
              Editar
            </button>
          </div>
          <div className='flex flex-col gap-1 my-1'>
            <label htmlFor='name' className='font-semibold'>
              Nome
            </label>
            <input
              type='text'
              id='name'
              name='name'
              className='border border-slate-300 rounded-lg p-2'
              value={patient.name}
            />
          </div>

          <div className='flex flex-col gap-1 my-1'>
            <label htmlFor='gender' className='font-semibold'>
              Gênero
            </label>
            <input
              type='text'
              id='gender'
              name='gender'
              className='border border-slate-300 rounded-lg p-2'
              value={patient.gender}
            />
          </div>

          <div className='flex flex-col gap-1 my-1'>
            <label htmlFor='dateOfBirth' className='font-semibold'>
              Data de Nascimento
            </label>
            <input
              type='text'
              id='dateOfBirth'
              name='dateOfBirth'
              className='border border-slate-300 rounded-lg p-2'
              value={patient.dateOfBirth}
            />
            <span className='pl-1 my-1'>{patient.age}</span>
          </div>
        </div>

        <div className='flex flex-col items-center flex-1 pl-4'>
          <div className='flex items-center justify-start gap-1 w-full'>
            <h1 className='text-lg font-semibold'>Protocolo:</h1>
            <select
              name='protocol'
              id='protocol'
              className='p-2 border border-slate-300 rounded-lg w-full'
            >
              <option value='vb-mapp'>VB-MAPP</option>
            </select>
          </div>
          <div className='w-full bg-gray-300 rounded-lg font-semibold flex justify-between bg my-2 p-1'>
            <button type='button' className='flex-1'>
              Barreiras
            </button>
            <button type='button' className='p-4 flex-1 bg-white rounded-lg'>
              Marcos de Desenvolvimento
            </button>
          </div>
          <div className='w-full p-4 rounded-md flex flex-col bg-white border border-slate-300'>
            <div className='flex flex-col'>
              <h1 className='font-semibold text-lg'>Avaliações</h1>
              <div className='my-2 gap-2'>
                <p>1 de junho de 2021</p>
                <p>30 de dezembro de 2021</p>
              </div>
              <button
                type='button'
                className='border border-slate-300 font-semibold p-4 rounded-lg max-w-3xl mx-auto'
              >
                Nova avaliação
              </button>
            </div>
          </div>
          <div className='w-full p-4 flex flex-col bg-white border-r border-l border-b rounded-md border-slate-300'>
            <div className='flex flex-col'>
              <h1 className='font-semibold text-lg'>Plano de Intervenção</h1>
              <div className='my-2 gap-2'>
                <p>3 de outubro de 2023</p>
                <p>18 de dezembro de 2023</p>
              </div>
              <button
                type='button'
                className='border border-slate-300 font-semibold p-4 rounded-lg max-w-3xl mx-auto'
              >
                Novo Plano de Intervenção
              </button>
            </div>
          </div>
          <div className='w-full p-4 flex flex-col bg-white border-r border-l border-b rounded-md border-slate-300'>
            <div className='flex flex-col'>
              <h1 className='font-semibold text-lg'>Sessão</h1>
              <div className='my-2 gap-2'>
                <p>Nenhuma avaliação até o momento</p>
              </div>
              <button
                type='button'
                className='border border-slate-300 font-semibold p-4 rounded-lg max-w-3xl mx-auto'
              >
                Nova sessão
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PatientDetails
