import * as actions from './slice'

export const searchDoctors = {
  eventName: 'getDoctors',
  onSuccess: {
    action: actions.setSearchDoctors,
    redux: true
  }
}

export const addDoctorToGroup = {
  eventName: 'addDoctorToGroup'
}

export const removeDoctorFromGroup = {
  eventName: 'removeDoctorFromGroup'
}
