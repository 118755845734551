import React from 'react'

const Modal = ({ open, content, title, onClose }) => {
  return (
    <>
      {
        open
          ? (
            <>
              <div
                className='modal'
              >
                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                  <div className='modal-content'>
                    {title &&
                      <div className='flex justify-between p-5 border-b border-solid rounded-t flex-row items-center'>
                        <h3 className='title'>
                          {title}
                        </h3>
                        {onClose && <button
                          className='flex content-center  justify-end ml-auto text-black opacity-50 text-3xl font-semibold'
                          onClick={onClose}
                        >
                          <span className='flex items-center justify-end text-black opacity-50 h-6 w-6 text-2xl outline-none focus:outline-none'>
                            ×
                          </span>
                        </button>}
                      </div>}
                    {content &&
                      <div className='relative px-6 pb-6 flex-auto'>
                        <div className='my-4 text-lg overflow-auto max-h-[75vh]'>
                          {content}
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
              <div className='opacity-25 fixed inset-0 z-40 bg-black' />
            </>)
          : null
      }
    </>
  )
}

export default Modal
