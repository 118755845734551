import React from 'react'
import { useAuth } from '../../../contexts/user-authentication'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const SignIn = ({ setCurrentStep, setRegisterData }) => {
  const { signIn } = useAuth()

  const { handleSubmit, handleChange, errors, touched, handleBlur, values } =
    useFormik({
      initialValues: {
        email: '',
        password: ''
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email('Insira um e-mail válido')
          .required('O e-mail é obrigatório'),
        password: Yup.string().required('A senha é obrigatória')
      }),
      onSubmit: (values) => {
        signIn(values.email, values.password).catch(() => {
          setRegisterData(values)
          setCurrentStep(2)
        })
      }
    })

  return (
    <div className='flex flex-col p-8 w-full'>
      <div className='bg-white rounded-lg shadow-lg w-full lg:w-[448px] xl:p-0'>
        <div className='p-6 sm:p-8 w-full'>
          <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
            Entrar
          </h1>
          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label
                htmlFor='email'
                className='label'
              >
                Seu email
              </label>
              <input
                type='email'
                name='email'
                id='email'
                className='input-with-error'
                placeholder='name@company.com'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                required
              />

              {touched.email && errors.email ? <div>{errors.email}</div> : null}
            </div>
            <div className='mb-4'>
              <label
                htmlFor='password'
                className='label'
              >
                Senha
              </label>
              <input
                type='password'
                name='password'
                id='password'
                placeholder='••••••••'
                className='input-with-error'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                required
              />
              {touched.password && errors.password ? (
                <div>{errors.password}</div>
              ) : null}
              <div className='flex items-center justify-between'>
                <p
                  onClick={() => setCurrentStep(3)}
                  className='text-sm font-medium text-primary-600 hover:underline text-red-600 cursor-pointer'
                >
                  Esqueceu a senha?
                </p>
              </div>
            </div>
            <button
              type='submit'
              className='signin-btn'
            >
              Entrar
            </button>
            <p className='text-sm font-light text-gray-500'>
              Não tem conta?{' '}
              <button
                type='button'
                onClick={() => setCurrentStep((oldState) => oldState + 1)}
                className='font-medium text-primary-600 hover:underline text-primary-blue cursor-pointer'
              >
                Cadastre-se agora
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignIn
