import React from 'react'
import SendIcon from '@mui/icons-material/Send'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { chatSlice, coreSlice } from '../../reducers'
import { useDispatch, useSelector } from 'react-redux'

export default function ChatForm({ patient }) {
  const [text, setText] = React.useState('')
  const dispatcher = useDispatch()
  const user = useSelector(coreSlice.selectors.selectUser)

  const [sendMessage] = useEvent([chatSlice.eventBus.sendMessage])

  const onSubmit = () => {
    if (user && patient && text.length > 0) {
      const message = {
        patient: patient?.pk,
        text,
        sender: user.userName,
        time: new Date().valueOf()
      }
      dispatcher(chatSlice.actions.sendMessage(message))
      sendMessage.trigger(message)
      setText('')
    }
  }

  return (
    <div className='relative h-16'>
      <div className='flex items-center justify-between w-full p-3 bg-white border-b border-gray-200'>
        <input
          type='text'
          placeholder='Escreva uma mensagem'
          className='block w-full py-2 pl-4 mx-3 outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500'
          value={text}
          onChange={e => setText(e.target.value)}
          onKeyDown={({ key }) => key === 'Enter' && onSubmit()}
        />
        <button onClick={onSubmit} disabled={text.length === 0}>
          <SendIcon />
        </button>
      </div>
    </div>
  )
}
