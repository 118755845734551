import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { coreSlice } from '../../../reducers'

const AdminRoute = ({ children }) => {
  const { cognitoGroups } = useSelector(coreSlice.selectors.selectAuthUser) || {}
  const { tenantKey } = useParams()

  if (!cognitoGroups) {
    return null
  }

  const isAdmin = cognitoGroups.indexOf('admin') >= 0 || cognitoGroups.indexOf('owner') >= 0
  return isAdmin ? children : <Navigate to={`/${tenantKey}/signin`} />
}

export default AdminRoute
