import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminSlice, componentsSelectPatientSlice, coreSlice } from '../../../../reducers'
import SelectPatient from '../../../select-patient'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { PagesContext } from '../../../../contexts/pages'

const CreateUser = () => {
  const { role } = useSelector(coreSlice.selectors.selectUser)
  const { setUserPage, AdminUserPages } = React.useContext(PagesContext)
  const patient = useSelector(componentsSelectPatientSlice.selectors.selectPatient)
  const [selectedUser, setSelectedUser] = React.useState({ role: 'patient' })
  const [createUser] = useEvent([adminSlice.eventBus.createUser])
  const setUser = user => {
    dispatcher(adminSlice.actions.setUser(user))
  }

  const dispatcher = useDispatch()

  const setUserInfo = React.useCallback((field, value) => {
    setSelectedUser({ ...selectedUser, [field]: value })
  }, [selectedUser])

  const userToCreate = React.useMemo(() => {
    const userInfo = { ...selectedUser }
    if (selectedUser?.role === 'patient') {
      userInfo.patientDoc = patient?.doc
    } else {
      delete userInfo.patientDoc
    }
    return userInfo
  }, [patient, selectedUser])

  const create = () => {
    if (selectedUser?.role === 'patient') {
      if (!userToCreate?.patientDoc) {
        return
      }
    }
    createUser.trigger({ ...userToCreate })
    dispatcher(adminSlice.actions.setUser(userToCreate))
    setUser(null)
    setUserPage(AdminUserPages.ADMIN_USER_LIST)
  }

  const isEmptypatientDoc = !userToCreate?.patientDoc
  const isValidRole = selectedUser.role && ((selectedUser.role === 'patient' && patient) || selectedUser.role !== 'patient')
  const isAbleToCreate = !createUser.isWorking && selectedUser.name && isValidRole && selectedUser.email

  return (
    <section className='py-2 font-inter flex flex-col w-full px-8'>
      <div className='inline-flex items-center justify-between my-2'>
        <div className='title inline-flex'>
          <button
            className='outline-none border-none'
          >
            Novo Usuário
          </button>
        </div>
        <div className='flex'>
          <button className={isAbleToCreate ? 'btn-primary' : 'btn-disabled'} disabled={!isAbleToCreate} onClick={create}>Criar</button>
          <button className='btn-secondary' onClick={() => { setUser(null); setUserPage(AdminUserPages.ADMIN_USER_LIST) }}>Voltar</button>
        </div>
      </div>

      <form className='w-full'>
        <div className='mb-5'>
          <label htmlFor='name' className='label'>Nome</label>
          <input type='text' id='name' className='input' value={selectedUser?.name} onChange={({ target: { value } }) => setUserInfo('name', value)} />
        </div>
        <div className='mb-5'>
          <label htmlFor='role' className='label'>Papel</label>
          <select type='text' id='role' className='input' value={selectedUser?.role} onChange={({ target: { value } }) => setUserInfo('role', value)}>
            <option value='patient'>Paciente</option>
            <option value='family'>Familiar</option>
            <option value='doctor'>Profissional</option>
            <option value='admin'>Administrador</option>
            {role === 'owner' && <option value='owner'>Proprietário</option>}
          </select>
        </div>
        {selectedUser?.role === 'patient' &&
          <>
            <label className='label'>Paciente</label>
            <SelectPatient />
            {isEmptypatientDoc && <p className='text-red-800 text-sm'>Você precisa selecionar um paciente.</p>}
          </>}
        <div className='mb-5'>
          <label htmlFor='email' className='label'>Email</label>
          <input type='email' id='email' className='input' value={selectedUser?.email} onChange={({ target: { value } }) => setUserInfo('email', value)} />
        </div>
      </form>
    </section>
  )
}

export default CreateUser
