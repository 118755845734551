import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../utils'

const initialState = {
  patient: null,
  familyPatients: [],
  doctor: null,
  user: null,
  authUser: null,
  notActiveTenant: false,
  videoCall: false
}

export const core = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setPatient: (state, { payload }) => {
      state.patient = payload
    },
    setFamilyPatients: (state, { payload }) => {
      state.familyPatients = payload
    },
    setDoctor: (state, { payload }) => {
      state.doctor = payload
    },
    setUser: (state, { payload }) => {
      state.user = payload
    },
    setAuthUser: (state, { payload }) => {
      state.authUser = payload
    },
    setNotActiveTenant: (state, { payload }) => {
      state.notActiveTenant = payload
    },
    setVideoCall: (state, { payload }) => {
      state.videoCall = payload
    }
  }
})

export const {
  setPatient,
  setFamilyPatients,
  setDoctor,
  setUser,
  setAuthUser,
  setNotActiveTenant,
  setVideoCall,
  reset
} = core.actions

export default core.reducer
