import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { componentsSelectUserSlice, dashboardAgendaSlice } from '../../../reducers'
import { ClearIcon, DateTimePicker } from '@mui/x-date-pickers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import moment from 'moment'
import SelectUser from '../../select-user'
import Loading from '../../loading'

const CreateEditMeeting = ({ open, onCancel, edit, readOnly, fetchMeetings }) => {
  const [getMeetingParticipants] = useEvent([dashboardAgendaSlice.eventBus.getMeetingParticipants])
  const dispatcher = useDispatch()
  const [
    createMeeting,
    updateMeeting,
    sendInvite,
    removeInvite
  ] = useEvent([
    { ...dashboardAgendaSlice.eventBus.createMeeting, onSuccess: () => { setTimeout(fetchMeetings, 500) } },
    { ...dashboardAgendaSlice.eventBus.updateMeeting, onSuccess: () => { setTimeout(fetchMeetings, 500) } },
    {
      ...dashboardAgendaSlice.eventBus.sendInvite, onSuccess: () => {
        dispatcher(componentsSelectUserSlice.actions.setUser(null))
        setTimeout(fetchParticipants, 500)
      }
    },
    { ...dashboardAgendaSlice.eventBus.removeInvite, onSuccess: () => { setTimeout(fetchParticipants, 500) } }
  ])
  const meeting = useSelector(dashboardAgendaSlice.selectors.selectMeeting)
  const [meetingData, setMeetingData] = React.useState(meeting)
  const user = useSelector(componentsSelectUserSlice.selectors.selectUser)
  const [participants, setParticipants] = React.useState([])
  const meetingParticipants = edit ? useSelector(dashboardAgendaSlice.selectors.selectMeetingParticipants) : participants

  React.useEffect(() => {
    if (!edit) {
      setMeetingInfo('participants', participants)
    }
  }, [participants, edit])

  const setMeetingInfo = (key, value) => {
    setMeetingData({ ...meetingData, [key]: value })
  }
  const onSuccess = React.useCallback(() => {
    if (edit) {
      updateMeeting.trigger(meetingData)
    } else { createMeeting.trigger(meetingData) }
    onCancel()
    fetchMeetings()
  }, [createMeeting, edit, meetingData, fetchMeetings, onCancel, updateMeeting])

  const handleFrequencyChange = (frequency) => {
    if (meetingData.frequency && meetingData.frequency === frequency) {
      setMeetingInfo('frequency', undefined)
    } else {
      setMeetingInfo('frequency', frequency)
    }
  }

  const handleRemoveParticipant = React.useCallback(({ pk: userPk }) => {
    if (edit) {
      removeInvite.trigger({ sk: meeting?.sk, userPk })
    } else {
      setParticipants(participants => participants.filter(({ pk }) => pk !== userPk))
    }
  }, [meeting, edit])

  const handleAddParticipant = React.useCallback(() => {
    if (edit) {
      sendInvite.trigger({ sk: meeting?.sk, userPk: user?.pk })
    } else {
      setParticipants(participants => [...participants, { pk: user?.pk, name: user?.name, email: user?.email }])
      dispatcher(componentsSelectUserSlice.actions.setUser(null))
    }
  }, [meeting, user, edit])

  const fetchParticipants = React.useCallback(() => {
    if (meeting?.sk) {
      getMeetingParticipants.trigger({ sk: meeting.sk })
    }
  }, [meeting])

  React.useEffect(() => {
    fetchParticipants()
  }, [fetchParticipants])

  React.useEffect(() => {
    dispatcher(componentsSelectUserSlice.actions.setUsers([...meetingParticipants, { pk: meeting?.host }]))
  }, [meetingParticipants, meeting])

  const isValidDates = React.useMemo(() => meetingData?.startDate?.valueOf() < meetingData?.endDate?.valueOf(), [meetingData])
  const isAbleToUpdate = React.useMemo(() => edit && JSON.stringify(meetingData) !== JSON.stringify(meeting), [edit, meeting, meetingData])
  const isAbleToSave = React.useMemo(() => isValidDates && meetingData?.title?.length >= 3, [meetingData, isValidDates])
  const disabled = edit ? (!isAbleToUpdate || !isAbleToSave) : !isAbleToSave
  return (
    <>
      {
        open
          ? (
            <>
              <div
                className='modal'
              >
                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                  <div className='modal-content'>
                    <div className='flex items-start justify-between p-5 border-b border-solid rounded-t'>
                      <h3 className='title'>
                        {readOnly ? 'Informações da reunião' : edit ? 'Atualizar reunião' : 'Nova reunião'}
                      </h3>
                      <button
                        className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                        onClick={onCancel}
                      >
                        <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                          ×
                        </span>
                      </button>
                    </div>
                    <div className='relative p-2 flex-auto'>
                      <h1 className='subtitle'>Detalhes da reunião</h1>
                      <input disabled={readOnly} type='text' id='title' placeholder='Título' className='input' value={meetingData?.title} onChange={({ target: { value } }) => setMeetingInfo('title', value)} />
                      <div className='flex flex-row gap-2'>
                        <div>
                          <h1 className='subtitle'>Início</h1>
                          <DateTimePicker disabled={readOnly} value={moment(meetingData?.startDate)} onChange={value => setMeetingInfo('startDate', value.valueOf())} />
                        </div>
                        <div>
                          <h1 className='subtitle'>Fim</h1>
                          <DateTimePicker disabled={readOnly} value={moment(meetingData?.endDate)} onChange={value => setMeetingInfo('endDate', value.valueOf())} />
                        </div>
                      </div>
                      {!isValidDates && !readOnly && <p className='text-red-700 text-sm'>Data inválida.</p>}
                      <h1 className='subtitle'>Frequência</h1>
                      <div className='w-64 flex flex-row justify-between'>
                        <div className={`flex flex-row gap-2 ${readOnly ? 'opacity-70' : ''}`}><input type='checkbox' checked={meetingData?.frequency === 'daily'} onChange={() => handleFrequencyChange('daily')} disabled={readOnly} /><p>Diária</p></div>
                        <div className={`flex flex-row gap-2 ${readOnly ? 'opacity-70' : ''}`}><input type='checkbox' checked={meetingData?.frequency === 'weekly'} onChange={() => handleFrequencyChange('weekly')} disabled={readOnly} /><p>Semanal</p></div>
                        <div className={`flex flex-row gap-2 ${readOnly ? 'opacity-70' : ''}`}><input type='checkbox' checked={meetingData?.frequency === 'monthly'} onChange={() => handleFrequencyChange('monthly')} disabled={readOnly} /><p>Mensal</p></div>
                      </div>
                      <h1 className='subtitle'>Participantes</h1>
                      <div className={`h-36 ${readOnly ? 'opacity-70' : ''}`}>
                        {(getMeetingParticipants.isWorking || sendInvite.isWorking || removeInvite.isWorking) ? <div className='flex flex-col items-center'><Loading /></div> :
                          meetingParticipants.length === 0 ? <p className='text-sm'>Não há participantes.</p> :
                            meetingParticipants.length > 0 ? <div className='input max-h-36 h-36 flex overflow-auto flex-col'>
                              <table className='w-full'>
                                <thead>
                                  <tr>
                                    <th className='text-xs'>Nome</th>
                                    <th className='text-xs'>E-mail</th>
                                    {!readOnly && <th className='text-xs'>Remover</th>}
                                  </tr>
                                </thead>
                                <tbody>
                                  {meetingParticipants.map((participant, key) => (
                                    <tr key={key} className='border-b h-8'>
                                      <td className='text-center text-xs flex justify-center'>
                                        {participant?.name}
                                      </td>
                                      <td className='text-center text-xs'>
                                        {participant?.email}
                                      </td>
                                      {!readOnly && <td className='text-center text-xs'>
                                        {!(participant.pk === meeting.host) && <ClearIcon onClick={() => { handleRemoveParticipant(participant) }} className='icon cursor-pointer hover:text-gray-900' />}
                                      </td>}
                                    </tr>
                                  ))
                                  }
                                </tbody>
                              </table>
                            </div>
                              : <></>}
                      </div>
                      {!readOnly && <div>
                        <h1 className='subtitle'>Adicionar participante</h1>
                        <div className='flex flex-row items-end'>
                          <SelectUser />
                          <button className='btn-primary' onClick={handleAddParticipant} disabled={!user?.pk}>Adicionar</button>
                        </div>
                      </div>}
                    </div>
                    <div className='flex items-center justify-end rounded-b px-4'>
                      <button
                        className='btn-secondary'
                        type='button'
                        onClick={onCancel}
                      >
                        Fechar
                      </button>
                      {!readOnly &&
                        <button
                          className={`btn-primary ${disabled ? 'btn-disabled' : ''}`}
                          type='button'
                          onClick={onSuccess}
                          disabled={disabled}
                        >
                          {edit ? 'Salvar' : 'Criar'}
                        </button>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='opacity-25 fixed inset-0 z-40 bg-black' />
            </>)
          : null
      }
    </>
  )
}
export default CreateEditMeeting
