import * as actions from './slice'

export const getMessages = {
  eventName: 'getMessages',
  canSubscribe: false,
  onSuccess: [
    {
      action: actions.setMessages,
      redux: true
    }
  ]
}

export const sendMessage = {
  eventName: 'sendMessage',
  subscribeOnInit: true,
  onSuccess: {
    action: actions.addMessage,
    redux: true
  }
}

export const newMessage = {
  eventName: 'newMessage',
  onSuccess: {
    action: actions.addMessage,
    redux: true
  }
}

export const getChatMembers = {
  eventName: 'getChatMembers',
  onSuccess: {
    redux: true,
    action: actions.setChatMembers
  }
}
