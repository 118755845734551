import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../utils'

const initialState = {
  messages: {},
  hasMoreMessages: {},
  chatMembers: []
}

export const chatSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setMessages: (state, { payload }) => {
      const { patient, messages } = payload
      if (state.messages[patient]) {
        state.messages = {
          ...state.messages,
          [patient]: [...messages, ...state.messages[patient]]
        }
      } else {
        state.messages = { [patient]: messages, ...state.messages }
      }
      state.hasMoreMessages[patient] = messages.length > 0
    },
    addMessage: (state, { payload }) => {
      const { time, patient, sender } = payload
      const index = state.messages[patient].findIndex(
        (m) => m.time === time && m.patient === patient && m.sender === sender
      )
      if (index >= 0) {
        state.messages[patient] = state.messages[patient].map((message, i) => {
          if (i === index) {
            return { ...payload, sent: true }
          }
          return message
        })
      } else {
        if (state.messages[patient]) {
          state.messages[patient] = [...state.messages[patient], payload].sort(
            ({ time: timeA }, { time: timeB }) => timeA - timeB
          )
        } else {
          state.messages[patient] = [payload]
        }
      }
    },
    sendMessage: (state, { payload }) => {
      const { patient } = payload
      const message = { ...payload, sent: false }
      if (state.messages[patient]) {
        state.messages[patient].push(message)
      } else {
        state.messages[patient] = [message]
      }
    },
    setChatMembers: (state, { payload }) => {
      state.chatMembers = payload
    }
  }
})

export const {
  actions: { setMessages, addMessage, sendMessage, reset, setChatMembers }
} = chatSlice

export default chatSlice.reducer
