import React from 'react'
import { PagesContext } from '../../../contexts/pages'
import CreateUser from './create'
import ListUsers from './list'
import EditUser from './edit'
import { useSelector } from 'react-redux'
import { adminSlice } from '../../../reducers'

const Users = () => {
  const { adminUserPage, AdminUserPages } = React.useContext(PagesContext)
  const user = useSelector(adminSlice.selectors.selectUser)
  switch (adminUserPage) {
    case AdminUserPages.ADMIN_USER_CREATE:
      return <CreateUser />
    case AdminUserPages.ADMIN_USER_LIST:
      if (user) {
        return <EditUser />
      }
      return <ListUsers />
    default:
      return <ListUsers />
  }
}

export default Users
