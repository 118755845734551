import React from 'react'
import VideoStream from '../video-stream'
import { Paper, useMediaQuery } from '@mui/material'
import moment from 'moment'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { coreSlice, dashboardAttendanceSlice, dashboardPatientSlice } from '../../../reducers'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../loading'

const Attendance = () => {
  const dispatcher = useDispatch()
  const user = useSelector(coreSlice.selectors.selectUser)
  const dashboardPatient = useSelector(dashboardPatientSlice.selectors.selectPatient)
  const patient = useSelector(dashboardAttendanceSlice.selectors.selectPatient)
  const attendances = useSelector(dashboardAttendanceSlice.selectors.selectAttendances)
  const attendance = useSelector(dashboardAttendanceSlice.selectors.selectAttendance)
  const notes = useSelector(dashboardAttendanceSlice.selectors.selectNotes)
  const hasNotesChanges = useSelector(dashboardAttendanceSlice.selectors.selectHasNotesChanges)
  const doctorInProgressAttendance = useSelector(dashboardAttendanceSlice.selectors.selectDoctorInProgressAttendance)
  const videoCall = useSelector(coreSlice.selectors.selectVideoCall)
  const setNotes = notes => {
    dispatcher(dashboardAttendanceSlice.actions.setNotes(notes))
  }

  const isSupervisor = React.useMemo(
    () => patient?.supervisors?.find((s) => s.pk === user.pk),
    [patient, user]
  )
  const [getAttendances, getDoctorInProgressAttendance] = useEvent([
    dashboardAttendanceSlice.eventBus.getAttendances,
    dashboardAttendanceSlice.eventBus.getDoctorInProgressAttendance
  ])

  const [createAttendance] = useEvent([
    {
      ...dashboardAttendanceSlice.eventBus.createAttendance, onSuccess: attendance => {
        dispatcher(dashboardAttendanceSlice.actions.setAttendance(attendance))
        getAttendances.trigger({ patient: patient?.pk, limit: 5 })
      }
    }
  ])

  const newAttendance = React.useCallback(() => {
    if (patient?.pk) {
      createAttendance.trigger({ patient: patient.pk })
    }
  }, [patient])


  const isLoading = React.useMemo(() => createAttendance.isWorking || getAttendances.isWorking, [createAttendance.isWorking, getAttendances.isWorking])
  const disableNewAttendance = React.useMemo(() => isLoading || doctorInProgressAttendance || attendances.find(({ hasFinished }) => !hasFinished), [isLoading, attendances, doctorInProgressAttendance])

  React.useEffect(() => {
    if (!attendance?.start) {
      dispatcher(dashboardAttendanceSlice.actions.setAttendance(attendances.find(({ hasFinished }) => !hasFinished)))
    }
  }, [attendances, attendance])

  React.useEffect(() => {
    setNotes(attendance?.notes || '')
  }, [attendance])

  React.useEffect(() => {
    dispatcher(dashboardAttendanceSlice.actions.setHasNotesChanges(attendance?.notes ? notes !== attendance?.notes : !!notes))
  }, [notes, attendance])

  const lookForAttendances = React.useCallback(() => {
    if (patient?.pk) {
      getAttendances.trigger({ patient: patient?.pk, limit: 5 })
    }
  }, [patient])

  React.useEffect(() => {
    if (user.role === 'doctor') {
      getDoctorInProgressAttendance.trigger()
    }
  }, [user])

  React.useEffect(() => {
    if (patient?.pk) {
      lookForAttendances()
    }
  }, [patient])

  React.useEffect(() => {
    if (user?.role !== 'patient' && user.role !== 'family') {
      dispatcher(dashboardAttendanceSlice.actions.setPatient(dashboardPatient))
    }
  }, [dispatcher, dashboardPatient])

  const setAttendance = React.useCallback(start => dispatcher(dashboardAttendanceSlice.actions.setAttendance(attendances.find(att => att?.start?.toString() === start?.toString()))), [dispatcher, attendances])

  const isTabletOrMobile = useMediaQuery('(max-width:767px)')

  const ableToSelectAttendance = React.useMemo(() => user?.role === 'doctor' && !isSupervisor, [user, isSupervisor])

  const SelectAttendanceMobile = React.useCallback(() => (
    <select className='input' value={attendance?.start} onChange={({ target: { value } }) => setAttendance(value)} disabled={hasNotesChanges}>
      <option value={null} >Selecione um atendimento</option>
      {attendances.map(att => (<option key={att.start} value={att.start}>{att.hasFinished ? 'Finalizado' : 'Em andamento'} - Início: {moment(att.start).format('DD/MM/YY HH:mm')}</option>))}
    </select >)
    , [attendance, attendances, hasNotesChanges])

  if (!ableToSelectAttendance) {
    return <VideoStream />
  }

  if (getDoctorInProgressAttendance.isWorking) {
    return <div className='w-full flex justify-center'><Loading /></div>
  }

  if (doctorInProgressAttendance?.patient && doctorInProgressAttendance?.patient?.pk !== patient?.pk) {
    return <p className='text-sm'>Você possui um atendimento em andamento com o paciente <a className='cursor-pointer underline' onClick={() => {
      dispatcher(dashboardAttendanceSlice.actions.setDoctorInProgressAttendance(null))
      dispatcher(dashboardPatientSlice.actions.setPatient(doctorInProgressAttendance.patient))
    }}>{doctorInProgressAttendance?.patient?.name} - {doctorInProgressAttendance?.patient?.doc}</a></p>
  }

  return (
    <div className={`w-full flex ${isTabletOrMobile ? 'flex-col' : 'flex-row'}`}>
      {!videoCall && <div className={`${isTabletOrMobile ? 'max-h-96 overflow-auto' : 'w-72'}`}>
        <button onClick={newAttendance} className='btn-primary' disabled={disableNewAttendance}>Novo Atendimento</button>
        <p className='label'>Atendimentos</p>
        {!isLoading && attendances.length === 0 ?
          <p className='text-xs'>Não há atendimentos disponíveis.</p> :
          isLoading ? <div className='flex justify-center h-10'><Loading size={5} /></div> :
            isTabletOrMobile ? <SelectAttendanceMobile /> :
              <Paper style={{ maxHeight: '50vh', overflow: 'auto' }}>
                {attendances.map((att) => (
                  <div className={`border-b h-20 text-sm flex flex-col items-center p-2 cursor-pointer ${att.start === attendance?.start ? 'bg-gray-100' : ''} ${hasNotesChanges ? 'pointer-events-none' : ''}`} onClick={() => setAttendance(att.start)} key={att.start}>
                    <div>
                      {att.hasFinished ? 'Finalizado' : 'Em andamento'}
                    </div>
                    <div className='text-xs text-gray-500 font-semibold'>
                      <>Terapeuta: {att?.doctor?.name}</>
                    </div>
                    <div className='text-xs text-gray-500'>
                      Início: {moment(att.start).format('DD/MM/YY HH:mm')}
                    </div>
                    <div className='text-xs text-gray-500'>
                      {att.hasFinished && (
                        <>Fim: {moment(att.end).format('DD/MM/YY HH:mm')}</>
                      )}
                    </div>
                  </div>
                ))}
              </Paper>
        }
      </div>}
      <div className={`${!isTabletOrMobile ? 'w-full' : ''}`}>
        <VideoStream />
      </div>
    </div>
  )
}
export default Attendance
