import name from './name'

const selectSliceData = (state) => state[name]

export const selectPatient = (state) => selectSliceData(state).patient
export const selectInProgressAttendance = (state) =>
  selectSliceData(state).inProgressAttendance
export const selectAttendances = (state) => selectSliceData(state).attendances
export const selectAttendance = (state) => selectSliceData(state).attendance
export const selectAllAttendances = (state) =>
  selectSliceData(state).allAttendances
export const selectAttendanceRecordings = (state) =>
  selectSliceData(state).attendanceRecordings
export const selectHasNotesChanges = (state) =>
  selectSliceData(state).hasNotesChanges
export const selectNotes = (state) => selectSliceData(state).notes
export const selectDoctorInProgressAttendance = (state) =>
  selectSliceData(state).doctorInProgressAttendance
export const selectRtmToken = (state) => selectSliceData(state).rtmToken
export const selectAttendanceComments = (state) => selectSliceData(state).attendanceComments