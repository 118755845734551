import React from 'react'
import Logo from '../../assets/img/main-logo.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Api from '../../services/api'
import Loading from '../../components/loading'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import CCForm from '../../components/cc-form'
import AddressForm from '../../components/address-form'
import { nameRegex, tenantRegex, emailRegex, ccRegex, cvvRegex, expirationRegex, cepRegex, cnpjRegex } from '../../constants/regex'
import { cnpjMask, emailMask, nameMask, tenantMask } from '../../utils/mask'
import Plans from './plans'

const Join = () => {
  const [isValidTenant, setIsValidTenant] = React.useState(null)
  const [loading, setIsLoading] = React.useState(false)
  const [open, setOpen] = React.useState({ org: true, address: false, payment: false })
  const [errors, setErrors] = React.useState([])
  const [success, setSuccess] = React.useState(false)

  const { setFieldValue, ...formik } = useFormik({
    initialValues: {
      name: '',
      tenantKey: '',
      identity: '',
      holder: '',
      cardNumber: '',
      expirationDate: '',
      securityCode: '',
      email: '',
      paymentMethod: 2, // "1" (Boleto Bancário), "2" (Cartão de Crédito),
      city: '',
      district: '',
      street: '',
      number: '',
      complement: '',
      zipcode: '',
      plan: null
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      name: Yup.string().required('O nome é obrigatório.').matches(nameRegex, { message: 'O nome pode conter apenas letras, números e espaços.' }),
      tenantKey: Yup.string().required('O id é obrigatório.').matches(tenantRegex, { message: 'O id pode conter apenas letras minúsculas e números.' }),
      email: Yup.string().required('O e-mail é obrigatório.').matches(emailRegex, { message: 'Digite um e-mail válido.' }),
      identity: Yup.string().required('O CNPJ é obrigatório.').matches(cnpjRegex, { message: 'Digite um CNPJ válido' }),
      holder: Yup.string().required('O nome é obrigatório.').matches(nameRegex, { message: 'O nome pode conter apenas letras, números e espaços.' }),
      cardNumber: Yup.string().required('O número do cartão é obrigatório').matches(ccRegex, 'O número deve conter 16 dígitos.'),
      securityCode: Yup.string().required('O CVV é obrigatório.').matches(cvvRegex, 'O CVV deve conter 3 dígitos.'),
      expirationDate: Yup.string().required('A data de expiração é obrigatória.').matches(expirationRegex, 'A data de expiração deve estar no formato MM/YYYY.'),
      city: Yup.string().required('A cidade é obrigatória.'),
      district: Yup.string().required('O bairro é obrigatório.'),
      street: Yup.string().required('A rua é obrigatória.'),
      number: Yup.string().required('O número é obrigatório.'),
      complement: Yup.string().notRequired(),
      zipcode: Yup.string().required('O CEP é obrigatório.').matches(cepRegex, 'O CEP deve conter 8 dígitos.'),
      plan: Yup.number().required('Você precisa selecionar um plano')
    })
  })

  const submit = React.useCallback(() => {
    const subscribe = async () => {
      const { data: { success, Errors } } = await new Api().post('subscribe', formik.values)
      setErrors([])
      setSuccess(success)
      if (!success) {
        setErrors(Errors)
      }
      subscribe()
    }
  }, [formik.values])

  const validateTenant = async () => {
    setIsLoading(true)
    const { data: { available } } = await new Api().get('check-tenant-availability', {
      tenantKey: formik.values.tenantKey
    })
    setIsLoading(false)
    setIsValidTenant(available)
  }

  React.useEffect(() => {
    setIsValidTenant(null)
  }, [formik.values.tenantKey])

  const ableToCheck = React.useMemo(() => formik.values.tenantKey.length >= 2, [formik.values.tenantKey])
  const isValidOrg = React.useMemo(() => !formik.errors.name && !formik.errors.tenantKey && !formik.errors.email && !formik.errors.identity && isValidTenant, [formik.errors, isValidTenant])
  const isValidAddress = React.useMemo(() =>
    !formik.errors.city &&
    !formik.errors.district &&
    !formik.errors.street &&
    !formik.errors.number &&
    !formik.errors.zipcode, [formik.errors]
  )
  const isValidPayment = React.useMemo(() =>
    (!formik.errors.holder &&
      !formik.errors.cardNumber &&
      !formik.errors.expirationDate &&
      !formik.errors.securityCode) || parseInt(formik.values.paymentMethod) === 1, [formik.errors, formik.values.paymentMethod])

  React.useEffect(() => {
    setOpen(open => ({ ...open, address: isValidOrg && !isValidAddress, org: !isValidOrg, payment: isValidAddress }))
  }, [isValidAddress, isValidOrg])

  return (
    <section className='p-2'>
      <div className='w-full justify-start'>
        <img src={Logo} alt='Logo Vinculo Integral' width={273} height={65} />
      </div>
      <div className='w-full flex justify-center'>
        <div className='p-6 max-w-96 w-full'>
          <div className='w-full text-center'>
            <h1 className='text-2xl font-semibold'>Junte-se a nós</h1>
          </div>
          <div className='w-full'>
            <div className='w-full'>
              <form className='w-full flex flex-col'>
                <div className='w-full text-center hover:bg-gray-100 p-2 cursor-pointer rounded border' onClick={() => { setOpen(open => ({ ...open, org: !open.org })) }}>
                  <div className='flex flex-row text-center w-full justify-between'>
                    <h1 className='text-xl'>Sobre sua organização</h1>
                    {isValidOrg ? <CheckIcon className='text-green-700 text-sm' /> : <CloseIcon className='text-red-700 text-xs' />}
                  </div>
                </div>
                <div className={`transition-height duration-500 ${open.org ? 'h-[28rem] visible' : 'h-0 invisible'}`}>
                  <div>
                    <label className='label' htmlFor='name'>Nome da Organização</label>
                    <input onBlur={formik.handleBlur} className='input' value={formik.values.name} onChange={({ target: { value } }) => setFieldValue('name', nameMask(value))} id='name' />
                    <p className='text-red-700 text-xs'>{formik.errors.name}</p>
                  </div>
                  <div>
                    <label className='label' htmlFor='email'>E-mail</label>
                    <input onBlur={formik.handleBlur} className='input' type='email' value={formik.values.email} id='email' onChange={({ target: { value } }) => setFieldValue('email', emailMask(value))} />
                    <p className='text-red-700 text-xs'>{formik.errors.email}</p>
                  </div>
                  <div>
                    <label className='label' htmlFor='identity'>CNPJ</label>
                    <input onBlur={formik.handleBlur} className='input' type='text' value={formik.values.identity} id='identity' onChange={({ target: { value } }) => setFieldValue('identity', cnpjMask(value))} />
                    <p className='text-red-700 text-xs'>{formik.errors.identity}</p>
                  </div>
                  <div>
                    <label className='label' htmlFor='tenantKey'>Id da Organização</label>
                    <div className='flex flex-row'>
                      <div className='flex flex-row items-center w-full'>
                        <input onBlur={formik.handleBlur} className='input w-full' value={formik.values.tenantKey} onChange={({ target: { value } }) => setFieldValue('tenantKey', tenantMask(value))} id='tenantKey' />
                        {isValidTenant ? <CheckIcon className='text-green-700' /> : <CloseIcon className='text-red-700' />}
                      </div>
                      <button type='button' onClick={validateTenant} className='btn-primary h-12 !w-32' disabled={!ableToCheck || isValidTenant}>{loading ? <div className='w-full flex justify-center h-full'><Loading size={6} /></div> : 'Verificar Disponibilidade'}</button>
                    </div>
                    <p className='text-red-700 text-xs'>{formik.errors.tenantKey}</p>
                  </div>
                </div>
                <div className='w-full text-center hover:bg-gray-100 p-2 cursor-pointer rounded border' onClick={() => { setOpen(open => ({ ...open, address: !open.address })) }}>
                  <div className='flex flex-row text-center w-full justify-between'>
                    <h1 className='text-xl'>Endereço</h1>
                    {isValidAddress ? <CheckIcon className='text-green-700 text-sm' /> : <CloseIcon className='text-red-700 text-xs' />}
                  </div>
                </div>
                <div className={`transition-height duration-500 ${open.address ? 'h-[32rem] visible' : 'h-0 invisible'}`}>
                  <AddressForm formik={formik} setFieldValue={setFieldValue} />
                </div>
                <div className='w-full text-center hover:bg-gray-100 p-2 cursor-pointer rounded border'>
                  <div className='flex flex-row text-center w-full justify-between'>
                    <h1 className='text-xl'>Plano</h1>
                    {formik.values.plan ? <CheckIcon className='text-green-700 text-sm' /> : <CloseIcon className='text-red-700 text-xs' />}
                  </div>
                </div>
                <div className='overflow-x-auto'>
                  <Plans plan={formik.values.plan} setPlan={plan => setFieldValue('plan', plan)} />
                </div>
                <p className='text-red-700 text-xs'>{formik.errors.plan}</p>
                <div className='w-full flex gap-4 flex-col bg-white'>
                  <div className='w-full text-center hover:bg-gray-100 p-2 cursor-pointer rounded border' onClick={() => { setOpen(open => ({ ...open, payment: !open.payment })) }}>
                    <div className='flex flex-row text-center w-full justify-between'>
                      <h1 className='text-xl'>Pagamento</h1>
                      {isValidPayment ? <CheckIcon className='text-green-700 text-sm' /> : <CloseIcon className='text-red-700 text-xs' />}
                    </div>
                  </div>
                  <div className={`block transition-height duration-500 ${open.payment ? 'h-20' : 'h-0 invisible'}`}>
                    <label className='label' htmlFor='paymentMethod'>Método de pagamento</label>
                    <select className='input w-full' value={formik.values.paymentMethod} onChange={formik.handleChange} id='paymentMethod'>
                      <option value={1}>Boleto</option>
                      <option value={2}>Cartão de Crédito</option>
                    </select>
                  </div>
                  <div className={`block transition-height duration-500 ${open.payment && parseInt(formik.values.paymentMethod) === 2 ? 'h-96' : 'h-0 invisible'}`}>
                    <CCForm formik={formik} setFieldValue={setFieldValue} />
                  </div>
                </div>
                {success && <p className='text-sm text-wrap'>Sua assinatura foi criada com sucesso e estará <a href='teste' className='underline'>disponível aqui</a> alguns minutos após a aprovação do pagamento. Você deve criar uma conta com o e-mail <span className='bold'>{formik.values.email}</span> para o primeiro acesso.</p>}
                {errors.map(({ Message }, key) => <p key={key} className='text-red-700 text-xs text-wrap'>{Message}</p>)}
                <button type='button' onClick={submit} className='btn-primary' disabled={!isValidOrg || !isValidPayment || success}>Criar Assinatura</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Join
