import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../../utils'

const initialState = {
  patient: null
}

export const dashboardPatientSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setPatient: (state, { payload }) => {
      state.patient = payload
    }
  }
})

export const {
  actions: {
    reset,
    setPatient
  }
} = dashboardPatientSlice

export default dashboardPatientSlice.reducer
