import React, { useRef } from 'react'
import { useFormik } from 'formik'
import { useAuth } from '../../../contexts/user-authentication'
import * as Yup from 'yup'

const ConfirmOTP = ({ registerData }) => {
  const { confirmUser, resendSignUp } = useAuth()
  const otpRefs = useRef([])
  otpRefs.current = Array(6)
    .fill()
    .map((_, i) => otpRefs.current[i] || React.createRef())

  const { handleChange, handleSubmit, handleBlur, errors, touched, values } = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema: Yup.object({
      otp: Yup.string().required('O código de verificação é obrigatório!')
    }),
    onSubmit: async (values) => {
      confirmUser(registerData, values.otp)
    }
  })

  return (
    <div className='relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50'>
      <div className='relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl'>
        <div className='mx-auto flex w-full max-w-md flex-col'>
          <div className='flex flex-col items-center justify-center text-center'>
            <div className='font-semibold text-3xl'>
              <p>Verificação de E-mail</p>
            </div>
            <div className='flex flex-row text-sm font-medium text-gray-400'>
              <p>
                Enviamos um código para o e-mail {registerData.email}
              </p>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className='flex flex-col'>
              <div className='mb-4 flex flex-col items-center w-full'>
                <div className='w-32'>
                  <input
                    className='input text-center'
                    type='text'
                    maxLength='6'
                    minLength='6'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.otp}
                    id='otp'
                    name='otp'
                    required
                  />
                </div>
                {touched.otp && errors.otp ? (
                  <div>{errors.otp}</div>
                ) : null}
              </div>

              <div className='flex flex-col'>
                <button
                  type='submit'
                  className='signin-btn'
                >
                  Verificar Conta
                </button>

                <div className='flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500'>
                  <p>Não recebeu o código?</p>
                  <button
                    className='flex flex-row items-center text-blue-600'
                    type='button'
                    onClick={() => resendSignUp(registerData.email)}
                  >
                    Reenviar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ConfirmOTP
