import React from 'react'
import Message from './Message'
import { useSelector } from 'react-redux'
import { coreSlice, chatSlice } from '../../reducers'
import moment from 'moment'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { PagesContext } from '../../contexts/pages'

const DATE_FORMAT = 'DD/MM/YYYY'

const ChatMessages = ({ patient }) => {
  const authUser = useSelector(coreSlice.selectors.selectAuthUser)
  const messages = useSelector(chatSlice.selectors.selectMessages)
  const [getMessages] = useEvent([chatSlice.eventBus.getMessages])
  const hasMoreMessages = useSelector(chatSlice.selectors.selectHasMoreMessages)

  const messageDates = messages[patient?.pk]?.reduce((prev, curr) => {
    const date = moment(curr.time).format(DATE_FORMAT)
    if (prev.indexOf(date) < 0) {
      return [...prev, date]
    }
    return prev
  }, [])
  const groupMessagesByDate = messageDates?.reduce(
    (prev, date) => ({
      ...prev,
      [date]: messages[patient.pk].filter(
        (message) => moment(message?.time).format(DATE_FORMAT) === date
      )
    }),
    {}
  ) || {}

  const fetchMoreMessages = (e) => {
    const {
      target: { scrollTop }
    } = e
    if (
      scrollTop === 0 &&
      !getMessages.isWorking &&
      hasMoreMessages[patient.pk]
    ) {
      getMessages.trigger(messages[patient.pk][0])
    }
  }

  const lastMessaageScrollRef = React.useRef()
  const scrollPosition = React.useRef()

  React.useEffect(() => {
    if (lastMessaageScrollRef.current) {
      scrollToLastMessage()
    }
  }, [])

  const scrollToLastMessage = () => {
    lastMessaageScrollRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    })
  }

  React.useEffect(() => {
    if (!messages[patient?.pk]) {
      getMessages.trigger({ patient: patient?.pk })
    }
  }, [getMessages, messages, patient])

  React.useEffect(() => {
    if (hasMoreMessages[patient?.pk] && scrollPosition.current) {
      scrollPosition.current.scrollTop = 450 - messages[patient?.pk]?.length
    }
  }, [patient, hasMoreMessages, messages])

  const { dashBoardPage, DashBoardPages } = React.useContext(PagesContext)

  return (
    <div className={`px-2 shadow-sm border overflow-y-auto pt-0 ${dashBoardPage === DashBoardPages.CHAT ? 'h-[62vh]' : 'h-[58vh]'}`}>
      <div className='w-full'>
        <ul>
          {!getMessages.isWorking && Object.keys(groupMessagesByDate).length === 0 &&
            <div className='flex justify-center'>Não há mensagens</div>}
          <div className='flex justify-center py-2'>
            {getMessages.isWorking
              ? <div className='bg-secondary-gray rounded-md p-1 text-white'>Carregando mensagens</div>
              : hasMoreMessages[patient?.pk]
                ? <button className='bg-secondary-gray rounded-md p-1 text-white cursor-pointer' onClick={fetchMoreMessages}>Carregar mais mensagens</button>
                : <></>}
          </div>
          {groupMessagesByDate &&
            Object.keys(groupMessagesByDate)?.map((date) => {
              const dateText =
                date === moment().format(DATE_FORMAT)
                  ? 'Hoje'
                  : date === moment().add(-1, 'day').format(DATE_FORMAT)
                    ? 'Ontem'
                    : date
              return (
                <div key={date}>
                  <div className='flex justify-center'>
                    <span className='bg-secondary-gray rounded-md p-1 text-white'>
                      {dateText}
                    </span>
                  </div>
                  {groupMessagesByDate[date]?.map((message, index) => (
                    <div key={index} ref={lastMessaageScrollRef}>
                      <Message
                        message={message}
                        self={message.sender === authUser.sub}
                      />
                    </div>
                  ))}
                </div>
              )
            })}
        </ul>
      </div>
    </div>
  )
}
export default ChatMessages
